import { actions } from '../actions/mealPrototype';

const initialState = {
  instances: [],
  menuCheckedMapping: {},
  menuCustomizationsMapping: {},
  menuCustomizationsDisplayLayoutMapping: {},
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case actions.updateProperties: {
      return {
        ...state,
        ...payload,
      };
    }

    case actions.resetData: {
      return {
        instances: [],
        menuCheckedMapping: {},
        menuCustomizationsMapping: {},
        menuCustomizationsDisplayLayoutMapping: {},
      };
    }

    default: {
      return state;
    }
  }
};
