import { actions as printSettingActions } from '../actions/printerSetting';

const initialState = {
  isLoadingPrinterDishSettings: false,
  isLoadPrinterDishSettingsSuccess: false,
  isLoadPrinterDishSettingsFailed: false,

  isUpdatingPrintSettings: false,
  isUpdatePrintSettingsSuccess: false,
  isUpdatePrintSettingsFailed: false,

  categories: {},
  printerDishSettings: {},

  isLoadingPrintCustomizationSettings: false,
  isLoadPrintCustomizationSettingsSuccess: false,
  isLoadPrintCustomizationSettingsFailed: false,

  isUpdatingPrintCustomizationSettings: false,
  isUpdatePrintCustomizationSettingsSuccess: false,
  isUpdatePrintCustomizationSettingsFailed: false,

  printerCustomizationSettings: {},
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case printSettingActions.loadingPrinterSettings: {
      return {
        ...state,
        isLoadingPrinterDishSettings: true,
        isLoadPrinterDishSettingsSuccess: false,
        isLoadPrinterDishSettingsFailed: false,
      };
    }

    case printSettingActions.loadPrinterSettingsSuccess: {
      return {
        ...state,
        isLoadingPrinterDishSettings: false,
        isLoadPrinterDishSettingsSuccess: true,
        isLoadPrinterDishSettingsFailed: false,
        ...payload,
      };
    }

    case printSettingActions.loadPrinterSettingsFailed: {
      return {
        ...state,
        isLoadingPrinterDishSettings: false,
        isLoadPrinterDishSettingsSuccess: false,
        isLoadPrinterDishSettingsFailed: true,
      };
    }

    case printSettingActions.updatingPrinterSetting: {
      return {
        ...state,
        isUpdatingPrintSettings: true,
        isUpdatePrintSettingsSuccess: false,
        isUpdatePrintSettingsFailed: false,
      };
    }

    case printSettingActions.updatePrinterSettingSuccess: {
      return {
        ...state,
        isUpdatingPrintSettings: false,
        isUpdatePrintSettingsSuccess: true,
        isUpdatePrintSettingsFailed: false,
        ...payload,
      };
    }

    case printSettingActions.updatePrinterSettingFailed: {
      return {
        ...state,
        isUpdatingPrintSettings: false,
        isUpdatePrintSettingsSuccess: false,
        isUpdatePrintSettingsFailed: true,
      };
    }

    case printSettingActions.deletePrinterMapping: {
      const { printerMacAddress } = payload;
      const newPrintSettings = { ...state.printerDishSettings };
      delete newPrintSettings[printerMacAddress];

      return {
        ...state,
        printerDishSettings: newPrintSettings,
      };
    }

    case printSettingActions.updateUnassignedData: {
      return {
        ...state,
        ...payload,
      };
    }

    case printSettingActions.loadingPrinterCustomizationSettings: {
      return {
        ...state,
        isLoadingPrintCustomizationSettings: true,
        isLoadPrintCustomizationSettingsSuccess: false,
        isLoadPrintCustomizationSettingsFailed: false,
      };
    }

    case printSettingActions.loadPrinterCustomizationSettingsSuccess: {
      return {
        ...state,
        isLoadingPrintCustomizationSettings: false,
        isLoadPrintCustomizationSettingsSuccess: true,
        isLoadPrintCustomizationSettingsFailed: false,
        ...payload,
      };
    }

    case printSettingActions.loadPrinterCustomizationSettingsFailed: {
      return {
        ...state,
        isLoadingPrintCustomizationSettings: false,
        isLoadPrintCustomizationSettingsSuccess: false,
        isLoadPrintCustomizationSettingsFailed: true,
      };
    }

    case printSettingActions.updatingPrinterCustomizationSetting: {
      return {
        ...state,
        isUpdatingPrintCustomizationSettings: true,
        isUpdatePrintCustomizationSettingsSuccess: false,
        isUpdatePrintCustomizationSettingsFailed: false,
      };
    }

    case printSettingActions.updatePrinterCustomizationSettingSuccess: {
      return {
        ...state,
        isUpdatingPrintCustomizationSettings: false,
        isUpdatePrintCustomizationSettingsSuccess: true,
        isUpdatePrintCustomizationSettingsFailed: false,
        ...payload,
      };
    }

    case printSettingActions.updatePrinterCustomizationSettingFailed: {
      return {
        ...state,
        isUpdatingPrintCustomizationSettings: false,
        isUpdatePrintCustomizationSettingsSuccess: false,
        isUpdatePrintCustomizationSettingsFailed: true,
      };
    }

    default:
      return state;
  }
};
