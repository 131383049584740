import { actions } from '../actions/quickNotes';

const initialState = {
  isLoadingQuickNotes: false,
  quickNotes: [],
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case actions.loadingQuickNotes: {
      return {
        ...state,
        isLoadingQuickNotes: true,
      };
    }

    case actions.loadedQuickNotes: {
      return {
        ...state,
        isLoadingQuickNotes: false,
        quickNotes: payload,
      };
    }

    default:
      return state;
  }
};
