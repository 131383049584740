import { queryFloorPlanSections } from '../services/floorPlan';

const BASE = 'FLOOR_PLAN';

export const actions = {
  loadingSections: `${BASE}_LOADING_SECTIONS`,
  loadSectionsSuccess: `${BASE}_LOAD_SECTIONS_SUCCESS`,
  loadSectionsFailed: `${BASE}_LOAD_SECTIONS_FAILED`,
};

export const fetchFloorPlanSections = () => async (dispatch) => {
  dispatch({ type: actions.loadingSections });
  let response;

  try {
    response = await queryFloorPlanSections();
  } catch (e) {
    response = { success: false };
  }

  if (!response.success) {
    dispatch({ type: actions.loadSectionsFailed });
    return;
  }

  dispatch({
    type: actions.loadSectionsSuccess,
    payload: response.data || [],
  });
};
