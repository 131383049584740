import { actions as discountActions } from '../actions/discount';

const initialState = {
  isLoading: false,
  isLoadDiscountSuccess: false,
  isLoadDiscountFailed: false,

  isCreatingDiscount: false,
  isCreateDiscountSuccess: false,
  isCreateDiscountFailed: false,

  isUpdatingDiscount: false,
  isUpdateDiscountSuccess: false,
  isUpdateDiscountFailed: false,

  discounts: [],
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case discountActions.loadingDiscounts: {
      return {
        ...state,
        isLoading: true,
        isLoadDiscountSuccess: false,
        isLoadDiscountFailed: false,
      };
    }

    case discountActions.loadDiscountSuccess: {
      return {
        ...state,
        isLoading: false,
        isLoadDiscountSuccess: true,
        isLoadDiscountFailed: false,
        ...payload,
      };
    }

    case discountActions.loadDiscountFailed: {
      return {
        ...state,
        isLoading: false,
        isLoadDiscountSuccess: false,
        isLoadDiscountFailed: true,
      };
    }

    case discountActions.creatingDiscount: {
      return {
        ...state,
        isCreatingDiscount: true,
        isCreateDiscountSuccess: false,
        isCreateDiscountFailed: false,
      };
    }

    case discountActions.createDiscountSuccess: {
      return {
        ...state,
        isCreatingDiscount: false,
        isCreateDiscountSuccess: true,
        isCreateDiscountFailed: false,
      };
    }

    case discountActions.createDiscountFailed: {
      return {
        ...state,
        isCreatingDiscount: false,
        isCreateDiscountSuccess: false,
        isCreateDiscountFailed: true,
      };
    }

    case discountActions.updatingDiscount: {
      return {
        ...state,
        isUpdatingDiscount: true,
        isUpdateDiscountSuccess: false,
        isUpdateDiscountFailed: false,
      };
    }

    case discountActions.updateDiscountSuccess: {
      return {
        ...state,
        isUpdatingDiscount: false,
        isUpdateDiscountSuccess: true,
        isUpdateDiscountFailed: false,
      };
    }

    case discountActions.updateDiscountFailed: {
      return {
        ...state,
        isUpdatingDiscount: false,
        isUpdateDiscountSuccess: false,
        isUpdateDiscountFailed: true,
      };
    }

    default:
      return state;
  }
};
