import { actions as WAITLISTActions } from '../actions/waitlist';

const initialState = {
  restaurantProfile: undefined,
  queueList: null,
  preferenceIsLoading: false,
};

const waitlistReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case WAITLISTActions.loadingPreference: {
      return {
        ...state,
        preferenceIsLoading: true,
      };
    }
    case WAITLISTActions.loadPreference: {
      return {
        ...state,
        ...payload,
        preferenceIsLoading: false,
      };
    }

    default:
      return state;
  }
};

export default waitlistReducer;
