import { CLIENT_ID } from 'src/consts';
import { GET, POST, PUT, DELETE } from '../utils/request';
import { MONOLITH_API_PREFIX } from '../consts';
export function querySalesCategoryList({ restaurantId }) {
  return GET(
    `${MONOLITH_API_PREFIX}/api/pos/v4/restaurants/${restaurantId}/sales_categories`,
    {},
    {
      headers: {
        'client-id': CLIENT_ID,
      },
    }
  );
}

export function updateSalesCategory({ id, restaurantId, ...data }) {
  return PUT(`${MONOLITH_API_PREFIX}/api/pos/v4/restaurants/${restaurantId}/sales_categories/${id}`, data, {
    headers: {
      'client-id': CLIENT_ID,
    },
  });
}

export function createSalesCategory({ restaurantId, ...data }) {
  return POST(`${MONOLITH_API_PREFIX}/api/pos/v4/restaurants/${restaurantId}/sales_categories`, data, {
    headers: {
      'client-id': CLIENT_ID,
    },
  });
}

export function deleteSalesCategory({ id, restaurantId }) {
  return DELETE(
    `${MONOLITH_API_PREFIX}/api/pos/v4/restaurants/${restaurantId}/sales_categories/${id}`,
    {},
    {
      headers: {
        'client-id': CLIENT_ID,
      },
    }
  );
}
