import { actions as dishPreferenceActions } from '../actions/dishPreferences';

const initialState = {
  isLoadingDishPreferences: false,
  isLoadDishPreferencesSuccess: false,
  isLoadDishPreferencesFailed: false,

  dishPreferences: [],
  customizationDishPreferencesMapping: {},
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case dishPreferenceActions.loadingDishPreferences: {
      return {
        ...state,
        isLoadingDishPreferences: true,
        isLoadDishPreferencesSuccess: false,
        isLoadDishPreferencesFailed: false,
      };
    }

    case dishPreferenceActions.loadDishPreferencesSuccess: {
      return {
        ...state,
        isLoadingDishPreferences: false,
        isLoadDishPreferencesSuccess: true,
        isLoadDishPreferencesFailed: false,
        dishPreferences: payload,
      };
    }

    case dishPreferenceActions.loadDishPreferencesFailed: {
      return {
        ...state,
        isLoadingDishPreferences: false,
        isLoadDishPreferencesSuccess: false,
        isLoadDishPreferencesFailed: true,
      };
    }

    case dishPreferenceActions.loadedCustomizationDishPreferencesMapping: {
      return {
        ...state,
        customizationDishPreferencesMapping: payload,
      };
    }

    default: {
      return state;
    }
  }
};
