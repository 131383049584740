import { actions as loyaltySettingsActions } from '../actions/loyaltySettings';

const initialState = {
  loyaltySettings: undefined,
  prepaidCardSettings: undefined,
  prepaidCardSettingsHasLoaded: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case loyaltySettingsActions.updateSettings: {
      return {
        ...state,
        loyaltySettings: payload,
      };
    }
    case loyaltySettingsActions.updatePrepaidCardSettings: {
      let { loyaltySettings, ...others } = payload;

      return {
        ...state,
        ...others,
        ...(loyaltySettings
          ? {
              loyaltySettings,
            }
          : {}),
      };
    }

    default:
      return state;
  }
};
