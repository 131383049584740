import {
  queryDishItemPrinterSetting,
  updateDishItemPrinterSetting,
  queryDishCustomizationPrinterSetting,
  updateDishCustomizationPrinterSetting,
} from '../services/dishitem';

const BASE = 'PRINTER_SETTING';

export const actions = {
  loadingPrinterSettings: `${BASE}_LOADING_PRINTER_SETTINGS`,
  loadPrinterSettingsSuccess: `${BASE}_LOAD_PRINTER_SETTINGS_SUCCESS`,
  loadPrinterSettingsFailed: `${BASE}_LOAD_PRINTER_SETTINGS_FAILED`,
  updateUnassignedData: `${BASE}_UPDATE_UNASSIGNED_DATA`,

  updatingPrinterSetting: `${BASE}_UPDATING_PRINTER_SETTING`,
  updatePrinterSettingSuccess: `${BASE}_UPDATE_PRINTER_SETTING_SUCCESS`,
  updatePrinterSettingFailed: `${BASE}_UPDATE_PRINTER_SETTING_FAILED`,

  deletePrinterMapping: `${BASE}_DELETE_PRINTER_MAPPING`,

  loadingPrinterCustomizationSettings: `${BASE}_LOADING_PRINTER_CUSTOMIZATION_SETTINGS`,
  loadPrinterCustomizationSettingsSuccess: `${BASE}_LOAD_PRINTER_CUSTOMIZATION_SETTINGS_SUCCESS`,
  loadPrinterCustomizationSettingsFailed: `${BASE}_LOAD_PRINTER_CUSTOMIZATION_SETTINGS_FAILED`,

  updatingPrinterCustomizationSetting: `${BASE}_UPDATING_PRINTER_CUSTOMIZATION_SETTING`,
  updatePrinterCustomizationSettingSuccess: `${BASE}_UPDATE_PRINTER_CUSTOMIZATION_SETTING_SUCCESS`,
  updatePrinterCustomizationSettingFailed: `${BASE}_UPDATE_PRINTER_CUSTOMIZATION_SETTING_FAILED`,
};
export const REWARD_CATEGORY_ID = 'reward_dishes';
const REWARD_CATEGORY = { id: REWARD_CATEGORY_ID, name: 'Reward dishes', foreign_name: '免费菜' };

export const fetchPrinterDishSettings = (callback) => async (dispatch) => {
  dispatch({ type: actions.loadingPrinterSettings });

  let response;

  try {
    response = await queryDishItemPrinterSetting();
  } catch (e) {
    response = { success: false };
  }

  if (!response.success) {
    dispatch({
      type: actions.loadPrinterSettingsFailed,
    });
    return;
  }

  const { meal_prototypes, rewards, printer_map } = response.data;

  const categoryMap = {};
  meal_prototypes.forEach((mealPrototype) => {
    const { meal_instances } = mealPrototype;
    if (meal_instances && meal_instances.length > 0) {
      meal_instances.forEach((meal) => {
        const { category } = meal;

        if (category) {
          if (!categoryMap[category.id]) {
            categoryMap[category.id] = { ...category, meals: [] };
          }

          const { meals } = categoryMap[category.id];
          if (!meals.find((_) => _.id === mealPrototype.id)) {
            meals.push({
              ...meal,
              id: mealPrototype.id,
            });
          }
        }
      });
    }
  });

  if (rewards && rewards.length) {
    const rewardCategory = {
      ...REWARD_CATEGORY,
      meals: [],
    };
    categoryMap[REWARD_CATEGORY_ID] = rewardCategory;
    rewards.forEach((reward) => {
      categoryMap[REWARD_CATEGORY_ID].meals.push({ ...reward, category: REWARD_CATEGORY });
    });
  }

  const printerDishSettings = printer_map || {};
  const payload = {
    categories: categoryMap,
    printerDishSettings,
  };

  dispatch({
    type: actions.loadPrinterSettingsSuccess,
    payload,
  });

  typeof callback === 'function' && callback(payload);
};

function handleUpdatedPrinterSetting(callback, success) {
  typeof callback === 'function' && callback(success);
}

export const doUpdatePrinterDishSetting = (changedPrinterSettings, callback) => async (dispatch, getState) => {
  dispatch({
    type: actions.updatingPrinterSetting,
  });

  const newPrinterSettings = Object.keys(changedPrinterSettings).map((printer_id) => {
    return {
      printer_id,
      print_instance_ids: changedPrinterSettings[printer_id],
    };
  });

  let response;

  try {
    response = await updateDishItemPrinterSetting(newPrinterSettings);
  } catch (e) {
    response = { success: false };
  }

  if (!response.success) {
    dispatch({
      type: actions.updatePrinterSettingFailed,
    });
    handleUpdatedPrinterSetting(callback, false);
    return;
  }

  const { printSettings } = getState().printSetting;

  const newSetting = {
    ...printSettings,
    ...changedPrinterSettings,
  };

  dispatch({
    type: actions.updatePrinterSettingSuccess,
    payload: { printSettings: newSetting },
  });
  handleUpdatedPrinterSetting(callback, true);
};

export const fetchPrinterCustomizationSettings = () => async (dispatch) => {
  dispatch({ type: actions.loadingPrinterCustomizationSettings });

  let response;

  try {
    response = await queryDishCustomizationPrinterSetting();
  } catch (e) {
    response = { success: false };
  }

  if (!response.success) {
    dispatch({
      type: actions.loadPrinterCustomizationSettingsFailed,
    });
    return;
  }

  dispatch({
    type: actions.loadPrinterCustomizationSettingsSuccess,
    payload: {
      printerCustomizationSettings: response.data || {},
    },
  });
};

export const doUpdatePrinterCustomizationOptionSetting =
  (changedPrinterSettings, callback) => async (dispatch, getState) => {
    dispatch({
      type: actions.updatingPrinterCustomizationSetting,
    });

    const newPrinterSettings = Object.keys(changedPrinterSettings).map((printer_id) => {
      return {
        printer_id,
        customized_option_ids: changedPrinterSettings[printer_id],
      };
    });

    let response;

    try {
      response = await updateDishCustomizationPrinterSetting(newPrinterSettings);
    } catch (e) {
      response = { success: false };
    }

    if (!response.success) {
      dispatch({
        type: actions.updatePrinterCustomizationSettingFailed,
      });
      handleUpdatedPrinterSetting(callback, false);
      return;
    }

    const { printerCustomizationSettings } = getState().printSetting;

    const newSetting = {
      ...printerCustomizationSettings,
      ...changedPrinterSettings,
    };

    dispatch({
      type: actions.updatePrinterCustomizationSettingSuccess,
      payload: { printerCustomizationSettings: newSetting },
    });
    handleUpdatedPrinterSetting(callback, true);
  };
