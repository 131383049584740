import { actions as activitiesActions } from '../actions/activities';

const initialState = {
  isLoadingActivities: false,
  isLoadActivitiesSuccess: false,
  isLoadActivitiesFailed: false,

  activities: [],
  pagination: { current: 1, pageSize: 10, total: 0 },
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case activitiesActions.loadingActivities: {
      return {
        ...state,
        isLoadingActivities: true,
        isLoadActivitiesSuccess: false,
        isLoadActivitiesFailed: false,
      };
    }

    case activitiesActions.loadActivitiesSuccess: {
      return {
        ...state,
        isLoadingActivities: false,
        isLoadActivitiesSuccess: true,
        isLoadActivitiesFailed: false,
        ...payload,
      };
    }

    case activitiesActions.loadActivitiesFailed: {
      return {
        ...state,
        isLoadingActivities: false,
        isLoadActivitiesSuccess: false,
        isLoadActivitiesFailed: true,
        activities: [],
        pagination: { current: 1, pageSize: 10, total: 0 },
      };
    }

    default:
      return state;
  }
};
