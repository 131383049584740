import { actions as restaurantsActions } from '../actions/restaurants';

const initialState = {
  loading: false,
  restaurants: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case restaurantsActions.loadingRestaurants: {
      return {
        ...state,
        loading: true,
      };
    }

    case restaurantsActions.loadRestaurantsSuccess: {
      return {
        ...state,
        loading: false,
        restaurants: action.payload,
      };
    }

    case restaurantsActions.loadRestaurantsFailed: {
      return {
        ...state,
        loading: false,
        restaurants: [],
      };
    }

    default:
      return state;
  }
};
