import { GET, POST, PUT, DELETE } from '../utils/request';
import { API_PREFIX } from '../consts';

export function queryDiscountList() {
  return GET(`${API_PREFIX}/v1/discounts`);
}

export function queryDiscountDetail(id) {
  return GET(`${API_PREFIX}/v1/discount/${id}`);
}

export function updateDiscount({ id, ...data }) {
  return PUT(`${API_PREFIX}/v1/discount/${id}`, data);
}

export function createDiscount(data) {
  return POST(`${API_PREFIX}/v1/discount`, data);
}

export function deleteDiscount(id) {
  return DELETE(`${API_PREFIX}/v1/discount/${id}`);
}

export function batchUpdateDiscounts(payload) {
  return PUT(`${API_PREFIX}/v1/discounts`, payload);
}
