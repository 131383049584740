import { DEVICE_TYPE_ENUMS } from 'src/consts';
import { activateDevice, deactivateDevice, deleteDevice, queryDeviceList, updateDevice } from '../services/device';

const BASE = 'DEVICE';

export const actions = {
  loadingDevices: `${BASE}_LOADING_DEVICES`,
  loadDevicesSuccess: `${BASE}_LOAD_DEVICES_SUCCESS`,
  loadDevicesFailed: `${BASE}_LOAD_DEVICES_FAILED`,

  loadingPrinters: `${BASE}_LOADING_PRINTERS`,
  loadPrintersSuccess: `${BASE}_LOAD_PRINTERS_SUCCESS`,
  loadPrintersFailed: `${BASE}_LOAD_PRINTERS_FAILED`,

  updatingDevice: `${BASE}_UPDATING_DEVICE`,
  updateDeviceSuccess: `${BASE}_UPDATE_DEVICE_SUCCESS`,
  updateDeviceFailed: `${BASE}_UPDATE_DEVICE_FAILED`,

  deletingDevice: `${BASE}_DELETING_DEVICE`,
  deleteDeviceSuccess: `${BASE}_DELETE_DEVICE_SUCCESS`,
  deleteDeviceFailed: `${BASE}_DELETE_DEVICE_FAILED`,

  activatingDevice: `${BASE}_ACTIVATING_DEVICE`,
  activateDeviceSuccess: `${BASE}_ACTIVATE_DEVICE_SUCCESS`,
  activateDeviceFailed: `${BASE}_ACTIVATE_DEVICE_FAILED`,

  deactivatingDevice: `${BASE}_DEACTIVATING_DEVICE`,
  deactivateDeviceSuccess: `${BASE}_DEACTIVATE_DEVICE_SUCCESS`,
  deactivateDeviceFailed: `${BASE}_DEACTIVATE_DEVICE_FAILED`,
};

export const fetchDevicesByType = (deviceType, callback) => async () => {
  let response;

  try {
    response = await queryDeviceList(deviceType);
  } catch (e) {
    response = { success: false };
  }

  typeof callback === 'function' && callback(response);
};

export const fetchAllDevices = (callback) => async (dispatch, getState) => {
  const { device } = getState();
  const { isLoadingDevices, devices } = device;

  if (devices.length || isLoadingDevices) return;

  dispatch({
    type: actions.loadingDevices,
  });

  dispatch(
    fetchDevicesByType(undefined, (response) => {
      if (!response.success) {
        dispatch({
          type: actions.loadDevicesFailed,
        });
        return;
      }

      const devices = response.data || [];
      const printers = devices.filter((_) => _.device_type === DEVICE_TYPE_ENUMS.PRINTER);

      dispatch({
        type: actions.loadDevicesSuccess,
        payload: { devices, printers },
      });
      typeof callback === 'function' && callback(devices);
    })
  );
};

export const fetchPrinters = () => async (dispatch, getState) => {
  const { printers, isLoadingPrinters } = getState().device;
  if (isLoadingPrinters || printers.length) return;

  dispatch({ type: actions.loadingPrinters });
  dispatch(
    fetchDevicesByType(DEVICE_TYPE_ENUMS.PRINTER, (response) => {
      if (!response.success) {
        dispatch({ type: actions.loadPrintersFailed });
        return;
      }

      dispatch({
        type: actions.loadPrintersSuccess,
        payload: { printers: response.data || [] },
      });
    })
  );
};

const invokeDeviceUpdateCallback = (callback, response) => {
  typeof callback === 'function' && callback(response);
};

export const doUpdateDevice = (deviceData, callback) => async (dispatch, getState) => {
  dispatch({
    type: actions.updatingDevice,
  });

  let response;

  try {
    response = await updateDevice(deviceData);
  } catch (e) {
    response = { success: false };
  }

  if (!response.success) {
    dispatch({
      type: actions.updateDeviceFailed,
    });
    invokeDeviceUpdateCallback(callback, response);
    return;
  }

  const deviceState = getState().device;
  const { devices } = deviceState;
  const updatedState = {};
  const newDevices = [...devices];
  const index = newDevices.findIndex((_) => _.id === deviceData.id);
  if (index > -1) {
    newDevices[index] = deviceData;
    updatedState.devices = newDevices;
  }

  if (deviceData.device_type === DEVICE_TYPE_ENUMS.PRINTER) {
    const { printers } = deviceState;
    const newPrinters = [...printers];
    const idx = newPrinters.findIndex((_) => _.id === deviceData.id);
    if (idx > -1) {
      newPrinters[idx] = deviceData;
      updatedState.printers = newPrinters;
    }
  }

  dispatch({
    type: actions.updateDeviceSuccess,
    payload: updatedState,
  });

  invokeDeviceUpdateCallback(callback, response);
};

export const doDeleteDevice = (deviceId) => async (dispatch, getState) => {
  dispatch({
    type: actions.deletingDevice,
  });

  let response;

  try {
    response = await deleteDevice(deviceId);
  } catch (e) {
    response = { success: false };
  }

  if (!response.success) {
    dispatch({
      type: actions.deleteDeviceFailed,
    });
    return;
  }

  dispatch({
    type: actions.deleteDeviceSuccess,
  });
  const { devices } = getState().device;
  const newDeivces = [...devices];
  const index = newDeivces.findIndex((_) => _.id === deviceId);
  if (index < 0) return;
  newDeivces.splice(index, 1);
  dispatch({
    type: actions.loadDevicesSuccess,
    payload: { devices: newDeivces },
  });
};

export const doActivateDevice = (deviceId, callback) => async (dispatch) => {
  dispatch({
    type: actions.activatingDevice,
  });

  try {
    const response = await activateDevice(deviceId);

    if (!response.success) {
      dispatch({
        type: actions.activateDeviceFailed,
      });
      return;
    }

    dispatch({
      type: actions.activateDeviceSuccess,
    });

    typeof callback === 'function' && callback();
  } catch (e) {
    dispatch({
      type: actions.activateDeviceFailed,
    });
  }
};

export const doDeactivateDevice = (deviceId, callback) => async (dispatch) => {
  dispatch({
    type: actions.deactivatingDevice,
  });

  try {
    const response = await deactivateDevice(deviceId);

    if (!response.success) {
      dispatch({
        type: actions.deactivateDeviceFailed,
      });
      return;
    }

    dispatch({
      type: actions.deactivateDeviceSuccess,
    });
    typeof callback === 'function' && callback();
  } catch (e) {
    dispatch({
      type: actions.deactivateDeviceFailed,
    });
  }
};
