import { GET, POST, PUT } from '../utils/request';
import { CLIENT_ID, LOYALTY_API_PREFIX } from '../consts';

export async function getRestaurantProfile({ locale, restaurant_id }) {
  return GET(
    `${LOYALTY_API_PREFIX}/api/wait_list/v1/restaurant_profiles`,
    {
      restaurant_id: restaurant_id,
    },
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
    }
  );
}

export async function updateRestaurantProfile({ locale, restaurant_id, restaurant_profile_for_waitlist }) {
  return POST(
    `${LOYALTY_API_PREFIX}/api/wait_list/v1/restaurant_profiles`,
    {
      restaurant_id: restaurant_id,
      restaurant_profile: {
        ...restaurant_profile_for_waitlist,
      },
    },
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
    }
  );
}

export async function getTableQueueList({ locale, restaurant_id }) {
  return GET(
    `${LOYALTY_API_PREFIX}/api/wait_list/v1/restaurant_queues`,
    {
      restaurant_id: restaurant_id,
    },
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
    }
  );
}

export async function updateTableQueueList({ locale, restaurant_id, restaurant_queues }) {
  return POST(
    `${LOYALTY_API_PREFIX}/api/wait_list/v1/restaurant_queues`,
    {
      restaurant_id: restaurant_id,
      restaurant_queues: [...restaurant_queues],
    },
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
    }
  );
}

export async function updateWaitCustomerReply({ key, customer_response }) {
  return PUT(
    `${LOYALTY_API_PREFIX}/api/en/wait_list/web/progresses/${key}/reply`,
    {
      customer_response,
    },
    {
      noNeedLogin: true,
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
    }
  );
}
