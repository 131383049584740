import { actions as userActions } from '../actions/user';

const initState = {
  logging: false,
  isLoginSuccess: false,
  isLoginFailed: false,

  isLoadingPermissionCode: false,
  isLoadPermissionCodeDone: false,

  loadingMenu: false,
  isLoadMenuSuccess: false,
  isLoadMenuFailed: false,

  isRefreshingToken: false,
  isRefreshTokenSuccess: false,
  isRefreshTokenFailed: false,

  isLoadingOwnerInfo: false,
  isLoadOwnerInfoSuccess: false,
  isLoadOwnerInfoFailed: false,

  isLoadOwnRestaurantsSuccess: false,

  permissionCodes: [],
  menus: [],
  ownerInfo: {},
  ownedRestaurants: [],

  currentUser: null,
  currentRestaurantId: null,
  email: null,
  currentEmployee: null,

  isLoadingRestGroupInfo: false,
  isLoadRestaurantGroupInfoDone: false,
  restaurantGroupInfo: null,
  restaurantGroupDealContract: null,
  restaurantGroupGiftCardDealContract: null,

  // cached feature flags
  featureEnabledState: {},
};

export default (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case userActions.login: {
      return {
        ...state,
        logging: true,
        isLoginSuccess: false,
        isLoginFailed: false,
      };
    }

    case userActions.loginSuccess: {
      return {
        ...state,
        logging: false,
        isLoginSuccess: true,
        isLoginFailed: false,
        appReady: true,
      };
    }

    case userActions.loginFailed: {
      return {
        ...state,
        logging: false,
        isLoginSuccess: false,
        isLoginFailed: true,
      };
    }

    case userActions.loadingPermissionCodes: {
      return {
        ...state,
        isLoadingPermissionCode: true,
        isLoadPermissionCodeDone: false,
      };
    }

    case userActions.loadPermissionCodesSuccess: {
      return {
        ...state,
        isLoadingPermissionCode: false,
        isLoadPermissionCodeDone: true,
        permissionCodes: payload,
      };
    }

    case userActions.loadPermissionCodesFailed: {
      return {
        ...state,
        isLoadingPermissionCode: false,
        isLoadPermissionCodeDone: false,
        permissionCodes: [],
      };
    }

    case userActions.loadingMenus: {
      return {
        ...state,
        loadingMenu: true,
        isLoadMenuSuccess: false,
        isLoadMenuFailed: false,
      };
    }

    case userActions.loadingMenuSuccess: {
      return {
        ...state,
        loadingMenu: false,
        isLoadMenuSuccess: true,
        isLoadMenuFailed: false,
        menus: payload,
      };
    }

    case userActions.loadingMenuFailed: {
      return {
        ...state,
        loadingMenu: false,
        isLoadMenuSuccess: false,
        isLoadMenuFailed: true,
      };
    }

    case userActions.refreshingToken: {
      return {
        ...state,
        isRefreshingToken: true,
        isRefreshTokenSuccess: false,
        isRefreshTokenFailed: false,
      };
    }

    case userActions.refreshTokenSuccess: {
      return {
        ...state,
        isRefreshingToken: false,
        isRefreshTokenSuccess: true,
        isRefreshTokenFailed: false,
      };
    }

    case userActions.refreshTokenFailed: {
      return {
        ...state,
        isRefreshingToken: false,
        isRefreshTokenSuccess: false,
        isRefreshTokenFailed: true,
      };
    }

    case userActions.loadingOwnerInfo: {
      return {
        ...state,
        isLoadingOwnerInfo: true,
        isLoadOwnerInfoSuccess: false,
        isLoadOwnerInfoFailed: false,
      };
    }

    case userActions.loadOwnerInfoSuccess: {
      return {
        ...state,
        isLoadingOwnerInfo: false,
        isLoadOwnerInfoSuccess: true,
        isLoadOwnerInfoFailed: false,
        ownerInfo: payload,
      };
    }

    case userActions.loadOwnerInfoFailed: {
      return {
        ...state,
        isLoadingOwnerInfo: false,
        isLoadOwnerInfoSuccess: false,
        isLoadOwnerInfoFailed: true,
      };
    }

    case userActions.cacheUserInfo: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case userActions.clearAllCachedData: {
      return {
        ...state,
        currentUser: null,
        currentRestaurantId: null,
        email: null,
      };
    }

    case userActions.clearMenusPermissions: {
      return {
        ...state,
        permissionCodes: [],
        menus: [],
        ownerInfo: {},
        ownedRestaurants: [],
      };
    }

    case userActions.cacheEmployeeInfo: {
      return {
        ...state,
        ...payload,
      };
    }

    case userActions.loadOwnedRestaurantsSuccess: {
      return {
        ...state,
        isLoadOwnRestaurantsSuccess: true,
        ownedRestaurants: payload,
      };
    }

    case userActions.loadOwnedRestaurantsFailed: {
      return {
        ...state,
        isLoadOwnRestaurantsSuccess: false,
        ownedRestaurants: [],
      };
    }

    case userActions.loadRestaurantGroupInfo: {
      return {
        ...state,
        isLoadingRestGroupInfo: true,
        isLoadRestaurantGroupInfoDone: false,
        restaurantGroupInfo: null,
      };
    }

    case userActions.loadRestaurantGroupInforSucess: {
      return {
        ...state,
        isLoadingRestGroupInfo: false,
        isLoadRestaurantGroupInfoDone: true,
        ...(payload || {}),
      };
    }

    case userActions.loadRestaurantGroupInforFailed: {
      return {
        ...state,
        isLoadingRestGroupInfo: false,
        isLoadRestaurantGroupInfoDone: true,
        restaurantGroupInfo: null,
      };
    }

    case userActions.updateFeatureFlagState: {
      return {
        ...state,
        featureEnabledState: payload,
      };
    }

    default:
      return state;
  }
};
