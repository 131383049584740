import { API_PREFIX } from '../consts';
import { GET, POST, PUT, DELETE } from '../utils/request';

export function queryFloorPlanSections() {
  return GET(`${API_PREFIX}/v1/floor/sections`);
}

export function queryFloorPlanSectionDetail(id, showLoading = false) {
  return GET(`${API_PREFIX}/v1/floor/section/${id}`, {}, { silence: true, showLoading });
}

export function createFloorPlanSection(data) {
  return POST(`${API_PREFIX}/v1/floor/section`, data);
}

export function updateFloorPlanSection(data) {
  return PUT(`${API_PREFIX}/v1/floor/section`, data);
}

export function sortFloorPlanSections(data) {
  return PUT(`${API_PREFIX}/v1/floor/sections/sequence`, data);
}

export function deleteFloorPlanSection(id) {
  return DELETE(`${API_PREFIX}/v1/floor/section/${id}`);
}

export function updateTableQREnabled(data) {
  return PUT(`${API_PREFIX}/v1/floor/tables/qr`, data);
}
