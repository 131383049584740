import { GET, POST, PUT, DELETE } from '../utils/request';
import { API_PREFIX } from '../consts';

export function queryFeeList() {
  return GET(`${API_PREFIX}/v3/config/fees`);
}

export function queryFeeListOfV1() {
  return GET(`${API_PREFIX}/v1/config/fees`);
}

export function createFee(data) {
  return POST(`${API_PREFIX}/v1/config/fee`, data);
}

export function updateFee({ id, ...data } = {}) {
  return PUT(`${API_PREFIX}/v1/config/fee/${id}`, data);
}

export function deleteFee(id) {
  return DELETE(`${API_PREFIX}/v1/config/fee/${id}`);
}

export function batchUpdateFees(payload) {
  return PUT(`${API_PREFIX}/v1/config/fees`, payload);
}
