import { queryCdsPreference, updateCdsPreference } from 'src/services/cdsPreference';

const BASE = 'CDS_PREFERENCE';

export const actions = {
  loadingCdsPreference: `${BASE}_LOADING_PREFERENCE`,
  loadCdsPreferenceSuccess: `${BASE}_LOAD_PREFERENCE_SUCCESS`,
  loadCdsPreferenceFailed: `${BASE}_LOAD_PREFERENCE_FAILED`,

  updatingCdsPreference: `${BASE}_UPDATING_PREFERENCE`,
  updateCdsPreferenceSuccess: `${BASE}_UPDATE_PREFERENCE_SUCCESS`,
  updateCdsPreferenceFailed: `${BASE}_UPDATE_PREFERENCE_FAILED`,
};

export const fetchCdsPreference = (refresh, callback) => async (dispatch, getState) => {
  const { isLoadCdsPreference, cdsPreference: existedPreference } = getState().cdsPreference;
  if (!refresh && isLoadCdsPreference) {
    typeof callback === 'function' && callback(existedPreference);
    return;
  }

  dispatch({ type: actions.loadingCdsPreference });

  let response;

  try {
    response = await queryCdsPreference();
  } catch (e) {
    response = { success: false };
  }

  if (!response.success) {
    dispatch({ type: actions.loadCdsPreferenceFailed });
    return;
  }

  const cdsPreference = response.data || {};

  dispatch({ type: actions.loadCdsPreferenceSuccess, payload: cdsPreference });

  typeof callback === 'function' && callback(cdsPreference);
};

export const doUpdateCdsPreference = (data, callback) => async (dispatch) => {
  dispatch({ type: actions.updatingCdsPreference });

  let response;

  try {
    response = await updateCdsPreference(data);
  } catch (e) {
    response = { success: false };
  }

  typeof callback === 'function' && callback(response);

  if (!response.success) {
    dispatch({ type: actions.updateCdsPreferenceFailed });
    return;
  }

  dispatch({ type: actions.updateCdsPreferenceSuccess, payload: data });
};
