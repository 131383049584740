import { actions as taskActions } from '../actions/task';

const initialState = {
  isLoadingTasks: false,
  isLoadTasksSuccess: false,
  isLoadTasksFailed: false,

  tasks: [],
  pagination: { current: 1, pageSize: 10, total: 0 },
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case taskActions.loadingTasks: {
      return {
        ...state,
        isLoadingTasks: true,
        isLoadTasksSuccess: false,
        isLoadTasksFailed: false,
      };
    }

    case taskActions.loadTasksSuccess: {
      return {
        ...state,
        isLoadingTasks: false,
        isLoadTasksSuccess: true,
        isLoadTasksFailed: false,
        ...payload,
      };
    }

    case taskActions.loadTasksFailed: {
      return {
        ...state,
        isLoadingTasks: false,
        isLoadTasksSuccess: false,
        isLoadTasksFailed: true,
        tasks: [],
        pagination: { current: 1, pageSize: 10, total: 0 },
      };
    }

    default:
      return state;
  }
};
