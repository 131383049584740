export const THEME_MODE = {
  DARK: 'dark',
  LIGHT: 'light',
};

const RGB_COLOR_PREFIX = 'rgb';
// const RGBA_COLOR_PREFIX = 'rgba';
const HEX_COLOR_PREFIX = '#';

function getHexColorValue(colorValue) {
  return '0x' + (colorValue.length === 2 ? colorValue : colorValue + colorValue);
}

function getGrayValue({ r, g, b }) {
  return r * 0.299 + g * 0.587 + b * 0.114;
}

function detectThemeByGrayValue(grayValue = 0) {
  return grayValue > 192 ? THEME_MODE.LIGHT : THEME_MODE.DARK;
}

export function detectColorStyle(color = '') {
  if (!color) return THEME_MODE.DARK;

  if (color.startsWith(HEX_COLOR_PREFIX)) {
    const colorValue = color.slice(HEX_COLOR_PREFIX.length);
    if (colorValue.length === 3) {
      const [r, g, b] = colorValue.split('');
      const rStr = getHexColorValue(r);
      const gStr = getHexColorValue(g);
      const bStr = getHexColorValue(b);
      const grayValue = getGrayValue({
        r: parseInt(rStr),
        g: parseInt(gStr),
        b: parseInt(bStr),
      });
      return detectThemeByGrayValue(grayValue);
    }

    if (colorValue.length === 6) {
      const r = colorValue.substring(0, 2);
      const g = colorValue.substring(2, 4);
      const b = colorValue.substring(4, 6);
      const rStr = getHexColorValue(r);
      const gStr = getHexColorValue(g);
      const bStr = getHexColorValue(b);
      const grayValue = getGrayValue({
        r: parseInt(rStr),
        g: parseInt(gStr),
        b: parseInt(bStr),
      });
      return detectThemeByGrayValue(grayValue);
    }
  }

  if (color.startsWith(RGB_COLOR_PREFIX)) {
    const leftBracketIndex = color.indexOf('(');
    const rightBracketIndex = color.indexOf(')');
    const colorStr = color.substring(leftBracketIndex + 1, rightBracketIndex);
    const [r, g, b] = colorStr.split(',');
    const grayValue = getGrayValue({
      r: parseInt(r),
      g: parseInt(g),
      b: parseInt(b),
    });
    return detectThemeByGrayValue(grayValue);
  }

  return THEME_MODE.DARK;
}

export function isColorWhite(color = '') {
  if (!color) return;
  const lowerCaseColor = color.toLocaleLowerCase();
  return ['#fff', '#ffffff'].includes(lowerCaseColor);
}
