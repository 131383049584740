import { actions as settingActions } from 'src/actions/setting';
import { ALL_PLATFORMS } from 'src/consts';

const initialState = {
  isLoadingRestaurantProfile: false,
  isLoadRestaurantProfileDone: false,
  isLoadingEmployeeWorkflowSettings: false,
  isLoadEmployeeWorkflowSettingSuccess: false,
  isUpdatingEmployeeWorkflowSettings: false,
  restaurantInfo: {},
  settings: null,
  onlinePlatforms: ALL_PLATFORMS,
  employeeWorkflowSettings: {},
  activated: false,
  checkNewPayoutReady: true,
  openNewpayoutReport: true,
  isLoadingOnlineOrderingSettings: false,
  isLoadOnlineOrderingSettingsSuccess: false,
  isLoadOnlineOrderingSettingsFailed: false,
  onlineOrderingSettings: {},
  isUpdatingOnlineOrderingSettings: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case settingActions.loadingRestaurantProfile: {
      return {
        ...state,
        isLoadingRestaurantProfile: true,
        isLoadRestaurantProfileDone: false,
      };
    }

    case settingActions.loadRestaurantProfileSuccess: {
      return {
        ...state,
        isLoadingRestaurantProfile: false,
        isLoadRestaurantProfileDone: true,
        ...payload,
      };
    }

    case settingActions.loadRestaurantProfileFailed: {
      return {
        ...state,
        isLoadingRestaurantProfile: false,
        isLoadRestaurantProfileDone: false,
      };
    }

    case settingActions.updateRestaurantInfo: {
      return {
        ...state,
        restaurantInfo: {
          ...state.restaurantInfo,
          ...payload,
        },
      };
    }

    case settingActions.updateRestaurantSettings: {
      return {
        ...state,
        settings: payload,
      };
    }

    case settingActions.loadingEmployeeWorkflowSettings: {
      return {
        ...state,
        isLoadingEmployeeWorkflowSettings: true,
        isLoadEmployeeWorkflowSettingSuccess: false,
      };
    }

    case settingActions.loadEmployeeWorkflowSettingsSuccess: {
      return {
        ...state,
        isLoadingEmployeeWorkflowSettings: false,
        isLoadEmployeeWorkflowSettingSuccess: true,
        employeeWorkflowSettings: payload,
      };
    }

    case settingActions.loadEmployeeWorkflowSettingsFailed: {
      return {
        ...state,
        isLoadingEmployeeWorkflowSettings: false,
        isLoadEmployeeWorkflowSettingSuccess: false,
        employeeWorkflowSettings: {},
      };
    }

    case settingActions.updatingEmployeeWorkforceSettings: {
      return {
        ...state,
        isUpdatingEmployeeWorkflowSettings: true,
      };
    }

    case settingActions.updateEmployeeWorkforceSettingsSuccess: {
      return {
        ...state,
        isUpdatingEmployeeWorkflowSettings: false,
        employeeWorkflowSettings: payload,
      };
    }

    case settingActions.updateEmployeeWorkforceSettingsFailed: {
      return {
        ...state,
        isUpdatingEmployeeWorkflowSettings: false,
      };
    }

    case settingActions.setActivatedStatus: {
      return {
        ...state,
        activated: payload,
      };
    }

    case settingActions.loadingOnlineOrderingSettings: {
      return {
        ...state,
        isLoadingOnlineOrderingSettings: true,
        isLoadOnlineOrderingSettingsSuccess: false,
        isLoadOnlineOrderingSettingsFailed: false,
      };
    }

    case settingActions.loadOnlineOrderingSettingsFailed: {
      return {
        ...state,
        isLoadingOnlineOrderingSettings: false,
        isLoadOnlineOrderingSettingsSuccess: false,
        isLoadOnlineOrderingSettingsFailed: true,
        onlineOrderingSettings: {},
      };
    }

    case settingActions.loadOnlineOrderingSettingsSuccess: {
      return {
        ...state,
        isLoadingOnlineOrderingSettings: false,
        isLoadOnlineOrderingSettingsSuccess: true,
        isLoadOnlineOrderingSettingsFailed: false,
        onlineOrderingSettings: payload,
      };
    }

    case settingActions.updatingOnlineOrderingSettings: {
      return {
        ...state,
        isUpdatingOnlineOrderingSettings: true,
      };
    }

    case settingActions.updateOnlineOrderingFailed: {
      return {
        ...state,
        isUpdatingOnlineOrderingSettings: false,
      };
    }

    case settingActions.updateOnlineOrderingSuccess: {
      return {
        ...state,
        isUpdatingOnlineOrderingSettings: false,
        onlineOrderingSettings: payload,
      };
    }

    default:
      return state;
  }
};
