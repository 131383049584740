import { actions as salesCategoryActions } from '../actions/salesCategory';

const initialState = {
  isLoading: false,
  isLoadSalesCategorySuccess: false,
  isLoadSalesCategoryFailed: false,

  isCreatingSalesCategory: false,
  isCreateSalesCategorySuccess: false,
  isCreateSalesCategoryFailed: false,

  isUpdatingSalesCategory: false,
  isUpdateSalesCategorySuccess: false,
  isUpdateSalesCategoryFailed: false,

  salesCategorys: [],
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case salesCategoryActions.loadingSalesCategorys: {
      return {
        ...state,
        isLoading: true,
        isLoadSalesCategorySuccess: false,
        isLoadSalesCategoryFailed: false,
      };
    }

    case salesCategoryActions.loadSalesCategorySuccess: {
      return {
        ...state,
        isLoading: false,
        isLoadSalesCategorySuccess: true,
        isLoadSalesCategoryFailed: false,
        ...payload,
      };
    }

    case salesCategoryActions.loadSalesCategoryFailed: {
      return {
        ...state,
        isLoading: false,
        isLoadSalesCategorySuccess: false,
        isLoadSalesCategoryFailed: true,
      };
    }

    case salesCategoryActions.creatingSalesCategory: {
      return {
        ...state,
        isCreatingSalesCategory: true,
        isCreateSalesCategorySuccess: false,
        isCreateSalesCategoryFailed: false,
      };
    }

    case salesCategoryActions.createSalesCategorySuccess: {
      return {
        ...state,
        isCreatingSalesCategory: false,
        isCreateSalesCategorySuccess: true,
        isCreateSalesCategoryFailed: false,
      };
    }

    case salesCategoryActions.createSalesCategoryFailed: {
      return {
        ...state,
        isCreatingSalesCategory: false,
        isCreateSalesCategorySuccess: false,
        isCreateSalesCategoryFailed: true,
      };
    }

    case salesCategoryActions.updatingSalesCategory: {
      return {
        ...state,
        isUpdatingSalesCategory: true,
        isUpdateSalesCategorySuccess: false,
        isUpdateSalesCategoryFailed: false,
      };
    }

    case salesCategoryActions.updateSalesCategorySuccess: {
      return {
        ...state,
        isUpdatingSalesCategory: false,
        isUpdateSalesCategorySuccess: true,
        isUpdateSalesCategoryFailed: false,
      };
    }

    case salesCategoryActions.updateSalesCategoryFailed: {
      return {
        ...state,
        isUpdatingSalesCategory: false,
        isUpdateSalesCategorySuccess: false,
        isUpdateSalesCategoryFailed: true,
      };
    }

    default:
      return state;
  }
};
