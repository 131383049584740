import { actions as orderActions } from '../actions/order';

const initialState = {
  isLoadingOrders: false,
  isLoadOrdersSuccess: false,
  isLoadOrdersFailed: false,

  isLoadingOrderDetail: false,
  isLoadOrderDetailSuccess: false,
  isLoadOrderDetailFailed: false,

  orders: [],
  pagination: { current: 1, pageSize: 10, total: 0 },
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case orderActions.loadingOrders: {
      return {
        ...state,
        isLoadingOrders: true,
        isLoadOrdersSuccess: false,
        isLoadOrdersFailed: false,
      };
    }

    case orderActions.loadOrderSuccess: {
      return {
        ...state,
        isLoadingOrders: false,
        isLoadOrdersSuccess: true,
        isLoadOrdersFailed: false,
        ...payload,
      };
    }

    case orderActions.loadOrderFailed: {
      return {
        ...state,
        isLoadingOrders: false,
        isLoadOrdersSuccess: false,
        isLoadOrdersFailed: true,
      };
    }

    case orderActions.updateOrders: {
      return {
        ...state,
        orders: payload,
      };
    }

    default:
      return state;
  }
};
