import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { Alert } from 'antd';
import { FormattedMessage } from 'react-intl';
import EventEmitter, { EVENTS } from '../../utils/eventEmitter';
import QRCodeTaskCluster from './QRCodeCluster';
import styles from './index.less';
import { NORMAL_QRCODE_TYPE, TAKEOUT_QRCODE_TYPE, MENUBROWSING_QRCODE_TYPE } from 'src/consts';

const QRCodeContainerId = 'QRCode-Container';

const DownloadProgress = ({ executionInfo: { executed, total }, onAbort, showProgress }) => {
  if (!showProgress) return null;

  return (
    <Alert
      type="info"
      className={styles['downloading-tip']}
      message={
        <div>
          <FormattedMessage id="smartOrderingSettings.downloadingA5" values={{ count: executed, total }} />
          {executed < total && (
            <a style={{ marginLeft: 15 }} onClick={onAbort}>
              <FormattedMessage id="smartOrderingSettings.cancelDownloading" />
            </a>
          )}
        </div>
      }
    />
  );
};

const QRCodesDownloader = () => {
  const containerRef = useRef(null);
  const clusterRef = useRef(null);
  const [executionInfo, setExecutionInfo] = useState(() => ({ total: 0, executed: 0 }));
  const [showProgress, setShowProgress] = useState(false);

  useEffect(() => {
    let element = document.getElementById(QRCodeContainerId);
    if (!element) {
      element = document.createElement('div');
      element.setAttribute('id', QRCodeContainerId);
      document.body.appendChild(element);
    }
    containerRef.current = element;

    async function handleTaskExecuted({ total, executed }) {
      setExecutionInfo({ total, executed });
    }

    function handleAllDone() {
      setShowProgress(false);
      setExecutionInfo({ total: 0, executed: 0 });
    }

    async function handleStartDownloadQRCodes(payload) {
      const { tables, tableLinks, onAllTasksDone, qrCodeType = NORMAL_QRCODE_TYPE, isQuickService, ...other } = payload;
      let tasks;

      if (qrCodeType !== NORMAL_QRCODE_TYPE || isQuickService) {
        tasks = [
          {
            ...other,
            code: tableLinks[0]?.short_link,
            isQuickService,
            qrCodeType,
          },
        ];
      } else {
        tasks = tables.map((table) => {
          const code = tableLinks.find((_) => _.table_id === table.id)?.short_link;
          return {
            ...other,
            code,
            isQuickService,
            tableName: table.name,
            sectionName: table.section?.name,
            qrCodeType,
          };
        });
      }

      setExecutionInfo({ total: tasks.length, executed: 0 });
      setShowProgress(true);
      let cluster = clusterRef.current;
      if (!cluster) {
        cluster = clusterRef.current = new QRCodeTaskCluster({
          onTaskDone: handleTaskExecuted,
          onAllDone: handleAllDone,
        });
      }

      cluster.addQRCodeTasks({ tasks, onAllTasksDone });
    }

    EventEmitter.on(EVENTS.START_DOWNLOADING_QR_CODES, handleStartDownloadQRCodes);

    return () => {
      document.body.removeChild(containerRef.current);
      EventEmitter.off(EVENTS.START_DOWNLOADING_QR_CODES, handleStartDownloadQRCodes);
    };
  }, []);

  const handleAbort = () => {
    const cluster = clusterRef.current;
    if (cluster) cluster.abort();
    setShowProgress(false);
  };

  if (!containerRef.current) return null;
  return ReactDOM.createPortal(
    <DownloadProgress executionInfo={executionInfo} onAbort={handleAbort} showProgress={showProgress} />,
    containerRef.current
  );
};

export default QRCodesDownloader;
