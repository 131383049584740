import {
  drawRadialGradient,
  getCanvas,
  DEMOLOGO,
  CM3x3StickerQRCodeSize,
  CM4x4AcrylicSheetsQRCodeSize,
  StickerLogoSize,
  scale,
  StandQRCodeSize,
  Inch5x8StandQRCodeSize,
  StickerQRCodeSize,
  StandLogoSize,
  AcrylicSheetsLogoSize,
  LoyaltyQRCodeSize,
  LoyaltyQRWrapperSize,
  Loyalty5x8InchQRWrapperSize,
  Loyaltyy5x8InchQRCodeSize,
  LoyaltyLogoSize,
  BottomLogoSize,
  WifiImageSize,
  Inch_5X8_StandSize,
  A5StandSize,
  StandSize,
  Inch_4X6_StickerSize,
  CM_3X3_StickerSize,
  CM_4X4_AcrylicSheetsSize,
  StickerSize,
  checkIsHasLoyaltyContract,
  getBottomTextWithWidth,
  scanENImage,
  scanZHImage,
  fullENImage,
  fullZHImage,
  noCashBackENImage,
  noCashBackZHImage,
  noDiscountENImage,
  noDiscountZHImage,
  prepaidCardBonusENImage,
  prepaidCardBonusZHImage,
  prepaidCardNoBonusENImage,
  prepaidCardNoBonusZHImage,
  wifiImage,
} from './DrawQRCode';
import { NORMAL_QRCODE_TYPE, MENUBROWSING_QRCODE_TYPE, DISTRIBUTION_MODES } from '../../consts';
import { qrCodeServiceDomain } from '../../consts/third_party';
import { getPrepaidCardMaxBonusRatio, loadImage } from '../../utils/utils';
import { fittingStringEllipsis, drawRoundRect, generateQRCode, ELLIPSIS_TEXT } from '../../utils/drawLoyaltyQRCode';
import { FONT_FAMILY } from 'src/consts/fonts';

//A5 stand menu browsing mode 菜单浏览模式
const menuBrowsingStandENBackgroundImage = `${process.env.ASSETS_PREFIX}/assets/qrcode/browsing_menu/qr_bg_a5_en.svg`;
const menuBrowsingStandZHBackgroundImage = `${process.env.ASSETS_PREFIX}/assets/qrcode/browsing_menu/qr_bg_a5_zh.svg`;
const menuBrowsingStandENBackgroundImageWithWifi = `${process.env.ASSETS_PREFIX}/assets/qrcode/browsing_menu/qr_bg_a5_en_wifi.svg`;
const menuBrowsingStandZHBackgroundImageWithWifi = `${process.env.ASSETS_PREFIX}/assets/qrcode/browsing_menu/qr_bg_a5_zh_wifi.svg`;
//A5 stand no menu browsing mode
const standENBackgroundImage = `${process.env.ASSETS_PREFIX}/assets/qrcode/qr_bg_a5_en.svg`;
const standZHBackgroundImage = `${process.env.ASSETS_PREFIX}/assets/qrcode/qr_bg_a5_zh.svg`;
const standENBackgroundImageWithWifi = `${process.env.ASSETS_PREFIX}/assets/qrcode/qr_bg_a5_en_wifi.svg`;
const standZHBackgroundImageWithWifi = `${process.env.ASSETS_PREFIX}/assets/qrcode/qr_bg_a5_zh_wifi.svg`;

//A5 stand QRCode Poster
export async function drawA5StandQRCode(taskData) {
  const { restaurantInfo, size, qrCodeType } = taskData;
  const hasLoyaltyContract = checkIsHasLoyaltyContract(restaurantInfo);
  const images = [];

  let hasLoyalty = hasLoyaltyContract;

  if (hasLoyalty) {
    let { loyaltySettings } = taskData;

    if (!loyaltySettings) {
      loyaltySettings = { cash_back_ratio: '0.00', discount_ratio: '1.00' };
    }

    const { prepaidCardSettings } = taskData;
    const { cash_back_ratio, discount_ratio } = loyaltySettings || {};
    hasLoyalty = +discount_ratio !== 1 || cash_back_ratio > 0 || !!prepaidCardSettings?.is_active;
  }
  if (hasLoyalty) {
    const canvas1 = getCanvas(size);
    const ctx1 = canvas1.getContext('2d');
    await drawLoyaltyStandQRCodeInEnglish({ ctx: ctx1, taskData });
    const image1 = canvas1.toDataURL();
    images.push(image1);
    const canvas2 = getCanvas(size);
    const ctx2 = canvas2.getContext('2d');
    await drawLoyaltyStandQRCodeInChinese({ ctx: ctx2, taskData });
    const image2 = canvas2.toDataURL();
    images.push(image2);
  } else {
    const canvas2 = getCanvas(size);
    const ctx2 = canvas2.getContext('2d');
    await drawNonLoyaltyStandQRCodeInChinese({ ctx: ctx2, taskData });
    const image2 = canvas2.toDataURL();
    images.push(image2);

    const canvas1 = getCanvas(size);
    const ctx1 = canvas1.getContext('2d');
    await drawNonLoyaltyStandQRCodeInEnglish({ ctx: ctx1, taskData });
    const image1 = canvas1.toDataURL();
    images.push(image1);
  }

  return images;
}

async function drawBottomContentWithWifiInfo({ ctx, taskData, width, fontSize }) {
  const wifiImg = await loadImage(wifiImage);
  let x = (20 - 3) * scale;
  ctx.drawImage(wifiImg, x, 554 * scale, WifiImageSize, WifiImageSize);

  const { restaurantInfo } = taskData;
  const { logo_url, wifi_ssid, wifi_password } = restaurantInfo;

  // wifi info
  ctx.save();
  ctx.font = `normal ${fontSize} ${FONT_FAMILY.PROXIMA_NOVA}`;
  ctx.fillStyle = '#1D1B2E';
  x += WifiImageSize + 4 * scale;
  const wifiTextMaxWidth = width * 0.5 * scale - WifiImageSize - 4 * scale;
  ctx.fillText(`Wi-Fi: ${wifi_ssid}`, x, 564 * scale, wifiTextMaxWidth);
  ctx.fillText(`Password: ${wifi_password || ''}`, x, 582 * scale, wifiTextMaxWidth);
  ctx.restore();

  // restaurant names
  let maxWidth = width * 0.5 * scale - 20 * scale;

  if (logo_url) {
    maxWidth -= BottomLogoSize + 4 * scale;
  }

  const { nameText, foreignNameText, textMaxWidth } = getBottomTextWithWidth({ ctx, taskData, fontSize, maxWidth });
  const textX = width * scale - 20 * scale - textMaxWidth;

  if (logo_url) {
    const logoImage = await loadImage(logo_url);

    if (logoImage) {
      const logoX = textX - BottomLogoSize - 4 * scale;
      ctx.drawImage(logoImage, logoX, 546 * scale, BottomLogoSize, BottomLogoSize);
    }
  }

  ctx.save();
  ctx.font = `normal ${fontSize} ${FONT_FAMILY.PROXIMA_NOVA}`;
  ctx.fillStyle = '#1D1B2E';
  ctx.fillText(nameText, textX, 564 * scale);
  ctx.restore();

  ctx.save();
  ctx.font = `normal ${fontSize} ${FONT_FAMILY.PROXIMA_NOVA}`;
  ctx.fillStyle = '#1D1B2E';
  ctx.fillText(foreignNameText, textX, 582 * scale);
  ctx.restore();
}

async function drawBottomContentWithoutWifiInfo({ ctx, taskData, width, fontSize }) {
  const { restaurantInfo } = taskData;
  const { logo_url } = restaurantInfo;

  let maxWidth = width * scale - 20 * 2 * scale;
  const logoContentWidth = BottomLogoSize + 4 * scale;
  if (logo_url) maxWidth -= logoContentWidth;
  const { nameText, foreignNameText, textMaxWidth } = getBottomTextWithWidth({ ctx, taskData, fontSize, maxWidth });

  let fullWidth = textMaxWidth;
  if (logo_url) fullWidth += logoContentWidth;
  let x = (width * scale - fullWidth) / 2;

  if (logo_url) {
    const logoImage = await loadImage(logo_url);
    if (logoImage) {
      ctx.drawImage(logoImage, x, 546 * scale, BottomLogoSize, BottomLogoSize);

      x += logoContentWidth;
    }
  }

  ctx.save();
  ctx.font = `normal ${fontSize} ${FONT_FAMILY.PROXIMA_NOVA}`;
  ctx.fillStyle = '#1D1B2E';
  ctx.fillText(nameText, x, 564 * scale);
  ctx.restore();

  ctx.save();
  ctx.font = `normal ${fontSize} ${FONT_FAMILY.PROXIMA_NOVA}`;
  ctx.fillStyle = '#1D1B2E';
  ctx.fillText(foreignNameText, x, 582 * scale);
  ctx.restore();
}

async function drawBottomContent({ ctx, taskData, width }) {
  const fontSize = `${12 * scale}px/${14 * scale}px`;
  const { restaurantInfo } = taskData;
  const { wifi_ssid } = restaurantInfo;

  ctx.save();
  ctx.fillStyle = '#ffffff';
  ctx.fillRect(0, 541 * scale, width * scale, 54 * scale);
  ctx.restore();

  if (wifi_ssid) {
    await drawBottomContentWithWifiInfo({ ctx, fontSize, taskData, width });
    return;
  }

  await drawBottomContentWithoutWifiInfo({ ctx, fontSize, taskData, width });
}

async function drawLoyaltyQRCodeCommonContent({ ctx, taskData, width, height }) {
  await drawLoyaltyQRCode({ ctx, taskData, width });
  await drawBottomContent({ ctx, taskData, width, height });
}

async function drawLoyaltyStandQRCodeInChinese({ ctx, taskData }) {
  const { width, height } = A5StandSize;
  drawRadialGradient({ ctx, width, height });
  await drawPromotionContentInChinese({ ctx, taskData });
  await drawLoyaltyQRCodeCommonContent({ ctx, taskData, width, height });
}

async function drawLoyaltyStandQRCodeInEnglish({ ctx, taskData }) {
  const { width, height } = A5StandSize;
  drawRadialGradient({ ctx, width, height });
  await drawPromotionContentInEnglish({ ctx, taskData });
  await drawLoyaltyQRCodeCommonContent({ ctx, taskData, width, height });
}

async function drawNonLoyaltyStandQRCodeInChinese({ ctx, taskData }) {
  const { restaurantInfo, tableName, code, isQuickService, qrCodeType } = taskData;
  const { wifi_ssid, wifi_password, foreign_name, name, logo_url } = restaurantInfo;
  const { width, height } = A5StandSize;
  let image;
  if (qrCodeType === MENUBROWSING_QRCODE_TYPE) {
    if (wifi_ssid) {
      image = await loadImage(menuBrowsingStandZHBackgroundImageWithWifi);
    } else {
      image = await loadImage(menuBrowsingStandZHBackgroundImage);
    }
  } else {
    if (wifi_ssid) {
      image = await loadImage(standZHBackgroundImageWithWifi);
    } else {
      image = await loadImage(standZHBackgroundImage);
    }
  }

  ctx.drawImage(image, 0, 0, width * scale, height * scale);

  // draw restaurant foreign name
  const font = `normal ${20 * scale}px ${FONT_FAMILY.PINGFANG_SC}`;
  const { text } = fittingStringEllipsis({
    context: ctx,
    str: foreign_name || name,
    maxWidth: (width - 40) * scale,
    font,
  });
  ctx.save();
  ctx.fillStyle = '#ffffff';
  ctx.font = font;
  ctx.textAlign = 'center';
  if (qrCodeType === MENUBROWSING_QRCODE_TYPE) {
    ctx.fillText(text, width * 0.5 * scale, 202 * scale);
  } else {
    ctx.fillText(text, width * 0.5 * scale, 188 * scale);
  }

  ctx.restore();

  if (qrCodeType === NORMAL_QRCODE_TYPE && !isQuickService) {
    // draw table name
    ctx.save();
    ctx.fillStyle = '#ffffff';
    ctx.font = `bold normal ${36 * scale}px/${36 * scale}px ${FONT_FAMILY.PROXIMA_NOVA}`;
    ctx.textAlign = 'center';
    ctx.fillText(tableName, width * 0.5 * scale, 222 * scale);
    ctx.restore();
  }

  // draw qr code
  const qrCodeLink = `${qrCodeServiceDomain}?code=${code}`;
  const qrCodeImage = await generateQRCode(qrCodeLink, StandQRCodeSize);
  let y = 241 * scale;

  if (qrCodeImage) {
    const x = (width * scale - StandQRCodeSize) / 2;
    ctx.drawImage(qrCodeImage, x, y, StandQRCodeSize, StandQRCodeSize);
  }

  if (logo_url) {
    const logoImage = await loadImage(logo_url);
    if (logoImage) {
      const x = (width * scale - StandLogoSize) / 2;
      y += (StandQRCodeSize - StandLogoSize) / 2;
      ctx.drawImage(logoImage, x, y, StandLogoSize, StandLogoSize);
    }
  }

  if (!wifi_ssid) return;

  ctx.save();
  ctx.font = `normal ${14 * 3}px/${17 * 3}px ${FONT_FAMILY.PROXIMA_NOVA}`;
  ctx.fillStyle = '#1D1B2E';
  ctx.fillText(`Wi-Fi: ${wifi_ssid}`, 64 * scale, 565 * scale, 200 * scale);
  ctx.fillText(`Password: ${wifi_password || ''}`, 64 * scale, 582 * scale, 864 * scale);
  ctx.restore();
}

async function drawNonLoyaltyStandQRCodeInEnglish({ ctx, taskData }) {
  const { restaurantInfo, tableName, code, isQuickService, qrCodeType } = taskData;
  const { wifi_ssid, wifi_password, name, logo_url } = restaurantInfo;
  const { width, height } = StandSize;

  let image;
  //处理 菜单浏览模式的 背景图
  if (qrCodeType === MENUBROWSING_QRCODE_TYPE) {
    if (wifi_ssid) {
      image = await loadImage(menuBrowsingStandENBackgroundImageWithWifi);
    } else {
      image = await loadImage(menuBrowsingStandENBackgroundImage);
    }
  } else {
    if (wifi_ssid) {
      image = await loadImage(standENBackgroundImageWithWifi);
    } else {
      image = await loadImage(standENBackgroundImage);
    }
  }

  ctx.drawImage(image, 0, 0, width * scale, height * scale);

  // draw restaurant name
  const font = `normal ${20 * scale}px ${FONT_FAMILY.PROXIMA_NOVA}`;
  const { text } = fittingStringEllipsis({
    context: ctx,
    str: name || '',
    maxWidth: (width - 40) * scale,
    font,
  });
  ctx.save();
  ctx.fillStyle = '#ffffff';
  ctx.font = font;
  ctx.textAlign = 'center';
  ctx.fillText(text, width * 0.5 * scale, 188 * scale);
  ctx.restore();

  if (qrCodeType === NORMAL_QRCODE_TYPE && !isQuickService) {
    // draw table name
    ctx.save();
    ctx.fillStyle = '#ffffff';
    ctx.font = `bold normal ${36 * scale}px ${FONT_FAMILY.PROXIMA_NOVA}`;
    ctx.textAlign = 'center';
    ctx.fillText(tableName, width * 0.5 * scale, 222 * scale);
    ctx.restore();
  }

  // draw qr code
  const qrCodeLink = `${qrCodeServiceDomain}?code=${code}`;
  const qrCodeImage = await generateQRCode(qrCodeLink, StandQRCodeSize);
  let y = 241 * scale;

  if (qrCodeImage) {
    const x = (width * scale - StandQRCodeSize) / 2;
    ctx.drawImage(qrCodeImage, x, y, StandQRCodeSize, StandQRCodeSize);
  }

  if (logo_url) {
    const logoImage = await loadImage(logo_url);
    if (logoImage) {
      const x = (width * scale - StandLogoSize) / 2;
      y += (StandQRCodeSize - StandLogoSize) / 2;
      ctx.drawImage(logoImage, x, y, StandLogoSize, StandLogoSize);
    }
  }

  if (!wifi_ssid) return;

  ctx.save();
  ctx.font = `normal ${14 * 3}px/${17 * 3}px ${FONT_FAMILY.PROXIMA_NOVA}`;
  ctx.fillStyle = '#1D1B2E';
  ctx.fillText(`Wi-Fi: ${wifi_ssid}`, 64 * scale, 565 * scale, 200 * scale);
  ctx.fillText(`Password: ${wifi_password || ''}`, 64 * scale, 582 * scale, 864 * scale);
  ctx.restore();
}

async function drawPromotionContentInChinese({ ctx, taskData }) {
  const { loyaltySettings, prepaidCardSettings } = taskData;
  let { cash_back_ratio = 0, discount_ratio = 1 } = loyaltySettings || {};
  cash_back_ratio = parseInt(cash_back_ratio * 100);
  const discount = discount_ratio * 10;
  const hasDiscount = discount !== 10;
  const scanTextImage = await loadImage(scanZHImage);
  ctx.drawImage(scanTextImage, 67 * scale, 48 * scale, 285 * scale, 47 * scale);

  const discountFontSize = `${48 * scale}px`;
  const cashBackFontSize = `${46 * scale}px`;

  if (hasDiscount && cash_back_ratio > 0) {
    const fullTextImage = await loadImage(fullZHImage);
    ctx.drawImage(fullTextImage, 66 * scale, 128 * scale, 288 * scale, 178 * scale);
    ctx.save();
    ctx.fillStyle = '#FBDDB1';
    ctx.textAlign = 'center';
    ctx.font = `bold normal ${discountFontSize} 'Avenir Next Condensed'`;
    ctx.fillText(String(discount), 273 * scale, 232 * scale, 50 * scale);
    ctx.font = `bold normal ${cashBackFontSize} 'Avenir Next Condensed'`;
    ctx.fillText(`${cash_back_ratio}%`, 231 * scale, 282 * scale, 58 * scale);
    ctx.restore();
    return;
  }

  // no cashback
  if (hasDiscount && cash_back_ratio <= 0) {
    const noCashBackImage = await loadImage(noCashBackZHImage);
    ctx.drawImage(noCashBackImage, 66 * scale, 128 * scale, 288 * scale, 124 * scale);
    ctx.save();
    ctx.textAlign = 'center';
    ctx.fillStyle = '#FBDDB1';
    ctx.font = `bold normal ${discountFontSize} 'Avenir Next Condensed'`;
    ctx.fillText(String(discount), 273 * scale, 230 * scale);
    ctx.restore();
    return;
  }

  // no discount
  if (!hasDiscount && cash_back_ratio > 0) {
    const noDiscountImage = await loadImage(noDiscountZHImage);
    ctx.drawImage(noDiscountImage, 66 * scale, 128 * scale, 288 * scale, 124 * scale);
    ctx.save();
    ctx.fillStyle = '#FBDDB1';
    ctx.textAlign = 'center';
    ctx.font = `bold normal ${cashBackFontSize} 'Avenir Next Condensed'`;
    ctx.fillText(`${cash_back_ratio}%`, 229 * scale, 231 * scale, 58 * scale);
    ctx.restore();
    return;
  }

  const maxRatio = getPrepaidCardMaxBonusRatio(prepaidCardSettings);

  if (maxRatio >= 0.01) {
    const bonusImage = await loadImage(prepaidCardBonusZHImage);
    ctx.drawImage(bonusImage, 66 * scale, 128 * scale, 288 * scale, 125 * scale);
    ctx.save();
    ctx.textAlign = 'center';
    ctx.fillStyle = '#FBDDB1';
    ctx.font = `bold normal 130px "Avenir Next Condensed"`;
    ctx.fillText(`${Math.floor(maxRatio * 100)}%`, 310 * scale, 233 * scale, 60 * scale);
    ctx.restore();
    return;
  }

  const noBonusImage = await loadImage(prepaidCardNoBonusZHImage);
  ctx.drawImage(noBonusImage, 66 * scale, 128 * scale, 288 * scale, 125 * scale);
}

async function drawPromotionContentInEnglish({ ctx, taskData }) {
  const { loyaltySettings, prepaidCardSettings } = taskData;
  let { cash_back_ratio = 0, discount_ratio = 1 } = loyaltySettings || {};
  const hasDiscount = +discount_ratio !== 1;
  cash_back_ratio = parseInt(cash_back_ratio * 100);
  const discount = parseInt(100 - discount_ratio * 100);
  const scanTextImage = await loadImage(scanENImage);
  ctx.drawImage(scanTextImage, 64 * scale, 68 * scale, 289 * scale, 26 * scale);
  const discountFontSize = `${48 * scale}px`;
  const cashBackFontSize = `${24 * scale}px`;

  if (hasDiscount && cash_back_ratio > 0) {
    const fullTextImage = await loadImage(fullENImage);
    ctx.drawImage(fullTextImage, 66 * scale, 118 * scale, 288 * scale, 170 * scale);
    ctx.save();
    ctx.textAlign = 'center';
    ctx.fillStyle = '#FBDDB1';
    ctx.font = `bold normal ${discountFontSize} "Avenir Next Condensed"`;
    ctx.fillText(`${discount}%`, 121 * scale, 228 * scale, 84 * scale);
    ctx.font = `bold normal ${cashBackFontSize} "Avenir Next Condensed"`;
    ctx.fillText(`${cash_back_ratio}%`, 99 * scale, 266 * scale, 35 * scale);
    ctx.restore();
    return;
  }

  // no cashback
  if (hasDiscount && cash_back_ratio <= 0) {
    const noCashBackImage = await loadImage(noCashBackENImage);
    ctx.drawImage(noCashBackImage, 66 * scale, 118 * scale, 288 * scale, 138 * scale);
    ctx.save();
    ctx.textAlign = 'center';
    ctx.fillStyle = '#FBDDB1';
    ctx.font = `bold normal ${discountFontSize} "Avenir Next Condensed"`;
    ctx.fillText(`${discount}%`, 120 * scale, 228 * scale, 84 * scale);
    ctx.restore();
    return;
  }

  // no discount
  if (!hasDiscount && cash_back_ratio > 0) {
    const noDiscountImage = await loadImage(noDiscountENImage);
    ctx.drawImage(noDiscountImage, 66 * scale, 118 * scale, 288 * scale, 130 * scale);
    ctx.save();
    ctx.textAlign = 'center';
    ctx.fillStyle = '#FBDDB1';
    ctx.font = `bold normal ${cashBackFontSize} "Avenir Next Condensed"`;
    ctx.fillText(`${cash_back_ratio}%`, 97 * scale, 215 * scale, 40 * scale);
    ctx.restore();
    return;
  }

  const maxRatio = Math.floor(getPrepaidCardMaxBonusRatio(prepaidCardSettings) * 100);

  if (maxRatio >= 1) {
    const bonusImage = await loadImage(prepaidCardBonusENImage);
    ctx.drawImage(bonusImage, 66 * scale, 118 * scale, 288 * scale, 130 * scale);
    ctx.save();
    ctx.textAlign = 'center';
    ctx.fillStyle = '#FBDDB1';
    ctx.font = `bold normal 80px/80px "Avenir Next Condensed"`;
    ctx.fillText(`${maxRatio}%`, 246 * scale, 219 * scale, 50 * scale);
    ctx.restore();
    return;
  }

  const noBonusImage = await loadImage(prepaidCardNoBonusENImage);
  ctx.drawImage(noBonusImage, 66 * scale, 118 * scale, 288 * scale, 130 * scale);
}

async function drawLoyaltyQRCode({ ctx, taskData, width }) {
  const { restaurantInfo, tableName, code, isQuickService, qrCodeType } = taskData;
  const { logo_url } = restaurantInfo;

  if (qrCodeType === NORMAL_QRCODE_TYPE && !isQuickService) {
    // draw table name
    ctx.save();
    ctx.fillStyle = '#ffffff';
    ctx.textAlign = 'center';
    const fontSize = `${36 * scale}px`;
    ctx.font = `bold normal ${fontSize} ${FONT_FAMILY.PROXIMA_NOVA}`;
    ctx.fillText(tableName, width * 0.5 * scale, 374 * scale);
    ctx.restore();
  }

  const x = (width * scale - LoyaltyQRWrapperSize) / 2;
  drawRoundRect({
    ctx,
    x,
    y: 390 * scale,
    width: LoyaltyQRWrapperSize,
    height: LoyaltyQRWrapperSize,
    radius: 8 * scale,
  });

  // draw qrcode
  const qrCodeLink = `${qrCodeServiceDomain}?code=${code}`;
  const qrCodeImage = await generateQRCode(qrCodeLink, LoyaltyQRCodeSize);

  if (qrCodeImage) {
    const x = (width * scale - LoyaltyQRCodeSize) / 2;
    const y = 390 * scale + (LoyaltyQRWrapperSize - LoyaltyQRCodeSize) / 2;
    ctx.drawImage(qrCodeImage, x, y, LoyaltyQRCodeSize, LoyaltyQRCodeSize);
  }

  if (logo_url) {
    const logoImage = await loadImage(logo_url);
    if (logoImage) {
      const x = (width * scale - LoyaltyLogoSize) / 2;
      const y = 390 * scale + (LoyaltyQRWrapperSize - LoyaltyLogoSize) / 2;
      ctx.drawImage(logoImage, x, y, LoyaltyLogoSize, LoyaltyLogoSize);
    }
  }
}
