import { actions as appActions } from '../actions/app.js';
import en from '../locale/en.js';

export const initState = {
  locale: 'en',
  messages: en,
  baseAppConfig: {},
  allAppConfigs: [],
  isInApp: false,
  collapsed: false,
  appReady: false,
  featureConfigs: {},
  isSwitchingRestaurant: false,
  isLoadingRestaurantList: false,
  isLoadRestaurantListSuccess: false,
  restaurantList: [],
  isGroupUser: false,
};

export default (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case appActions.switchLocale:
      return {
        ...state,
        ...payload,
      };

    case appActions.appConfigLoaded: {
      return {
        ...state,
        ...payload,
      };
    }

    case appActions.setIsInApp: {
      return {
        ...state,
        isInApp: payload,
      };
    }

    case appActions.changeLayoutCollapsed: {
      return {
        ...state,
        collapsed: action.payload,
      };
    }

    case appActions.setReady: {
      return {
        ...state,
        appReady: payload,
      };
    }

    case appActions.switchingRestaurant: {
      return {
        ...state,
        isSwitchingRestaurant: true,
      };
    }

    case appActions.switchedRestaurant: {
      return {
        ...state,
        isSwitchingRestaurant: false,
      };
    }

    case appActions.loadingRestaurantList: {
      return {
        ...state,
        isLoadingRestaurantList: true,
        isLoadRestaurantListSuccess: false,
      };
    }

    case appActions.loadRestaurantListSuccess: {
      return {
        ...state,
        isLoadingRestaurantList: false,
        isLoadRestaurantListSuccess: true,
        restaurantList: payload,
      };
    }

    case appActions.loadRestaurantListFailed: {
      return {
        ...state,
        isLoadingRestaurantList: false,
        isLoadRestaurantListSuccess: false,
        restaurantList: [],
      };
    }

    case appActions.setIsGroup: {
      return {
        ...state,
        isGroupUser: payload,
      };
    }

    case appActions.clearAppSession: {
      return {
        ...state,
        isSwitchingRestaurant: false,
        isLoadingRestaurantList: false,
        isLoadRestaurantListSuccess: false,
        restaurantList: [],
      };
    }

    default:
      return state;
  }
};
