import { createCategory, queryAllCategories, updateCategory } from '../services/meal';
import { numberRangeToArray } from '../utils/array';

const BASE = 'CATEGORIES';

export const actions = {
  loadingCategories: `${BASE}_LOADING_CATEGORIES`,
  loadCategoriesSuccess: `${BASE}_LOAD_CATEGORIES_SUCCESS`,
  loadCategoriesFailed: `${BASE}_LOAD_CATEGORIES_FAILED`,
  refreshCategories: `${BASE}_REFRESH_CATEGORIES`,
};

const fetchCategoriesPageData = async ({ restaurant_id, page = 1, result = [] }) => {
  let response;
  try {
    response = await queryAllCategories(restaurant_id, page);
  } catch (e) {
    response = { success: false };
  }

  if (!response.success) return response;

  const {
    pages: { total_pages },
  } = response.meta;
  const categoryData = response.data || [];

  result.push(...categoryData);

  if (page === 1 && total_pages > 1) {
    await Promise.all(
      numberRangeToArray(2, total_pages + 1).map((pageIndex) => {
        return fetchCategoriesPageData({ restaurant_id, page: pageIndex, result });
      })
    );
  }

  return { success: true, data: result };
};

export const fetchCategories = (restaurant_id) => async (dispatch, getState) => {
  const { categories } = getState().category;
  if (categories.length) return;

  dispatch({
    type: actions.loadingCategories,
  });

  const response = await fetchCategoriesPageData({ restaurant_id });

  if (!response.success) {
    dispatch({
      type: actions.loadCategoriesFailed,
    });
    return;
  }

  dispatch({
    type: actions.loadCategoriesSuccess,
    payload: response.data,
  });
};

export const doCreateCategory = (payload, callback) => async (dispatch, getState) => {
  let response;

  try {
    response = await createCategory(payload);
  } catch (e) {
    response = { success: false };
  }

  if (!response.success) return;

  const { categories } = getState().category;
  const newCategories = [...categories, response.data];
  dispatch({
    type: actions.refreshCategories,
    payload: newCategories,
  });
  typeof callback === 'function' && callback();
};

export const doUpdateCategory = (payload, callback) => async (dispatch, getState) => {
  let response;

  try {
    response = await updateCategory(payload);
  } catch (e) {
    response = { success: false };
  }

  if (!response.success) return;

  const { categories } = getState().category;
  const newCategories = [...categories];
  const index = newCategories.findIndex((_) => _.id === payload.id);
  if (index < 0) return;
  newCategories[index] = response.data;
  dispatch({
    type: actions.refreshCategories,
    payload: newCategories,
  });
  typeof callback === 'function' && callback();
};
