import { GET, PUT, DELETE } from '../utils/request';
import { API_PREFIX } from '../consts';

export function queryDeviceList(device_type) {
  return GET(`${API_PREFIX}/v1/devices`, { device_type });
}

export function updateDevice({ id, ...data }) {
  return PUT(`${API_PREFIX}/v1/device/${id}`, data);
}

export function deleteDevice(deviceId) {
  return DELETE(`${API_PREFIX}/v1/device/${deviceId}`);
}

export function activateDevice(deviceId) {
  return PUT(`${API_PREFIX}/v1/device/${deviceId}/active`);
}

export function deactivateDevice(deviceId) {
  return PUT(`${API_PREFIX}/v1/device/${deviceId}/deactive`);
}
