import { EXIST_FLAG } from 'src/consts/rgflags';
import { actions as rgUserActions } from '../actions/rgUser';

const initState = {
  currentRgId: null,
  rgPermissions: null,
  owningRestaurants: EXIST_FLAG.checking,
};

export default (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case rgUserActions.cacheRgId: {
      return {
        ...state,
        currentRgId: payload,
      };
    }

    case rgUserActions.clearAllCachedData: {
      return {
        ...state,
        currentRgId: null,
        owningRestaurants: EXIST_FLAG.checking,
      };
    }

    case rgUserActions.cacheRgPermissions: {
      return {
        ...state,
        rgPermissions: payload,
      };
    }

    case rgUserActions.owningRestaurants: {
      return {
        ...state,
        owningRestaurants: payload,
      };
    }

    default:
      return state;
  }
};
