import { actions } from '../actions/tipsAllocationPolicy';

const initialState = {
  isLoadingSettings: false,
  isUpdatingSettings: false,
  isLoadingPolicies: false,

  policies: [],
  settings: null,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case actions.loadingSettings: {
      return {
        ...state,
        isLoadingSettings: true,
      };
    }

    case actions.loadSettingsSuccess: {
      return {
        ...state,
        isLoadingSettings: false,
        settings: payload,
      };
    }

    case actions.loadSettingsFailed: {
      return {
        ...state,
        isLoadingSettings: false,
        settings: null,
      };
    }

    case actions.updatingSettings: {
      return {
        ...state,
        isUpdatingSettings: true,
      };
    }

    case actions.updateSettingsSuccess: {
      return {
        ...state,
        isUpdatingSettings: false,
        settings: payload,
      };
    }

    case actions.updateSettingsFailed: {
      return {
        ...state,
        isUpdatingSettings: false,
      };
    }

    case actions.loadingPolicies: {
      return {
        ...state,
        isLoadingPolicies: true,
      };
    }

    case actions.loadPoliciesSuccess: {
      return {
        ...state,
        isLoadingPolicies: false,
        policies: payload || [],
      };
    }

    case actions.loadPoliciesFailed: {
      return {
        ...state,
        isLoadingPolicies: false,
        policies: [],
      };
    }

    case actions.updatePolicies: {
      return {
        ...state,
        policies: payload,
      };
    }

    default:
      return state;
  }
};
