import { GET, POST } from 'src/utils/request';
import { API_PREFIX } from 'src/consts';

export function queryReportPreference() {
  return GET(`${API_PREFIX}/v1/report_preference`);
}

export function updateReportPreference(data) {
  return POST(`${API_PREFIX}/v1/report_preference`, data);
}
