import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import thunk from 'redux-thunk';
import * as reducers from '../reducers';
import { intlSelector } from '../selector/app.js';
import { actions as userActions } from '../actions/user';

export const configureStore = (preloadedState) => {
  const middlewares = applyMiddleware(thunk.withExtraArgument({ intl: intlSelector }));
  const enhancers = composeEnhancers(middlewares);

  const appReducer = combineReducers({
    ...reducers,
  });

  const rootReducer = (state, action) => {
    if (action.type === userActions.resetReduxData) {
      const newAppState = {
        ...state.app,
        isSwitchingRestaurant: false,
        isLoadingRestaurantList: false,
        isLoadRestaurantListSuccess: false,
        restaurantList: [],
      };
      state = {
        app: newAppState, // reserve app data including locale etc.
      };
    }
    return appReducer(state, action);
  };

  const store = createStore(rootReducer, preloadedState, enhancers);

  return store;
};

const composeEnhancers = (...args) =>
  typeof window !== 'undefined' ? composeWithDevTools({})(...args) : compose(...args);
