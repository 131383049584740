import { getAllMenuCategorySettings, sortMenuCategories, updateCategoryDisplayColor } from '../services/meal';

const BASE = 'POS_MEAL_SETTINGS';

export const actions = {
  updateCategorySequenceRecords: `${BASE}_UPDATE_CATEGORY_SEQUENCE_RECORDS`,
  updateMealsSettings: `${BASE}_UPDATE_MEALS_SETTINGS`,
};

export const fetchAllMenuCategories =
  ({ restaurantId }) =>
  async (dispatch, getState) => {
    const {
      menuSettings: { menuCategoriesMapping },
    } = getState();
    if (Object.keys(menuCategoriesMapping).length) return;

    let response;

    try {
      response = await getAllMenuCategorySettings({ restaurantId });
    } catch (e) {
      response = { success: false };
    }

    if (!response.success) return;

    const recordsGroupByMenu = response.data.reduce((prev, current) => {
      const { menu_id, category_id } = current;
      if (!prev[menu_id]) prev[menu_id] = {};
      prev[menu_id][category_id] = current;
      return prev;
    }, {});

    dispatch({
      type: actions.updateCategorySequenceRecords,
      payload: recordsGroupByMenu,
    });
  };

export const sortCategories =
  ({ restaurantId, menuId, categoryData, errorCallback }) =>
  async (dispatch, getState) => {
    let response;

    const categoryIds = categoryData.map((_) => _.id);

    try {
      response = await sortMenuCategories({ restaurantId, menuId, categoryIds });
    } catch (e) {
      response = { success: false };
    }

    if (!response.success) {
      typeof errorCallback === 'function' && errorCallback();
      return;
    }

    const { menuCategoriesMapping } = getState().menuSettings;
    const newMenuCategoriesMapping = { ...menuCategoriesMapping };
    const records = newMenuCategoriesMapping[menuId];
    Object.values(records).forEach((record) => {
      record.sequence = categoryIds.indexOf(String(record.category_id)) + 1;
    });

    dispatch({
      type: actions.updateCategorySequenceRecords,
      payload: newMenuCategoriesMapping,
    });
  };

export const doUpdateCategoryColor = (payload, callback) => async (dispatch, getState) => {
  let response;

  try {
    response = await updateCategoryDisplayColor(payload);
  } catch (e) {
    response = { success: false };
  }

  if (!response.success) return;

  const { menuCategoriesMapping } = getState().menuSettings;
  const { category_id, menu_id, display_color } = payload;
  const newMenuCategoriesMapping = { ...menuCategoriesMapping };
  let records = newMenuCategoriesMapping[menu_id];

  if (!records) {
    records = newMenuCategoriesMapping[menu_id] = {};
  }

  const item = records[category_id];
  if (item) {
    item.display_color = display_color;
  } else {
    records[category_id] = {
      menu_id,
      category_id,
      display_color,
    };
  }

  dispatch({
    type: actions.updateCategorySequenceRecords,
    payload: newMenuCategoriesMapping,
  });
  typeof callback === 'function' && callback();
};
