import {
  getCanvas,
  StickerSize,
  CM_4X4_AcrylicSheetsSize,
  CM_250X250_AcrylicSheetsSize,
  CM_3X3_StickerSize,
  DEMOLOGO,
  CM3x3StickerQRCodeSize,
  CM4x4AcrylicSheetsQRCodeSize,
  CM250x250AcrylicSheetsQRCodeSize,
  StickerLogoSize,
  scale,
  StandQRCodeSize,
  Inch5x8StandQRCodeSize,
  StickerQRCodeSize,
  StandLogoSize,
  AcrylicSheetsLogoSize,
  LoyaltyQRCodeSize,
  LoyaltyQRWrapperSize,
  Loyalty5x8InchQRWrapperSize,
  Loyaltyy5x8InchQRCodeSize,
  LoyaltyLogoSize,
  BottomLogoSize,
  WifiImageSize,
  scanENImage,
  scanZHImage,
  fullENImage,
  fullZHImage,
  noCashBackENImage,
  noCashBackZHImage,
  noDiscountENImage,
  noDiscountZHImage,
  prepaidCardBonusENImage,
  prepaidCardBonusZHImage,
  prepaidCardNoBonusENImage,
  prepaidCardNoBonusZHImage,
  wifiImage,
} from './DrawQRCode';
import { fittingStringEllipsis, drawRoundRect, generateQRCode, ELLIPSIS_TEXT } from '../../utils/drawLoyaltyQRCode';
import {
  QR_CODE_SIZE_STAND_A5,
  QR_CODE_SIZE_STAND_5X8_INCH,
  QR_CODE_SIZE_STICKER_4X6_INCH,
  QR_CODE_SIZE_STICKER_325_INCH,
  QR_CODE_SIZE_ACRYLIC_SHEETS_4X4_INCH,
  QR_CODE_SIZE_ACRYLIC_SHEETS_250X250_INCH,
  NORMAL_QRCODE_TYPE,
  TAKEOUT_QRCODE_TYPE,
  MENUBROWSING_QRCODE_TYPE,
  DISTRIBUTION_MODES,
} from '../../consts';
import { qrCodeServiceDomain } from '../../consts/third_party';
import { getPrepaidCardMaxBonusRatio, loadImage } from '../../utils/utils';
import { FONT_FAMILY } from 'src/consts/fonts';

//4"* 6" sticker
const stickerBackgroundImage = `${process.env.ASSETS_PREFIX}/assets/qrcode/qr_bg_sticker.svg`;
const stickerBackgroundImageWithWifi = `${process.env.ASSETS_PREFIX}/assets/qrcode/qr_bg_sticker_wifi.svg`;
const enVersionstickerBackgroundImageWithWifi = `${process.env.ASSETS_PREFIX}/assets/qrcode/en_version_qr_bg_sticker_wifi.svg`;
const enVersionstickerBackgroundImage = `${process.env.ASSETS_PREFIX}/assets/qrcode/en_verion_qr_bg_sticker.svg`;
//4"* 6" sticker  for menu browsing
const menuBrowsing_stickerBackgroundImage = `${process.env.ASSETS_PREFIX}/assets/qrcode/browsing_menu/qr_bg_sticker.svg`;
const menuBrowsing_stickerBackgroundImageWithWifi = `${process.env.ASSETS_PREFIX}/assets/qrcode/browsing_menu/qr_bg_sticker_wifi.svg`;
const menuBrowsing_enVersionstickerBackgroundImageWithWifi = `${process.env.ASSETS_PREFIX}/assets/qrcode/browsing_menu/en_version_qr_bg_sticker_wifi.svg`;
const menuBrowsing_enVersionstickerBackgroundImage = `${process.env.ASSETS_PREFIX}/assets/qrcode/browsing_menu/en_verion_qr_bg_sticker.svg`;

//3x3
const cm3x3StickerBackgroundImage = `${process.env.ASSETS_PREFIX}/assets/qrcode/qr_bg_cm_3x3.svg`;
const cm3x3StickerBlackBackgroundImage = `${process.env.ASSETS_PREFIX}/assets/qrcode/qr_bg_black_cm_3x3.svg`;
const cm3x3StickerWhiteBackgroundImage = `${process.env.ASSETS_PREFIX}/assets/qrcode/qr_bg_white_cm_3x3.svg`;
const enVersioncm3x3StickerBackgroundImage = `${process.env.ASSETS_PREFIX}/assets/qrcode/en_version_qr_bg_cm_3x3.svg`;
const enVersioncm3x3StickerBlackBackgroundImage = `${process.env.ASSETS_PREFIX}/assets/qrcode/en_version_qr_bg_black_cm_3x3.svg`;
const enVersioncm3x3StickerWhiteBackgroundImage = `${process.env.ASSETS_PREFIX}/assets/qrcode/en_version_qr_bg_white_cm_3x3.svg`;

//3x3 browsing menu
const menuBrowsing_cm3x3StickerBackgroundImage = `${process.env.ASSETS_PREFIX}/assets/qrcode/browsing_menu/qr_bg_cm_3x3.svg`;
const menuBrowsing_cm3x3StickerBlackBackgroundImage = `${process.env.ASSETS_PREFIX}/assets/qrcode/browsing_menu/qr_bg_black_cm_3x3.svg`;
const menuBrowsing_cm3x3StickerWhiteBackgroundImage = `${process.env.ASSETS_PREFIX}/assets/qrcode/browsing_menu/qr_bg_white_cm_3x3.svg`;
const menuBrowsing_enVersioncm3x3StickerBackgroundImage = `${process.env.ASSETS_PREFIX}/assets/qrcode/browsing_menu/en_version_qr_bg_cm_3x3.svg`;
const menuBrowsing_enVersioncm3x3StickerBlackBackgroundImage = `${process.env.ASSETS_PREFIX}/assets/qrcode/browsing_menu/en_version_qr_bg_black_cm_3x3.svg`;
const menuBrowsing_enVersioncm3x3StickerWhiteBackgroundImage = `${process.env.ASSETS_PREFIX}/assets/qrcode/browsing_menu/en_version_qr_bg_white_cm_3x3.svg`;

//4x4
const cm4x4StickerWhiteBackgroundImage = `${process.env.ASSETS_PREFIX}/assets/qrcode/qr_bg_cm_4x4_zh.svg`;
const enVersioncm4x4StickerWhiteBackgroundImage = `${process.env.ASSETS_PREFIX}/assets/qrcode/qr_bg_cm_4x4_en.svg`;
//4x4  browsing menu
const menuBrowsing_cm4x4StickerWhiteBackgroundImage = `${process.env.ASSETS_PREFIX}/assets/qrcode/browsing_menu/qr_bg_cm_4x4_zh.svg`;
const menuBrowsing_enVersioncm4x4StickerWhiteBackgroundImage = `${process.env.ASSETS_PREFIX}/assets/qrcode/browsing_menu/qr_bg_cm_4x4_en.svg`;

//2.5 x 2.5 inch Acrylic
const cm250x250StickerWhiteBackgroundImage = `${process.env.ASSETS_PREFIX}/assets/qrcode/qr_bg_inch_250x250_acrylic_zh.svg`;
const enVersioncm250x250StickerWhiteBackgroundImage = `${process.env.ASSETS_PREFIX}/assets/qrcode/qr_bg_inch_250x250_acrylic_en.svg`;
//4x4  browsing menu
const menuBrowsing_cm250x250StickerWhiteBackgroundImage = `${process.env.ASSETS_PREFIX}/assets/qrcode/browsing_menu/qr_bg_inch_250x250_acrylic_zh.svg`;
const menuBrowsing_enVersioncm250x250StickerWhiteBackgroundImage = `${process.env.ASSETS_PREFIX}/assets/qrcode/browsing_menu/qr_bg_inch_250x250_acrylic_en.svg`;

export async function drawStickerQRCode(taskData) {
  const canvas = getCanvas(taskData.size);
  const ctx = canvas.getContext('2d');
  const images = [];
  if (taskData.size == QR_CODE_SIZE_STICKER_4X6_INCH) {
    await drawNonLoyaltyStickerQRCode({ ctx, taskData });
    images.push(canvas.toDataURL());

    let canvasEnVersionBG = getCanvas(taskData.size);
    let canvasEnVersionBG_ctx = canvasEnVersionBG.getContext('2d');
    await drawNonLoyaltyStickerQRCodeInEnglish({ ctx: canvasEnVersionBG_ctx, taskData });
    images.push(canvasEnVersionBG.toDataURL());
  } else if (taskData.size == QR_CODE_SIZE_ACRYLIC_SHEETS_4X4_INCH) {
    //4 亚克力版本
    let canvasWhitekkBG = getCanvas(taskData.size);
    let canvasWhitekkBG_ctx = canvasWhitekkBG.getContext('2d');
    await draw4x4CMAcrylicSheetsQRCode({ ctx: canvasWhitekkBG_ctx, taskData });
    images.push(canvasWhitekkBG.toDataURL());

    canvasWhitekkBG = getCanvas(taskData.size);
    canvasWhitekkBG_ctx = canvasWhitekkBG.getContext('2d');
    await draw4x4CMAcrylicSheetsQRCodeInEnglish({ ctx: canvasWhitekkBG_ctx, taskData });
    images.push(canvasWhitekkBG.toDataURL());
  } else if (taskData.size == QR_CODE_SIZE_ACRYLIC_SHEETS_250X250_INCH) {
    //2.5 亚克力版本, 样式是4inch亚克力版本，只是最终print到2.5 亚克力板上面
    // =====
    let canvasWhitekkBG = getCanvas(taskData.size);
    let canvasWhitekkBG_ctx = canvasWhitekkBG.getContext('2d');
    await draw4x4CMAcrylicSheetsQRCode({ ctx: canvasWhitekkBG_ctx, taskData });
    images.push(canvasWhitekkBG.toDataURL());

    canvasWhitekkBG = getCanvas(taskData.size);
    canvasWhitekkBG_ctx = canvasWhitekkBG.getContext('2d');
    await draw4x4CMAcrylicSheetsQRCodeInEnglish({ ctx: canvasWhitekkBG_ctx, taskData });
    images.push(canvasWhitekkBG.toDataURL());
  } else {
    await drawNonLoyalty3x3CMStickerQRCode({ ctx, taskData });
    images.push(canvas.toDataURL());

    let canvasBlackkBG = getCanvas(taskData.size);
    let canvasBlackkBG_ctx = canvasBlackkBG.getContext('2d');
    await drawNonLoyalty3x3CMBlackBKStickerQRCode({ ctx: canvasBlackkBG_ctx, taskData });
    images.push(canvasBlackkBG.toDataURL());

    let canvasWhitekkBG = getCanvas(taskData.size);
    let canvasWhitekkBG_ctx = canvasWhitekkBG.getContext('2d');
    await drawNonLoyalty3x3CMWhiteBKStickerQRCode({ ctx: canvasWhitekkBG_ctx, taskData });
    images.push(canvasWhitekkBG.toDataURL());

    //pure english version
    await drawNonLoyalty3x3CMStickerQRCodeInEnglish({ ctx, taskData });
    images.push(canvas.toDataURL());

    canvasBlackkBG = getCanvas(taskData.size);
    canvasBlackkBG_ctx = canvasBlackkBG.getContext('2d');
    await drawNonLoyalty3x3CMBlackBKStickerQRCodeInEnglish({ ctx: canvasBlackkBG_ctx, taskData });
    images.push(canvasBlackkBG.toDataURL());

    canvasWhitekkBG = getCanvas(taskData.size);
    canvasWhitekkBG_ctx = canvasWhitekkBG.getContext('2d');
    await drawNonLoyalty3x3CMWhiteBKStickerQRCodeInEnglish({ ctx: canvasWhitekkBG_ctx, taskData });
    images.push(canvasWhitekkBG.toDataURL());
  }

  return images;
}

async function drawNonLoyaltyStickerQRCode({ ctx, taskData }) {
  const { restaurantInfo, tableName, code, isQuickService, qrCodeType } = taskData;
  const { wifi_ssid, wifi_password, name, foreign_name, logo_url } = restaurantInfo;
  const { width, height } = StickerSize;
  let image;
  if (qrCodeType === MENUBROWSING_QRCODE_TYPE) {
    if (wifi_ssid) {
      image = await loadImage(menuBrowsing_stickerBackgroundImageWithWifi);
    } else {
      image = await loadImage(menuBrowsing_stickerBackgroundImage);
    }
  } else {
    if (wifi_ssid) {
      image = await loadImage(stickerBackgroundImageWithWifi);
    } else {
      image = await loadImage(stickerBackgroundImage);
    }
  }

  ctx.drawImage(image, 0, 0, width * scale, height * scale);

  const maxWidth = (width - 40) * scale;
  const fontSize = 24 * scale;
  const restaurantNameY = 310 * scale;

  if (!foreign_name) {
    const font = `normal ${fontSize}px/${fontSize}px ${FONT_FAMILY.PROXIMA_NOVA}`;
    const { text } = fittingStringEllipsis({
      context: ctx,
      str: name,
      maxWidth,
      font,
    });
    ctx.save();
    ctx.fillStyle = '#ffffff';
    ctx.font = font;
    ctx.textAlign = 'center';
    ctx.fillText(text, width * 0.5 * scale, restaurantNameY);
    ctx.restore();
  } else {
    const { text, contentWith: nameContentWidth } = fittingStringEllipsis({
      context: ctx,
      str: name,
      maxWidth,
      font: `normal ${fontSize}px/${fontSize}px ${FONT_FAMILY.PROXIMA_NOVA}`,
    });
    let nameText = text;
    let totalWidth = nameContentWidth;
    let foreignNameText;

    if (nameContentWidth <= maxWidth - fontSize) {
      const _maxWidth = maxWidth - nameContentWidth - fontSize;
      const { text, contentWith } = fittingStringEllipsis({
        context: ctx,
        str: foreign_name,
        maxWidth: _maxWidth,
        font: `normal ${fontSize}px/${fontSize}px ${FONT_FAMILY.PROXIMA_NOVA}`,
      });
      foreignNameText = text;

      if (foreignNameText === ELLIPSIS_TEXT) {
        if (!nameText.includes(ELLIPSIS_TEXT)) {
          nameText += ELLIPSIS_TEXT;
          ctx.font = `normal ${fontSize}px/${fontSize}px ${FONT_FAMILY.PROXIMA_NOVA}`;
          totalWidth += ctx.measureText(ELLIPSIS_TEXT).width;
        }

        foreignNameText = null;
      } else {
        totalWidth += contentWith;
      }
    }

    // draw restaurant name
    ctx.save();
    ctx.fillStyle = '#ffffff';
    ctx.font = `normal ${fontSize}px/${fontSize}px ${FONT_FAMILY.PROXIMA_NOVA}`;
    const left = (maxWidth - totalWidth) / 2;
    ctx.fillText(nameText, left, restaurantNameY);
    ctx.restore();

    if (foreignNameText) {
      ctx.save();
      ctx.fillStyle = '#ffffff';
      ctx.font = `normal ${fontSize}px/${fontSize}px ${FONT_FAMILY.PROXIMA_NOVA}`;
      ctx.fillText(foreignNameText, left + nameContentWidth + fontSize, restaurantNameY);
      ctx.restore();
    }
  }

  if (qrCodeType === NORMAL_QRCODE_TYPE && !isQuickService) {
    ctx.save();
    ctx.fillStyle = '#ffffff';
    ctx.font = `bold ${52 * scale}px/${52 * scale}px ${FONT_FAMILY.PROXIMA_NOVA}`;
    ctx.textAlign = 'center';
    ctx.fillText(tableName, width * 0.5 * scale, 410 * scale);
    ctx.restore();
  }

  // draw qr code
  const qrCodeLink = `${qrCodeServiceDomain}?code=${code}`;
  const qrCodeImage = await generateQRCode(qrCodeLink, StickerQRCodeSize);
  let y = 440 * scale;

  if (qrCodeImage) {
    const x = (width * scale - StickerQRCodeSize) / 2;
    ctx.drawImage(qrCodeImage, x, y, StickerQRCodeSize, StickerQRCodeSize);
  }

  if (logo_url) {
    const logoImage = await loadImage(logo_url);
    if (logoImage) {
      const x = (width * scale - StickerLogoSize) / 2;
      y += (StickerQRCodeSize - StickerLogoSize) / 2;
      ctx.drawImage(logoImage, x, y, StickerLogoSize, StickerLogoSize);
    }
  }

  if (!wifi_ssid) return;

  const offsetY = (height - 42) * scale;

  ctx.save();
  ctx.font = `normal ${18 * scale}px/${22 * scale}px ${FONT_FAMILY.PROXIMA_NOVA}`;
  ctx.fillStyle = '#1D1B2E';
  ctx.fillText(`Wi-Fi: ${wifi_ssid}`, 86 * scale, offsetY);
  ctx.fillText(`Password: ${wifi_password || ''}`, 86 * scale, offsetY + 22 * scale);
  ctx.restore();
}

async function drawNonLoyaltyStickerQRCodeInEnglish({ ctx, taskData }) {
  const { restaurantInfo, tableName, code, isQuickService, qrCodeType } = taskData;
  const { wifi_ssid, wifi_password, name, logo_url } = restaurantInfo;
  const { width, height } = StickerSize;
  let image;
  if (qrCodeType === MENUBROWSING_QRCODE_TYPE) {
    if (wifi_ssid) {
      image = await loadImage(menuBrowsing_enVersionstickerBackgroundImageWithWifi);
    } else {
      image = await loadImage(menuBrowsing_enVersionstickerBackgroundImage);
    }
  } else {
    if (wifi_ssid) {
      image = await loadImage(enVersionstickerBackgroundImageWithWifi);
    } else {
      image = await loadImage(enVersionstickerBackgroundImage);
    }
  }

  ctx.drawImage(image, 0, 0, width * scale, height * scale);

  const maxWidth = (width - 40) * scale;
  const fontSize = 24 * scale;
  const restaurantNameY = 310 * scale;
  const font = `normal ${fontSize}px/${fontSize}px ${FONT_FAMILY.PROXIMA_NOVA}`;

  const { text } = fittingStringEllipsis({
    context: ctx,
    str: name,
    maxWidth,
    font,
  });
  ctx.save();
  ctx.fillStyle = '#ffffff';
  ctx.font = font;
  ctx.textAlign = 'center';
  ctx.fillText(text, width * 0.5 * scale, restaurantNameY);
  ctx.restore();

  if (qrCodeType === NORMAL_QRCODE_TYPE && !isQuickService) {
    ctx.save();
    ctx.fillStyle = '#ffffff';
    ctx.font = `bold ${52 * scale}px/${52 * scale}px ${FONT_FAMILY.PROXIMA_NOVA}`;
    ctx.textAlign = 'center';
    ctx.fillText(tableName, width * 0.5 * scale, 410 * scale);
    ctx.restore();
  }

  // draw qr code
  const qrCodeLink = `${qrCodeServiceDomain}?code=${code}`;
  const qrCodeImage = await generateQRCode(qrCodeLink, StickerQRCodeSize);
  let y = 440 * scale;

  if (qrCodeImage) {
    const x = (width * scale - StickerQRCodeSize) / 2;
    ctx.drawImage(qrCodeImage, x, y, StickerQRCodeSize, StickerQRCodeSize);
  }

  if (logo_url) {
    const logoImage = await loadImage(logo_url);
    if (logoImage) {
      const x = (width * scale - StickerLogoSize) / 2;
      y += (StickerQRCodeSize - StickerLogoSize) / 2;
      ctx.drawImage(logoImage, x, y, StickerLogoSize, StickerLogoSize);
    }
  }

  if (!wifi_ssid) return;

  const offsetY = (height - 42) * scale;

  ctx.save();
  ctx.font = `normal ${18 * scale}px/${22 * scale}px ${FONT_FAMILY.PROXIMA_NOVA}`;
  ctx.fillStyle = '#1D1B2E';
  ctx.fillText(`Wi-Fi: ${wifi_ssid}`, 86 * scale, offsetY);
  ctx.fillText(`Password: ${wifi_password || ''}`, 86 * scale, offsetY + 22 * scale);
  ctx.restore();
}

async function drawNonLoyalty3x3CMStickerQRCode({ ctx, taskData }) {
  const { restaurantInfo, tableName, code, isQuickService, qrCodeType } = taskData;
  const { wifi_ssid, wifi_password, name, foreign_name, logo_url } = restaurantInfo;
  const { width, height } = CM_3X3_StickerSize;
  let image = await loadImage(cm3x3StickerBackgroundImage);
  if (qrCodeType === MENUBROWSING_QRCODE_TYPE) {
    image = await loadImage(menuBrowsing_cm3x3StickerBackgroundImage);
  }

  ctx.drawImage(image, 0, 0, width * scale, height * scale);

  if (qrCodeType === NORMAL_QRCODE_TYPE && !isQuickService) {
    ctx.save();
    ctx.fillStyle = '#ffffff';
    ctx.font = `bold ${72 * scale}px/${72 * 1.1 * scale}px ${FONT_FAMILY.PROXIMA_NOVA}`;
    ctx.textAlign = 'center';
    ctx.fillText(tableName, width * 0.5 * scale, 350 * scale);
    ctx.restore();
  }

  // draw qr code
  const qrCodeLink = `${qrCodeServiceDomain}?code=${code}`;
  const qrCodeImage = await generateQRCode(qrCodeLink, CM3x3StickerQRCodeSize);
  let y = 415 * scale;

  if (qrCodeImage) {
    const x = (width * scale - CM3x3StickerQRCodeSize) / 2 + 20;
    ctx.drawImage(qrCodeImage, x, y, CM3x3StickerQRCodeSize, CM3x3StickerQRCodeSize);
  }

  if (logo_url) {
    const logoImage = await loadImage(logo_url);
    if (logoImage) {
      const x = (width * scale - StickerLogoSize) / 2 + 20;
      y += (CM3x3StickerQRCodeSize - StickerLogoSize) / 2;
      ctx.drawImage(logoImage, x, y, StickerLogoSize, StickerLogoSize);
    }
  }
}

async function drawNonLoyalty3x3CMStickerQRCodeInEnglish({ ctx, taskData }) {
  const { restaurantInfo, tableName, code, isQuickService, qrCodeType } = taskData;
  const { wifi_ssid, wifi_password, name, foreign_name, logo_url } = restaurantInfo;
  const { width, height } = CM_3X3_StickerSize;

  let image = await loadImage(enVersioncm3x3StickerBackgroundImage);
  if (qrCodeType === MENUBROWSING_QRCODE_TYPE) {
    image = await loadImage(menuBrowsing_enVersioncm3x3StickerBackgroundImage);
  }

  ctx.drawImage(image, 0, 0, width * scale, height * scale);

  if (qrCodeType === NORMAL_QRCODE_TYPE && !isQuickService) {
    ctx.save();
    ctx.fillStyle = '#ffffff';
    ctx.font = `bold ${72 * scale}px/${72 * 1.1 * scale}px ${FONT_FAMILY.PROXIMA_NOVA}`;
    ctx.textAlign = 'center';
    ctx.fillText(tableName, width * 0.5 * scale, 350 * scale);
    ctx.restore();
  }

  // draw qr code
  const qrCodeLink = `${qrCodeServiceDomain}?code=${code}`;
  const qrCodeImage = await generateQRCode(qrCodeLink, CM3x3StickerQRCodeSize);
  let y = 415 * scale;

  if (qrCodeImage) {
    const x = (width * scale - CM3x3StickerQRCodeSize) / 2 + 20;
    ctx.drawImage(qrCodeImage, x, y, CM3x3StickerQRCodeSize, CM3x3StickerQRCodeSize);
  }

  if (logo_url) {
    const logoImage = await loadImage(logo_url);
    if (logoImage) {
      const x = (width * scale - StickerLogoSize) / 2 + 20;
      y += (CM3x3StickerQRCodeSize - StickerLogoSize) / 2;
      ctx.drawImage(logoImage, x, y, StickerLogoSize, StickerLogoSize);
    }
  }
}

async function drawNonLoyalty3x3CMBlackBKStickerQRCode({ ctx, taskData }) {
  const { restaurantInfo, tableName, code, isQuickService, qrCodeType } = taskData;
  const { logo_url } = restaurantInfo;
  const { width, height } = CM_3X3_StickerSize;

  let image = await loadImage(cm3x3StickerBlackBackgroundImage);
  if (qrCodeType === MENUBROWSING_QRCODE_TYPE) {
    image = await loadImage(menuBrowsing_cm3x3StickerBlackBackgroundImage);
  }

  ctx.drawImage(image, 0, 0, width * scale, height * scale);

  if (qrCodeType === NORMAL_QRCODE_TYPE && !isQuickService) {
    ctx.save();
    ctx.fillStyle = '#ffffff';
    ctx.font = `bold ${72 * scale}px/${72 * 1.1 * scale}px ${FONT_FAMILY.PROXIMA_NOVA}`;
    ctx.textAlign = 'center';
    ctx.fillText(tableName, width * 0.5 * scale, 350 * scale);
    ctx.restore();
  }

  // draw qr code
  const qrCodeLink = `${qrCodeServiceDomain}?code=${code}`;
  const qrCodeImage = await generateQRCode(qrCodeLink, CM3x3StickerQRCodeSize);
  let y = 415 * scale;

  if (qrCodeImage) {
    const x = (width * scale - CM3x3StickerQRCodeSize) / 2 + 20;
    ctx.drawImage(qrCodeImage, x, y, CM3x3StickerQRCodeSize, CM3x3StickerQRCodeSize);
  }

  if (logo_url) {
    const logoImage = await loadImage(logo_url);
    if (logoImage) {
      const x = (width * scale - StickerLogoSize) / 2 + 20;
      y += (CM3x3StickerQRCodeSize - StickerLogoSize) / 2;
      ctx.drawImage(logoImage, x, y, StickerLogoSize, StickerLogoSize);
    }
  }
}

async function drawNonLoyalty3x3CMBlackBKStickerQRCodeInEnglish({ ctx, taskData }) {
  const { restaurantInfo, tableName, code, isQuickService, qrCodeType } = taskData;
  const { logo_url } = restaurantInfo;
  const { width, height } = CM_3X3_StickerSize;

  let image = await loadImage(enVersioncm3x3StickerBlackBackgroundImage);
  if (qrCodeType === MENUBROWSING_QRCODE_TYPE) {
    image = await loadImage(menuBrowsing_enVersioncm3x3StickerBlackBackgroundImage);
  }

  ctx.drawImage(image, 0, 0, width * scale, height * scale);

  if (qrCodeType === NORMAL_QRCODE_TYPE && !isQuickService) {
    ctx.save();
    ctx.fillStyle = '#ffffff';
    ctx.font = `bold ${72 * scale}px/${72 * 1.1 * scale}px ${FONT_FAMILY.PROXIMA_NOVA}`;
    ctx.textAlign = 'center';
    ctx.fillText(tableName, width * 0.5 * scale, 350 * scale);
    ctx.restore();
  }

  // draw qr code
  const qrCodeLink = `${qrCodeServiceDomain}?code=${code}`;
  const qrCodeImage = await generateQRCode(qrCodeLink, CM3x3StickerQRCodeSize);
  let y = 415 * scale;

  if (qrCodeImage) {
    const x = (width * scale - CM3x3StickerQRCodeSize) / 2 + 20;
    ctx.drawImage(qrCodeImage, x, y, CM3x3StickerQRCodeSize, CM3x3StickerQRCodeSize);
  }

  if (logo_url) {
    const logoImage = await loadImage(logo_url);
    if (logoImage) {
      const x = (width * scale - StickerLogoSize) / 2 + 20;
      y += (CM3x3StickerQRCodeSize - StickerLogoSize) / 2;
      ctx.drawImage(logoImage, x, y, StickerLogoSize, StickerLogoSize);
    }
  }
}

async function drawNonLoyalty3x3CMWhiteBKStickerQRCode({ ctx, taskData }) {
  const { restaurantInfo, tableName, code, isQuickService, qrCodeType } = taskData;
  const { logo_url } = restaurantInfo;
  const { width, height } = CM_3X3_StickerSize;

  let image = await loadImage(cm3x3StickerWhiteBackgroundImage);
  if (qrCodeType === MENUBROWSING_QRCODE_TYPE) {
    image = await loadImage(menuBrowsing_cm3x3StickerWhiteBackgroundImage);
  }

  ctx.drawImage(image, 0, 0, width * scale, height * scale);

  if (qrCodeType === NORMAL_QRCODE_TYPE && !isQuickService) {
    ctx.save();
    ctx.fillStyle = '#E00051';
    ctx.font = `bold ${72 * scale}px/${72 * 1.1 * scale}px ${FONT_FAMILY.PROXIMA_NOVA}`;
    ctx.textAlign = 'center';
    ctx.fillText(tableName, width * 0.5 * scale, 350 * scale);
    ctx.restore();
  }

  // draw qr code
  const qrCodeLink = `${qrCodeServiceDomain}?code=${code}`;
  const qrCodeImage = await generateQRCode(qrCodeLink, CM3x3StickerQRCodeSize);
  let y = 415 * scale;

  if (qrCodeImage) {
    const x = (width * scale - CM3x3StickerQRCodeSize) / 2 + 20;
    ctx.drawImage(qrCodeImage, x, y, CM3x3StickerQRCodeSize, CM3x3StickerQRCodeSize);
  }

  if (logo_url) {
    const logoImage = await loadImage(logo_url);
    if (logoImage) {
      const x = (width * scale - StickerLogoSize) / 2 + 20;
      y += (CM3x3StickerQRCodeSize - StickerLogoSize) / 2;
      ctx.drawImage(logoImage, x, y, StickerLogoSize, StickerLogoSize);
    }
  }
}

async function drawNonLoyalty3x3CMWhiteBKStickerQRCodeInEnglish({ ctx, taskData }) {
  const { restaurantInfo, tableName, code, isQuickService, qrCodeType } = taskData;
  const { logo_url } = restaurantInfo;
  const { width, height } = CM_3X3_StickerSize;

  let image = await loadImage(enVersioncm3x3StickerWhiteBackgroundImage);
  if (qrCodeType === MENUBROWSING_QRCODE_TYPE) {
    image = await loadImage(menuBrowsing_enVersioncm3x3StickerWhiteBackgroundImage);
  }

  ctx.drawImage(image, 0, 0, width * scale, height * scale);

  if (qrCodeType === NORMAL_QRCODE_TYPE && !isQuickService) {
    ctx.save();
    ctx.fillStyle = '#E00051';
    ctx.font = `bold ${72 * scale}px/${72 * 1.1 * scale}px ${FONT_FAMILY.PROXIMA_NOVA}`;
    ctx.textAlign = 'center';
    ctx.fillText(tableName, width * 0.5 * scale, 350 * scale);
    ctx.restore();
  }

  // draw qr code
  const qrCodeLink = `${qrCodeServiceDomain}?code=${code}`;
  const qrCodeImage = await generateQRCode(qrCodeLink, CM3x3StickerQRCodeSize);
  let y = 415 * scale;

  if (qrCodeImage) {
    const x = (width * scale - CM3x3StickerQRCodeSize) / 2 + 20;
    ctx.drawImage(qrCodeImage, x, y, CM3x3StickerQRCodeSize, CM3x3StickerQRCodeSize);
  }

  if (logo_url) {
    const logoImage = await loadImage(logo_url);
    if (logoImage) {
      const x = (width * scale - StickerLogoSize) / 2 + 20;
      y += (CM3x3StickerQRCodeSize - StickerLogoSize) / 2;
      ctx.drawImage(logoImage, x, y, StickerLogoSize, StickerLogoSize);
    }
  }
}

async function draw4x4CMAcrylicSheetsQRCode({ ctx, taskData }) {
  const { restaurantInfo, tableName, code, isQuickService, qrCodeType } = taskData;
  const { logo_url } = restaurantInfo;
  const { width, height } = CM_4X4_AcrylicSheetsSize;

  let image = await loadImage(cm4x4StickerWhiteBackgroundImage);
  if (qrCodeType === MENUBROWSING_QRCODE_TYPE) {
    image = await loadImage(menuBrowsing_cm4x4StickerWhiteBackgroundImage);
  }

  ctx.drawImage(image, 0, 0, width * scale, height * scale);
  if (qrCodeType === NORMAL_QRCODE_TYPE && !isQuickService) {
    ctx.save();
    ctx.fillStyle = '#000000';
    ctx.font = `bold ${74 * scale}px/${74 * 1.1 * scale}px ${FONT_FAMILY.PROXIMA_NOVA}`;
    ctx.textAlign = 'center';
    ctx.fillText(tableName, width * 0.5 * scale, 380 * scale);
    ctx.restore();
  }

  // draw qr code
  const qrCodeLink = `${qrCodeServiceDomain}?code=${code}`;
  const qrCodeImage = await generateQRCode(qrCodeLink, CM4x4AcrylicSheetsQRCodeSize);
  let y = 428 * scale;

  if (qrCodeImage) {
    const x = (width * scale - CM4x4AcrylicSheetsQRCodeSize) / 2;
    ctx.drawImage(qrCodeImage, x, y, CM4x4AcrylicSheetsQRCodeSize, CM4x4AcrylicSheetsQRCodeSize);
  }

  if (logo_url) {
    const logoImage = await loadImage(logo_url);
    if (logoImage) {
      const x = (width * scale - AcrylicSheetsLogoSize) / 2;
      y += (CM4x4AcrylicSheetsQRCodeSize - AcrylicSheetsLogoSize) / 2;
      ctx.drawImage(logoImage, x, y, AcrylicSheetsLogoSize, AcrylicSheetsLogoSize);
    }
  }
}

async function draw4x4CMAcrylicSheetsQRCodeInEnglish({ ctx, taskData }) {
  const { restaurantInfo, tableName, code, isQuickService, qrCodeType } = taskData;
  const { logo_url } = restaurantInfo;
  const { width, height } = CM_4X4_AcrylicSheetsSize;

  let image = await loadImage(enVersioncm4x4StickerWhiteBackgroundImage);
  if (qrCodeType === MENUBROWSING_QRCODE_TYPE) {
    image = await loadImage(menuBrowsing_enVersioncm4x4StickerWhiteBackgroundImage);
  }

  ctx.drawImage(image, 0, 0, width * scale, height * scale);

  if (qrCodeType === NORMAL_QRCODE_TYPE && !isQuickService) {
    ctx.save();
    ctx.fillStyle = '#000000';
    ctx.font = `bold ${74 * scale}px/${74 * 1.1 * scale}px ${FONT_FAMILY.PROXIMA_NOVA}`;
    ctx.textAlign = 'center';
    ctx.fillText(tableName, width * 0.5 * scale, 350 * scale);
    ctx.restore();
  }

  // draw qr code
  const qrCodeLink = `${qrCodeServiceDomain}?code=${code}`;
  const qrCodeImage = await generateQRCode(qrCodeLink, CM4x4AcrylicSheetsQRCodeSize);
  let y = 405 * scale;

  if (qrCodeImage) {
    const x = (width * scale - CM4x4AcrylicSheetsQRCodeSize) / 2;
    ctx.drawImage(qrCodeImage, x, y, CM4x4AcrylicSheetsQRCodeSize, CM4x4AcrylicSheetsQRCodeSize);
  }

  if (logo_url) {
    const logoImage = await loadImage(logo_url);
    if (logoImage) {
      const x = (width * scale - AcrylicSheetsLogoSize) / 2;
      y += (CM4x4AcrylicSheetsQRCodeSize - AcrylicSheetsLogoSize) / 2;
      ctx.drawImage(logoImage, x, y, AcrylicSheetsLogoSize, AcrylicSheetsLogoSize);
    }
  }
}

//2.5英寸亚克力
async function draw250x250InchAcrylicSheetsQRCode({ ctx, taskData }) {
  const { restaurantInfo, tableName, code, isQuickService, qrCodeType } = taskData;
  const { logo_url } = restaurantInfo;
  const { width, height } = CM_250X250_AcrylicSheetsSize;

  let image = await loadImage(cm250x250StickerWhiteBackgroundImage);
  if (qrCodeType === MENUBROWSING_QRCODE_TYPE) {
    image = await loadImage(menuBrowsing_cm250x250StickerWhiteBackgroundImage);
  }

  ctx.drawImage(image, 0, 0, width * scale, height * scale);
  if (qrCodeType === NORMAL_QRCODE_TYPE && !isQuickService) {
    ctx.save();
    ctx.fillStyle = '#E00051';
    ctx.font = `bold ${170 * scale}px/${170 * 1.1 * scale}px ${FONT_FAMILY.PROXIMA_NOVA}`;
    ctx.textAlign = 'center';
    ctx.fillText(tableName, width * 0.5 * scale, 170 * scale);
    ctx.restore();
  }
  // else if (qrCodeType === TAKEOUT_QRCODE_TYPE) {
  //   ctx.save();
  //   ctx.fillStyle = '#E00051';
  //   ctx.font = `bold ${150 * scale}px/${150 * 1.1 * scale}px ${FONT_FAMILY.PROXIMA_NOVA}`;
  //   ctx.textAlign = 'center';
  //   ctx.fillText('打包', width * 0.5 * scale, 170 * scale);
  //   ctx.restore();
  // }

  // draw qr code
  const qrCodeLink = `${qrCodeServiceDomain}?code=${code}`;
  const qrCodeImage = await generateQRCode(qrCodeLink, CM250x250AcrylicSheetsQRCodeSize);
  let y = 262 * scale;

  if (qrCodeImage) {
    const x = (width * scale - CM250x250AcrylicSheetsQRCodeSize) / 2;
    ctx.drawImage(qrCodeImage, x, y, CM250x250AcrylicSheetsQRCodeSize, CM250x250AcrylicSheetsQRCodeSize);
  }

  if (logo_url) {
    const logoImage = await loadImage(logo_url);
    if (logoImage) {
      const x = (width * scale - AcrylicSheetsLogoSize) / 2;
      y += (CM250x250AcrylicSheetsQRCodeSize - AcrylicSheetsLogoSize) / 2;
      ctx.drawImage(logoImage, x, y, AcrylicSheetsLogoSize, AcrylicSheetsLogoSize);
    }
  }
}

async function draw250x250InchAcrylicSheetsQRCodeInEnglish({ ctx, taskData }) {
  const { restaurantInfo, tableName, code, isQuickService, qrCodeType } = taskData;
  const { logo_url } = restaurantInfo;
  const { width, height } = CM_250X250_AcrylicSheetsSize;

  let image = await loadImage(enVersioncm250x250StickerWhiteBackgroundImage);
  if (qrCodeType === MENUBROWSING_QRCODE_TYPE) {
    image = await loadImage(menuBrowsing_enVersioncm250x250StickerWhiteBackgroundImage);
  }

  ctx.drawImage(image, 0, 0, width * scale, height * scale);

  if (qrCodeType === NORMAL_QRCODE_TYPE && !isQuickService) {
    ctx.save();
    ctx.fillStyle = '#E00051';
    ctx.font = `bold ${170 * scale}px/${170 * 1.1 * scale}px ${FONT_FAMILY.PROXIMA_NOVA}`;
    ctx.textAlign = 'center';
    ctx.fillText(tableName, width * 0.5 * scale, 170 * scale);
    ctx.restore();
  }
  // else if (qrCodeType === TAKEOUT_QRCODE_TYPE) {
  //   ctx.save();
  //   ctx.fillStyle = '#E00051';
  //   ctx.font = `bold ${150 * scale}px/${150 * 1.1 * scale}px ${FONT_FAMILY.PROXIMA_NOVA}`;
  //   ctx.textAlign = 'center';
  //   ctx.fillText('Take Out', width * 0.5 * scale, 170 * scale);
  //   ctx.restore();
  // }

  // draw qr code
  const qrCodeLink = `${qrCodeServiceDomain}?code=${code}`;
  const qrCodeImage = await generateQRCode(qrCodeLink, CM250x250AcrylicSheetsQRCodeSize);
  let y = 262 * scale;

  if (qrCodeImage) {
    const x = (width * scale - CM250x250AcrylicSheetsQRCodeSize) / 2;
    ctx.drawImage(qrCodeImage, x, y, CM250x250AcrylicSheetsQRCodeSize, CM250x250AcrylicSheetsQRCodeSize);
  }

  if (logo_url) {
    const logoImage = await loadImage(logo_url);
    if (logoImage) {
      const x = (width * scale - AcrylicSheetsLogoSize) / 2;
      y += (CM250x250AcrylicSheetsQRCodeSize - AcrylicSheetsLogoSize) / 2;
      ctx.drawImage(logoImage, x, y, AcrylicSheetsLogoSize, AcrylicSheetsLogoSize);
    }
  }
}
