import { actions as RESERVATIONActions } from '../actions/reservation';

const initialState = {
  restaurantProfile: undefined,
  tableSettingList: undefined,
  openTimeRegularList: undefined,
  openTimeSpecList: undefined,
  closeTimeList: undefined,
  preferenceIsLoading: false,
};

const reservationReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case RESERVATIONActions.loadingPreference: {
      return {
        ...state,
        preferenceIsLoading: true,
      };
    }
    case RESERVATIONActions.loadPreference: {
      return {
        ...state,
        ...payload,
        preferenceIsLoading: false,
      };
    }
    case RESERVATIONActions.updatePreference: {
      return {
        ...state,
        restaurantProfile: {
          ...payload,
        },
      };
    }
    case RESERVATIONActions.updateCloseTime: {
      return {
        ...state,
        closeTimeList: [...payload],
      };
    }
    case RESERVATIONActions.updateTableSetting: {
      return {
        ...state,
        tableSettingList: [...payload],
      };
    }
    case RESERVATIONActions.updateOpenTimeRegularSetting: {
      return {
        ...state,
        openTimeRegularList: [...payload],
      };
    }
    case RESERVATIONActions.updateOpenTimeSpecialSetting: {
      return {
        ...state,
        openTimeSpecList: [...payload],
      };
    }

    default:
      return state;
  }
};

export default reservationReducer;
