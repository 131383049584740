import { GET, POST } from '../utils/request';
import { API_PREFIX } from '../consts';

export function queryDishItemPrinterSetting({ show_meal_prototypes = 1, show_rewards = 1 } = {}) {
  return GET(`${API_PREFIX}/v3/dishitem/printers`, { show_meal_prototypes, show_rewards });
}

export function updateDishItemPrinterSetting(changedPrinterSettings) {
  return POST(`${API_PREFIX}/v3/dishitem/printers`, changedPrinterSettings);
}

export function queryDishCustomizationPrinterSetting() {
  return GET(`${API_PREFIX}/v3/printer/options`);
}

export function updateDishCustomizationPrinterSetting(changedPrinterSettings) {
  return POST(`${API_PREFIX}/v3/option/printers`, changedPrinterSettings);
}
