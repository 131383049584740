import { actions as appConfigActions } from '../actions/appConfig';

const initialState = {
  isLoadingAppConfigs: false,
  isLoadAppConfigsSuccess: false,
  isLoadAppConfigsFailed: false,

  isCreatingAppConfig: false,
  isCreateAppConfigSuccess: false,
  isCreateAppConfigFailed: false,

  isUpdatingAppConfig: false,
  isUpdateAppConfigSuccess: false,
  isUpdateAppConfigFailed: false,

  isDeletingAppConfig: false,
  isDeleteAppConfigSuccess: false,
  isDeleteAppConfigFailed: false,

  appConfigs: [],
  deletingId: undefined,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case appConfigActions.loadingAppConfigs: {
      return {
        ...state,
        isLoadingAppConfigs: true,
        isLoadAppConfigsSuccess: false,
        isLoadAppConfigsFailed: false,
      };
    }

    case appConfigActions.loadAppConfigsSuccess: {
      return {
        ...state,
        isLoadingAppConfigs: false,
        isLoadAppConfigsSuccess: true,
        isLoadAppConfigsFailed: false,
        ...payload,
      };
    }

    case appConfigActions.loadAppConfigsFailed: {
      return {
        ...state,
        isLoadingAppConfigs: false,
        isLoadAppConfigsSuccess: false,
        isLoadAppConfigsFailed: true,
      };
    }

    case appConfigActions.creatingAppConfig: {
      return {
        ...state,
        isCreatingAppConfig: true,
        isCreateAppConfigSuccess: false,
        isCreateAppConfigFailed: false,
      };
    }

    case appConfigActions.createAppConfigSuccess: {
      return {
        ...state,
        isCreatingAppConfig: false,
        isCreateAppConfigSuccess: true,
        isCreateAppConfigFailed: false,
      };
    }

    case appConfigActions.createAppConfigFailed: {
      return {
        ...state,
        isCreatingAppConfig: false,
        isCreateAppConfigSuccess: false,
        isCreateAppConfigFailed: true,
      };
    }

    case appConfigActions.updatingAppConfig: {
      return {
        ...state,
        isUpdatingAppConfig: true,
        isUpdateAppConfigSuccess: false,
        isUpdateAppConfigFailed: false,
      };
    }

    case appConfigActions.updateAppConfigSuccess: {
      return {
        ...state,
        isUpdatingAppConfig: false,
        isUpdateAppConfigSuccess: true,
        isUpdateAppConfigFailed: false,
      };
    }

    case appConfigActions.updateAppConfigFailed: {
      return {
        ...state,
        isUpdatingAppConfig: false,
        isUpdateAppConfigSuccess: false,
        isUpdateAppConfigFailed: true,
      };
    }

    case appConfigActions.deletingAppConfig: {
      return {
        ...state,
        isDeletingAppConfig: true,
        isDeleteAppConfigSuccess: false,
        isDeleteAppConfigFailed: false,
        ...payload,
      };
    }

    case appConfigActions.deleteAppConfigSuccess: {
      return {
        ...state,
        isDeletingAppConfig: false,
        isDeleteAppConfigSuccess: true,
        isDeleteAppConfigFailed: false,
      };
    }

    case appConfigActions.deleteAppConfigFailed: {
      return {
        ...state,
        isDeletingAppConfig: false,
        isDeleteAppConfigSuccess: false,
        isDeleteAppConfigFailed: true,
      };
    }

    default:
      return state;
  }
};
