import { actions as submitGetADemoActions } from '../actions/demoRequest';

const initialState = {
  oid: '00D3t000002RE9U',
  lead_source: 'Inbound Website (POS)',
  debugEmail: 'joel.stump@chowbus.com',
  retURL: 'http://pos.chowbus.com',
  first_name: undefined,
  last_name: undefined,
  phone: undefined,
  email: undefined,
  company: undefined,
  city: undefined,
  state: undefined,
  role: undefined,
  optionForHowManyRestaurants: undefined,
  optionsForWhatKindRestaurants: undefined,
  optionForEstimatedAverageRevenue: undefined,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case submitGetADemoActions.submitStep1: {
      return {
        ...state,
        ...payload,
      };
    }
    case submitGetADemoActions.submitStep2: {
      return {
        ...state,
        ...payload,
      };
    }
    case submitGetADemoActions.reset: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};
