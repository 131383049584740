import { actions } from '../actions/alternativePayments';

const initialState = {
  isLoadingAlternativePayments: false,
  alternativePayments: [],
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case actions.loadingAlternativePayments: {
      return {
        ...state,
        isLoadingAlternativePayments: true,
      };
    }

    case actions.loadedAlternativePayments: {
      return {
        ...state,
        isLoadingAlternativePayments: false,
        alternativePayments: payload,
      };
    }

    default:
      return state;
  }
};
