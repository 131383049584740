import { actions as feeActions } from '../actions/fee';

const initialState = {
  isLoadingFees: false,
  isLoadFeesSuccess: false,
  isLoadFeesFailed: false,

  fees: [],
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case feeActions.loadingFees: {
      return {
        ...state,
        isLoadingFees: true,
        isLoadFeesSuccess: false,
        isLoadFeesFailed: false,
      };
    }

    case feeActions.loadFeesSuccess: {
      return {
        ...state,
        isLoadingFees: false,
        isLoadFeesSuccess: true,
        isLoadFeesFailed: false,
        ...payload,
      };
    }

    case feeActions.loadFeesFailed: {
      return {
        ...state,
        isLoadingFees: false,
        isLoadFeesSuccess: false,
        isLoadFeesFailed: true,
      };
    }

    default: {
      return state;
    }
  }
};
