import { actions as categoriesActions } from '../actions/category';

const initialState = {
  isLoading: false,
  categories: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case categoriesActions.loadingCategories: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case categoriesActions.loadCategoriesSuccess: {
      return {
        ...state,
        isLoading: false,
        categories: action.payload,
      };
    }

    case categoriesActions.loadCategoriesFailed: {
      return {
        ...state,
        isLoading: false,
        categories: [],
      };
    }

    case categoriesActions.refreshCategories: {
      return {
        ...state,
        categories: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};
