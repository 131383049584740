import { MENU_DECORATE_ACTIONS } from 'src/actions/menuDecorate';
import {
  MENU_PAGE_THEMES,
  MENU_PAGE_THEME_CUSTOM,
  MENU_PAGE_THEME_WHITE,
  MENU_TOOL_ZINDEX,
} from 'src/consts/menuDecorate';
import { THEME_MODE, detectColorStyle } from 'src/utils/color';
import EventEmitter, { EVENTS } from 'src/utils/eventEmitter';
import { extractMealBriefInfo } from 'src/utils/menu';
import { generateShortUUID } from 'src/utils/utils';

const TEMP_PAGE_NAME_PREFIX = 'Page';
const LIGHT_BORDER_COLOR = '#C0C2CC';
const categoryIdsOfMenu = {};

const cacheInfoState = {
  // pages of menus sequence dirty status Record<string, object> { [id]: { page_ids: [], dirty } }
  menuBriefInfoMap: {},
  // pages sequence of categories, { [categoryId_menuId]: { page_ids: [], dirty: false } }
  categoryPageIdsMap: {},
  // page info mapping Map<string, object> { id, menu_id, name, background_image_url, component_ids, max_z_index, category_ids, saved }
  pageInfoMap: {},
  // component of each page Map<string, object> { item_config, position, dish_info, menu_id, page_id, z_index, dirty }
  componentsMap: {},
  // page dirty status
  pageDirtyMap: {},
  // check theme is changed
  themeChanged: false,
  // default is red theme { id, theme_name, background_color, mode, content_color }
  currentThemeConfig: {},
  // page canvas object
  orientation: null,
  // current editing page id
  editingPageId: null,
  // current editing component
  editingComponentId: null,
  // page decoration snapshot
  pageDecorationSnapshot: {},
  // current location
  location: null,
  showCategory: false,
};

function getClonedCacheInfoState() {
  return JSON.parse(JSON.stringify(cacheInfoState));
}

function getParamsFromLocation(location) {
  if (!location) return {};

  const segments = location.split('-');

  if (segments.length === 2) {
    const [menuId, pageId] = segments;
    return { menuId, pageId };
  }

  if (segments.length === 3) {
    const [menuId, categoryId, pageId] = segments;
    return { menuId, categoryId, pageId };
  }

  return {};
}

const initialState = {
  ...getClonedCacheInfoState(),
  menus: [],
  loadingMenus: false,
  loadMenusSuccess: false,
  loadMenusFailed: false,
  meals: {},
  loadingMeals: false,
  loadMealsSuccess: false,
  loadMealsFailed: false,
  scale: 1.0,
  menuDecorationMap: {},
  loadingMenuDecorations: false,
  loadMenuDecorationsSuccess: false,
  loadMenuDecorationsFailed: false,
};

function handleThemeConfig(themeConfig) {
  let mode;
  const { theme_name } = themeConfig;
  if (theme_name !== MENU_PAGE_THEME_CUSTOM) {
    themeConfig.background_color = MENU_PAGE_THEMES[theme_name]?.background_color;
  }
  const { background_color } = themeConfig;
  if ([MENU_PAGE_THEME_CUSTOM, MENU_PAGE_THEME_WHITE].includes(theme_name)) {
    mode = detectColorStyle(background_color);
  } else {
    mode = THEME_MODE.DARK;
  }

  const isDark = mode === THEME_MODE.DARK;
  const content_color = isDark ? '#fff' : '#000';
  const border_color = isDark ? content_color : LIGHT_BORDER_COLOR;
  themeConfig.mode = mode;
  themeConfig.content_color = content_color;
  themeConfig.border_color = border_color;
}

function getDefaultDecoration() {
  const theme_name = Object.keys(MENU_PAGE_THEMES)[0];
  const themeConfig = {
    theme_name,
    background_color: MENU_PAGE_THEMES[theme_name]?.background_color,
  };
  return {
    config: themeConfig,
  };
}

function getCategoryPageInfo({ categoryPageIdsMap, menuId, categoryId }) {
  const path = [menuId, categoryId].join('-');
  return categoryPageIdsMap[path];
}

function checkPageInCategory({ categoryPageInfo, pageId }) {
  if (!categoryPageInfo) return false;
  return categoryPageInfo.page_ids.includes(pageId);
}

/**
 * add page to a certain category
 * @param {object} categoryPageIdsMap
 * @param {string} menuId
 * @param {string} categoryId
 * @param {string} pageId
 * @param {boolean} dirty
 */
function addPageToCategory({ categoryPageIdsMap, menuId, categoryId, pageId, dirty }) {
  const categoryPageInfo = getCategoryPageInfo({ categoryPageIdsMap, menuId, categoryId });

  if (!categoryPageInfo) {
    const path = [menuId, categoryId].join('-');
    const categoryIds = categoryIdsOfMenu[menuId] || [];
    const sequence = categoryIds.indexOf(categoryId) + 1;
    categoryPageIdsMap[path] = { page_ids: [pageId], dirty, sequence };
    return;
  }

  const { page_ids } = categoryPageInfo;
  if (page_ids.includes(pageId)) return;
  page_ids.push(pageId);
  categoryPageInfo.dirty = dirty;
}

/**
 * remove page form other categories other than current page
 * @param {object} categoryPageIdsMap
 * @param {string} menuId
 * @param {string} pageId
 * @param {string} categoryId
 */
function removePageFromOtherCategories({ categoryPageIdsMap, menuId, pageId, categoryId }) {
  const prefix = `${menuId}-`;
  Object.keys(categoryPageIdsMap).forEach((path) => {
    if (!path.startsWith(prefix)) return;

    const [_, _categoryId] = path.split('-');
    if (_categoryId === categoryId) return;

    const categoryPageInfo = categoryPageIdsMap[path];
    const { page_ids } = categoryPageInfo;
    if (!page_ids) return;

    const index = page_ids.indexOf(pageId);
    if (index < 0) return;

    page_ids.splice(index, 1);
    categoryPageInfo.dirty = true;
  });
}

/**
 * remove page from a certain category
 * @param {object} categoryPageIdsMap
 * @param {string} menuId
 * @param {string} pageId
 * @param {string} categoryId
 * @return {string} new page to be selected within the current category
 */
function removePageFromCategory({ categoryPageIdsMap, menuId, categoryId, pageId }) {
  const categoryPageInfo = getCategoryPageInfo({ categoryPageIdsMap, menuId, categoryId });
  if (!categoryPageInfo) return null;
  const { page_ids } = categoryPageInfo;
  const index = page_ids.indexOf(pageId);
  if (index < 0) return null;
  page_ids.splice(index, 1);
  categoryPageInfo.dirty = true;
  return page_ids[index === 0 ? index : index - 1];
}

/**
 * find dishes grouped by categories within a certain page
 * @param {object} nextState
 * @param {string} categoryId
 * @param {string} pageId
 */
function findDishesOfTheCategoryInPage({ nextState, categoryId, pageId }) {
  const { pageInfoMap, componentsMap } = nextState;
  const pageInfo = pageInfoMap[pageId];
  const { component_ids } = pageInfo;
  if (!component_ids || !component_ids.length) return [];

  return component_ids.reduce((acc, componentId) => {
    const component = componentsMap[componentId];
    if (!component) return acc;
    const { dish_info } = component;
    if (!dish_info) return acc;
    const { category, id } = dish_info;
    if (category?.id === categoryId) acc.push(id);
    return acc;
  }, []);
}

/**
 * handle replace or delete dish link of hotspot
 * @param {object} nextState
 * @param {object} dishInfo
 * @param {string} categoryId
 * @param {string} pageId
 * @param {string} menuId
 */
function handleReplaceOrDeleteDish({ nextState, dishInfo, categoryId, pageId, menuId }) {
  const { category } = dishInfo;
  if (!category || category.id === categoryId) return;

  const { categoryPageIdsMap } = nextState;
  const categoryPageInfo = getCategoryPageInfo({ categoryPageIdsMap, menuId, categoryId });
  const pageInCategory = checkPageInCategory({ categoryPageInfo, pageId });
  if (!pageInCategory) return;

  const dishesOfTheCategory = findDishesOfTheCategoryInPage({
    nextState,
    categoryId: category.id,
    pageId,
  });

  if (!dishesOfTheCategory.length) {
    removePageFromCategory({
      categoryPageIdsMap,
      menuId,
      categoryId: category.id,
      pageId,
    });
  }
}

/**
 * find category pathes that contains a certain page
 * @param {object} categoryPageIdsMap
 * @param {string} menuId
 * @param {string} pageId
 * @returns {Array<string>} matched category pathes
 */
function findCategoryPathesContainsPage({ categoryPageIdsMap, menuId, pageId }) {
  const prefix = `${menuId}-`;
  return Object.keys(categoryPageIdsMap).reduce((acc, path) => {
    if (!path.startsWith(prefix)) return acc;

    const { page_ids } = categoryPageIdsMap[path];
    if ((page_ids || []).includes(pageId)) acc.push(path);
    return acc;
  }, []);
}

function processMenuPageData({ nextState, page, menu_id }) {
  const { hot_spots, category_ids, ...other } = page;
  const {
    menuBriefInfoMap,
    categoryPageIdsMap,
    pageInfoMap,
    pageDirtyMap,
    meals,
    componentsMap,
    pageDecorationSnapshot,
    showCategory,
  } = nextState;
  const menuInfo = menuBriefInfoMap[menu_id];
  const hotSpots = hot_spots || [];
  const pageId = generateShortUUID();
  menuInfo.page_ids.push(pageId);

  if (showCategory && category_ids) {
    category_ids.forEach((categoryId) => {
      addPageToCategory({
        categoryPageIdsMap,
        menuId: menu_id,
        categoryId,
        pageId,
        dirty: false,
      });
    });
  }

  const component_ids = [];
  const pageInfo = {
    ...other,
    id: pageId,
    menu_id,
    component_ids,
    max_z_index: 1,
    saved: true,
  };
  pageInfoMap[pageId] = pageInfo;
  pageDirtyMap[pageId] = false;
  const pageComponents = [];
  hotSpots.forEach((component) => {
    const componentId = generateShortUUID();
    component_ids.push(componentId);
    const { position, ...item_config } = component;
    let dish_info;
    const { meal_instance_id } = component.dish_config || {};
    if (meal_instance_id) {
      const meal = meals[meal_instance_id];
      if (meal) {
        dish_info = extractMealBriefInfo(meal);
      } else {
        dish_info = {
          name: 'Item be deleted',
          foreign_name: '菜品已删除',
          id: meal_instance_id,
          deleted: true,
        };
      }
    }
    const componentInfo = {
      dirty: false,
      layout_changed: false,
      id: componentId,
      page_id: pageId,
      menu_id,
      dish_info,
      z_index: 1,
      position,
      item_config,
    };
    componentsMap[componentId] = componentInfo;
    pageComponents.push(JSON.parse(JSON.stringify(componentInfo)));
  });
  pageDecorationSnapshot[pageId] = {
    pageInfo: JSON.parse(JSON.stringify(pageInfo)),
    pageComponents,
  };
}

function processMenuData({ nextState, menu }) {
  const { menu_id, pages } = menu;
  const { menuBriefInfoMap } = nextState;
  menuBriefInfoMap[menu_id] = {
    page_ids: [],
    dirty: false,
  };
  (pages || [])
    .sort((a, b) => a.sequence - b.sequence)
    .forEach((page) => {
      processMenuPageData({ nextState, page, menu_id });
    });
}

function processMenuDecoration({ state, decoration }) {
  const { config } = decoration;
  const { menus: decorationMenus, ...currentThemeConfig } = config;
  handleThemeConfig(currentThemeConfig);
  const nextState = {
    ...state,
    menuBriefInfoMap: {},
    categoryPageIdsMap: {},
    pageInfoMap: {},
    pageDirtyMap: {},
    componentsMap: {},
    pageDecorationSnapshot: {},
    currentThemeConfig,
  };
  state.menus.map((menu) => {
    let decorationMenu = (decorationMenus || []).find((_) => _.menu_id === String(menu.id));
    if (!decorationMenu) {
      decorationMenu = {
        menu_id: menu.id,
        pages: [],
      };
    }

    processMenuData({ nextState, menu: decorationMenu });
  });
  return nextState;
}

function processMenuDecorationRelatedDatas(state) {
  const { orientation, menuDecorationMap } = state;
  if (!orientation) return state;

  const decoration = menuDecorationMap[orientation] || getDefaultDecoration();
  return processMenuDecoration({ state, decoration });
}

function handleCurrentComponentChanged(nextState) {
  const { location, editingComponentId } = nextState;
  const { pageId } = getParamsFromLocation(location);
  if (!editingComponentId || !nextState.pageInfoMap[pageId]) return nextState;

  const newPageInfoMap = { ...nextState.pageInfoMap };
  const pageInfo = newPageInfoMap[pageId];
  const newComponentsMap = { ...nextState.componentsMap };

  if (pageInfo.max_z_index < MENU_TOOL_ZINDEX - 2) {
    pageInfo.max_z_index += 1;
    const _component = newComponentsMap[editingComponentId];
    _component.z_index = pageInfo.max_z_index;
  } else {
    const { component_ids } = pageInfo;
    component_ids.forEach((componentId) => {
      const _component = newComponentsMap[componentId];
      if (componentId === editingComponentId) {
        _component.z_index = 2;
      } else {
        _component.z_index = 1;
      }
    });
    pageInfo.max_z_index = 2;
  }

  nextState.pageInfoMap = newPageInfoMap;
  nextState.componentsMap = newComponentsMap;
  return nextState;
}

function handleSwitchPage(nextState) {
  const { pageInfoMap, location } = nextState;
  const { pageId } = getParamsFromLocation(location);
  const pageInfo = pageInfoMap[pageId];

  if (!pageInfo) {
    EventEmitter.emit(EVENTS.DELETE_CROP);
    return nextState;
  }

  let currentComponentId = null;
  const { component_ids = [] } = pageInfo;
  let component;

  if (component_ids.length) {
    currentComponentId = component_ids[0];
    component = nextState.componentsMap[currentComponentId];
  }

  if (component) {
    EventEmitter.emit(EVENTS.CREATE_CROP_BY_CONTENT, component.position, nextState.scale);
  } else {
    EventEmitter.emit(EVENTS.DELETE_CROP);
  }

  nextState.editingComponentId = currentComponentId;
  handleCurrentComponentChanged(nextState);
}

function handleAddPageToMenu({ nextState, menuId, categoryId }) {
  const { menuBriefInfoMap } = nextState;
  if (!menuBriefInfoMap[menuId]) return nextState;

  // add page_id into menu brief info
  const newMenuBriefInfoMap = { ...menuBriefInfoMap };
  const pageId = generateShortUUID();
  let location = [menuId, pageId].join('-');
  let menuInfo = newMenuBriefInfoMap[menuId];
  if (menuInfo) {
    menuInfo.page_ids.push(pageId);
  } else {
    menuInfo = newMenuBriefInfoMap[menuId] = {
      page_ids: [pageId],
    };
  }
  menuInfo.dirty = true;
  nextState.menuBriefInfoMap = newMenuBriefInfoMap;

  // add page to category page map
  if (categoryId) {
    location = [menuId, categoryId, pageId].join('-');
    const categoryPageIdsMap = { ...nextState.categoryPageIdsMap };
    addPageToCategory({
      categoryPageIdsMap,
      menuId,
      categoryId,
      pageId,
      dirty: true,
    });
    nextState.categoryPageIdsMap = categoryPageIdsMap;
  }

  // cache page info
  const newPageInfoMap = { ...nextState.pageInfoMap };
  const pageInfo = {
    menu_id: menuId,
    id: pageId,
    name: `${TEMP_PAGE_NAME_PREFIX} ${menuInfo.page_ids.length}`,
    background_image_url: null,
    component_ids: [],
    max_z_index: 0,
    saved: false,
  };
  newPageInfoMap[pageId] = pageInfo;
  nextState.pageInfoMap = newPageInfoMap;
  const newPageDirtyMap = { ...nextState.pageDirtyMap };
  newPageDirtyMap[pageId] = false;
  nextState.pageDirtyMap = newPageDirtyMap;
  // 移除热点crop
  EventEmitter.emit(EVENTS.DELETE_CROP);
  nextState.location = location;
  nextState.editingComponentId = null;
  return nextState;
}

function getOldThemeConfig(state) {
  const { orientation, menuDecorationMap } = state;
  const menuDecoration = menuDecorationMap[orientation];
  if (!menuDecoration) return null;

  const { config } = menuDecoration;
  const { background_color, theme_name } = config;
  return { background_color, theme_name };
}

function handleClearDirtyState(nextState) {
  const newMenuBriefInfoMap = { ...nextState.menuBriefInfoMap };
  Object.values(newMenuBriefInfoMap).forEach((menuItem) => {
    menuItem.dirty = false;
  });
  nextState.menuBriefInfoMap = newMenuBriefInfoMap;

  const newPageDirtyMap = { ...nextState.pageDirtyMap };
  Object.keys(newPageDirtyMap).forEach((pageId) => {
    newPageDirtyMap[pageId] = false;
  });
  nextState.pageDirtyMap = newPageDirtyMap;

  const newCategoryPageIdsMap = { ...nextState.categoryPageIdsMap };
  Object.values(newCategoryPageIdsMap).forEach((categoryPageInfo) => {
    categoryPageInfo.dirty = false;
  });
  nextState.categoryPageIdsMap = newCategoryPageIdsMap;
}

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case MENU_DECORATE_ACTIONS.SET_INITIAL_STATUS: {
      return {
        ...state,
        ...payload,
      };
    }

    case MENU_DECORATE_ACTIONS.LOADING_QR_MENUS: {
      return {
        ...state,
        menus: [],
        loadingMenus: true,
        loadMenusSuccess: false,
        loadMenusFailed: false,
      };
    }

    case MENU_DECORATE_ACTIONS.LOAD_QR_MENUS_SUCCESS: {
      const { menus } = payload;
      menus.forEach((menu) => {
        const { categories } = menu;
        categoryIdsOfMenu[menu.id] = categories.map((_) => String(_.id));
      });

      return {
        ...state,
        menus: menus,
        loadingMenus: false,
        loadMenusSuccess: true,
        loadMenusFailed: false,
      };
    }

    case MENU_DECORATE_ACTIONS.LOAD_QR_MENUS_FAILED: {
      return {
        ...state,
        menus: [],
        loadingMenus: false,
        loadMenusSuccess: false,
        loadMenusFailed: true,
      };
    }

    case MENU_DECORATE_ACTIONS.LOADING_MEALS: {
      return {
        ...state,
        meals: [],
        loadingMeals: true,
        loadMealsSuccess: false,
        loadMealsFailed: false,
      };
    }

    case MENU_DECORATE_ACTIONS.LOAD_MEALS_SUCCESS: {
      return {
        ...state,
        meals: payload.meals,
        loadingMeals: false,
        loadMealsSuccess: true,
        loadMealsFailed: false,
      };
    }

    case MENU_DECORATE_ACTIONS.LOAD_MEALS_FAILED: {
      return {
        ...state,
        meals: [],
        loadingMeals: false,
        loadMealsSuccess: false,
        loadMealsFailed: true,
      };
    }

    case MENU_DECORATE_ACTIONS.LOADING_MENU_DECORATIONS: {
      return {
        ...state,
        loadingMenuDecorations: true,
        loadMenuDecorationsSuccess: false,
        loadMenuDecorationsFailed: false,
      };
    }

    case MENU_DECORATE_ACTIONS.LOAD_MENU_DECORATIONS_FAILED: {
      return {
        ...state,
        loadingMenuDecorations: false,
        loadMenuDecorationsSuccess: false,
        loadMenuDecorationsFailed: true,
      };
    }

    case MENU_DECORATE_ACTIONS.LOAD_MENU_DECORATIONS_SUCCESS: {
      const nextState = {
        ...state,
        ...payload,
        loadingMenuDecorations: false,
        loadMenuDecorationsSuccess: true,
        loadMenuDecorationsFailed: false,
      };
      return processMenuDecorationRelatedDatas(nextState);
    }

    case MENU_DECORATE_ACTIONS.CLEAR_DIRTY_STATE: {
      const nextState = { ...state };
      handleClearDirtyState(nextState);
      return nextState;
    }

    case MENU_DECORATE_ACTIONS.SAVE_MENU_DECORATION_SUCCESS: {
      const nextState = { ...state };
      handleClearDirtyState(nextState);
      nextState.menuDecorationMap = payload;
      nextState.themeChanged = false;
      const { pageId } = getParamsFromLocation(state.location);
      if (!pageId) return nextState;

      const newPageInfoMap = { ...state.pageInfoMap };
      const pageInfo = newPageInfoMap[pageId];
      if (!pageInfo) return nextState;
      pageInfo.saved = true;
      nextState.pageInfoMap = newPageInfoMap;
      const { component_ids } = pageInfo;
      const components = component_ids.map((componentId) => {
        const component = state.componentsMap[componentId];
        return JSON.parse(JSON.stringify(component));
      });
      const pageSnapshot = {
        pageInfo: JSON.parse(JSON.stringify(pageInfo)),
        pageComponents: components,
      };
      const newPageDecorationSnapshot = { ...state.pageDecorationSnapshot };
      newPageDecorationSnapshot[pageId] = pageSnapshot;
      nextState.pageDecorationSnapshot = newPageDecorationSnapshot;
      return nextState;
    }

    case MENU_DECORATE_ACTIONS.ADD_PAGE_TO_MENU:
    case MENU_DECORATE_ACTIONS.ADD_PAGE_TO_CATEGORY: {
      const nextState = { ...state };
      return handleAddPageToMenu({ nextState, ...payload });
    }

    case MENU_DECORATE_ACTIONS.UPDATE_PAGE_NAME: {
      const { pageId, name, changed } = payload;
      const newPageInfoMap = { ...state.pageInfoMap };
      const pageInfo = newPageInfoMap[pageId];
      if (pageInfo) {
        pageInfo.name = name;
      }
      const nextState = {
        ...state,
        pageInfoMap: newPageInfoMap,
        editingPageId: null,
      };

      if (changed) {
        const newPageDirtyMap = { ...state.pageDirtyMap };
        newPageDirtyMap[pageId] = true;
        nextState.pageDirtyMap = newPageDirtyMap;
      }

      return nextState;
    }

    case MENU_DECORATE_ACTIONS.REMOVE_PAGE_FROM_MENU: {
      const { menuBriefInfoMap } = state;
      const { menuId, pageId, saved } = payload;
      if (!menuBriefInfoMap[menuId]) return state;

      const nextState = { ...state };
      // remove page from menu
      const newMenuBriefInfoMap = { ...menuBriefInfoMap };
      const menuInfo = newMenuBriefInfoMap[menuId];
      const { page_ids } = menuInfo;
      const index = page_ids.indexOf(pageId);
      if (index < -1) return state;
      page_ids.splice(index, 1);
      if (saved) menuInfo.dirty = true;
      nextState.menuBriefInfoMap = newMenuBriefInfoMap;
      let newLocation = null;
      const newPageId = page_ids[index === 0 ? index : index - 1];
      if (newPageId) newLocation = [menuId, newPageId].join('-');

      nextState.location = newLocation;
      // remove page cache info
      const newPageInfoMap = { ...state.pageInfoMap };
      delete newPageInfoMap[pageId];
      nextState.pageInfoMap = newPageInfoMap;
      // remove page dirty status
      const newPageDirtyMap = { ...state.pageDirtyMap };
      delete newPageDirtyMap[pageId];
      nextState.pageDirtyMap = newPageDirtyMap;
      // delete page snapshot
      const newPageDecorationSnapshot = { ...state.pageDecorationSnapshot };
      delete newPageDecorationSnapshot[pageId];
      nextState.pageDecorationSnapshot = newPageDecorationSnapshot;
      handleSwitchPage(nextState);
      if (!saved) handleClearDirtyState(nextState);
      return nextState;
    }

    case MENU_DECORATE_ACTIONS.REMOVE_PAGE_FROM_CATEGORY: {
      const { menuBriefInfoMap } = state;
      const { menuId, pageId, categoryId, saved } = payload;
      if (!menuBriefInfoMap[menuId]) return state;

      const nextState = { ...state };
      const categoryPageIdsMap = { ...state.categoryPageIdsMap };
      nextState.categoryPageIdsMap = categoryPageIdsMap;
      const newPageId = removePageFromCategory({
        categoryPageIdsMap,
        menuId,
        categoryId,
        pageId,
      });
      const newLocation = !newPageId ? null : [menuId, categoryId, newPageId].join('-');
      nextState.location = newLocation;
      const categoryPathes = findCategoryPathesContainsPage({ categoryPageIdsMap, menuId, pageId });

      if (!categoryPathes.length) {
        // remove page from menu
        const newMenuBriefInfoMap = { ...menuBriefInfoMap };
        const menuInfo = newMenuBriefInfoMap[menuId];
        const { page_ids } = menuInfo;
        const index = page_ids.indexOf(pageId);
        if (index < -1) return state;
        page_ids.splice(index, 1);
        if (saved) menuInfo.dirty = true;
        nextState.menuBriefInfoMap = newMenuBriefInfoMap;

        // remove page cache info
        const newPageInfoMap = { ...state.pageInfoMap };
        delete newPageInfoMap[pageId];
        nextState.pageInfoMap = newPageInfoMap;
        // remove page dirty status
        const newPageDirtyMap = { ...state.pageDirtyMap };
        delete newPageDirtyMap[pageId];
        nextState.pageDirtyMap = newPageDirtyMap;
        // delete page snapshot
        const newPageDecorationSnapshot = { ...state.pageDecorationSnapshot };
        delete newPageDecorationSnapshot[pageId];
        nextState.pageDecorationSnapshot = newPageDecorationSnapshot;
      }

      handleSwitchPage(nextState);
      if (!saved) handleClearDirtyState(nextState);
      return nextState;
    }

    case MENU_DECORATE_ACTIONS.ADD_COMPONENT_TO_PAGE: {
      const { pageInfoMap, location } = state;
      const { component } = payload;
      const { pageId } = getParamsFromLocation(location);
      if (!pageId || !pageInfoMap[pageId]) return state;

      // set dirty flag
      const newPageDirtyMap = { ...state.pageDirtyMap };
      newPageDirtyMap[pageId] = true;
      // add component to page info
      const newPageInfoMap = { ...pageInfoMap };
      const pageInfo = newPageInfoMap[pageId];
      const { component_ids, menu_id } = pageInfo;
      const componentId = generateShortUUID();
      component_ids.push(componentId);
      if (!pageInfo.max_z_index) {
        pageInfo.max_z_index = 0;
      }
      pageInfo.max_z_index += 1;
      // cache component info
      component.id = componentId;
      component.page_id = pageId;
      component.menu_id = menu_id;
      component.dirty = false;
      component.z_index = pageInfo.max_z_index;
      const newComponentsMap = { ...state.componentsMap };
      newComponentsMap[componentId] = component;
      EventEmitter.emit(EVENTS.CREATE_CROP_BY_CONTENT, component.position, state.scale);

      return {
        ...state,
        editingComponentId: componentId,
        componentsMap: newComponentsMap,
        pageDirtyMap: newPageDirtyMap,
        pageInfoMap: newPageInfoMap,
      };
    }

    case MENU_DECORATE_ACTIONS.ADJUST_COMPONENT_CONFIGS: {
      const { editingComponentId } = state;
      if (!editingComponentId) return state;

      const newComponentsMap = { ...state.componentsMap };
      const component = newComponentsMap[editingComponentId];
      const clonedComponent = {
        ...component,
        ...payload,
        dirty: true,
      };
      if (payload.layout_changed) {
        clonedComponent.old_layout = component.item_config.layout;
      }
      newComponentsMap[editingComponentId] = clonedComponent;
      // update page dirty status
      const newPageDirtyMap = { ...state.pageDirtyMap };
      newPageDirtyMap[component.page_id] = true;

      return {
        ...state,
        componentsMap: newComponentsMap,
        pageDirtyMap: newPageDirtyMap,
      };
    }

    case MENU_DECORATE_ACTIONS.ADJUST_CONPONENT_DISH_LINK: {
      const { editingComponentId } = state;
      if (!editingComponentId) return state;

      const { dishInfo } = payload;
      const { pageId, menuId, categoryId } = getParamsFromLocation(state.location);
      // const pageInfo = state.pageInfoMap[pageId];
      const nextState = { ...state };
      const newComponentsMap = { ...state.componentsMap };
      const component = newComponentsMap[editingComponentId];
      const clonedComponent = {
        ...component,
        dish_info: dishInfo,
        layout_changed: false,
        dirty: true,
      };
      clonedComponent.item_config.dish_config.meal_instance_id = dishInfo.id;
      newComponentsMap[editingComponentId] = clonedComponent;
      nextState.componentsMap = newComponentsMap;

      // update page dirty status
      const newPageDirtyMap = { ...state.pageDirtyMap };
      newPageDirtyMap[component.page_id] = true;
      nextState.pageDirtyMap = newPageDirtyMap;
      if (!nextState.showCategory) return nextState;

      const categoryPageIdsMap = { ...state.categoryPageIdsMap };
      nextState.categoryPageIdsMap = categoryPageIdsMap;
      const { category } = dishInfo;
      // if ths dish is not current category, then add to that category if not contained
      if (category.id !== categoryId) {
        addPageToCategory({
          categoryPageIdsMap,
          menuId,
          categoryId: category.id,
          pageId,
          dirty: true,
        });
      }

      const oldDishInfo = component.dish_info;
      if (oldDishInfo && !oldDishInfo.deleted) {
        handleReplaceOrDeleteDish({ nextState, dishInfo: oldDishInfo, categoryId, pageId, menuId });
      }
      return nextState;
    }

    case MENU_DECORATE_ACTIONS.DELETE_CURRENT_COMPONENT: {
      const { pageId, categoryId, menuId } = getParamsFromLocation(state.location);
      const { pageInfoMap, editingComponentId: componentId, componentsMap } = state;
      if (!pageInfoMap[pageId]) return state;

      const nextState = { ...state };
      // remove component from page
      const newPageInfoMap = { ...pageInfoMap };
      const pageInfo = newPageInfoMap[pageId];
      const { component_ids } = pageInfo;
      const index = component_ids.indexOf(componentId);
      if (index > -1) component_ids.splice(index, 1);
      nextState.pageInfoMap = newPageInfoMap;

      // delete component cache
      const newComponentsMap = { ...componentsMap };
      delete newComponentsMap[componentId];
      nextState.componentsMap = newComponentsMap;

      // auto select another component within page
      let newEditingComponentId = null;
      if (!component_ids.length) {
        EventEmitter.emit(EVENTS.DELETE_CROP);
      } else {
        newEditingComponentId = component_ids[0];
        const newComponent = newComponentsMap[newEditingComponentId];
        newComponent.z_index = pageInfo.max_z_index;
        EventEmitter.emit(EVENTS.CREATE_CROP_BY_CONTENT, newComponent.position, state.scale);
      }
      nextState.editingComponentId = newEditingComponentId;

      // update page dirty status
      const newPageDirtyMap = { ...state.pageDirtyMap };
      newPageDirtyMap[pageId] = true;
      nextState.pageDirtyMap = newPageDirtyMap;
      if (!state.showCategory) return nextState;

      const component = componentsMap[componentId];
      const { dish_info } = component;
      const newCategoryPageIdsMap = { ...state.categoryPageIdsMap };
      nextState.categoryPageIdsMap = newCategoryPageIdsMap;
      handleReplaceOrDeleteDish({ nextState, dishInfo: dish_info, categoryId, pageId, menuId });
      return nextState;
    }

    case MENU_DECORATE_ACTIONS.DUPLICATE_COMPONENT_TO_PAGE: {
      const { pageId } = getParamsFromLocation(state.location);
      const { editingComponentId, pageInfoMap } = state;

      // add component id into page info
      const newPageInfoMap = { ...pageInfoMap };
      const pageInfo = newPageInfoMap[pageId];
      const { component_ids } = pageInfo;
      const componentId = generateShortUUID();
      component_ids.push(componentId);
      pageInfo.max_z_index += 1;
      // add component info cache
      const newComponentsMap = { ...state.componentsMap };
      const component = newComponentsMap[editingComponentId];
      const { position, ...other } = component;
      const duplicatedComponent = {
        ...other,
        id: componentId,
        dirty: false,
        position: {
          ...position,
          x: position.x + 40,
          y: position.y + 40,
        },
        z_index: pageInfo.max_z_index,
      };
      EventEmitter.emit(EVENTS.CREATE_CROP_BY_CONTENT, duplicatedComponent.position, state.scale);
      newComponentsMap[componentId] = duplicatedComponent;
      // set page dirty status
      const newPageDirtyMap = { ...state.pageDirtyMap };
      newPageDirtyMap[pageId] = true;

      return {
        ...state,
        componentsMap: newComponentsMap,
        pageInfoMap: newPageInfoMap,
        editingComponentId: componentId,
        pageDirtyMap: newPageDirtyMap,
      };
    }

    case MENU_DECORATE_ACTIONS.CANCEL_PAGE_MODIFICATIONS: {
      const { pageDecorationSnapshot } = state;
      const { pageId, menuId } = getParamsFromLocation(state.location);
      const pageSnapShot = pageDecorationSnapshot[pageId];
      const newPageDirtyMap = { ...state.pageDirtyMap };
      const newPageInfoMap = { ...state.pageInfoMap };
      const newComponentsMap = { ...state.componentsMap };
      const newMenuBriefInfoMap = { ...state.menuBriefInfoMap };
      const nextState = {
        ...state,
        pageDirtyMap: newPageDirtyMap,
        componentsMap: newComponentsMap,
        pageInfoMap: newPageInfoMap,
        menuBriefInfoMap: newMenuBriefInfoMap,
      };
      const pageInfo = newPageInfoMap[pageId];
      const { component_ids } = pageInfo;
      // to remove all components
      component_ids.forEach((componentId) => {
        delete newComponentsMap[componentId];
      });

      if (pageSnapShot) {
        const { pageInfo: oldPageInfo, pageComponents } = pageSnapShot;
        newPageInfoMap[pageId] = JSON.parse(JSON.stringify(oldPageInfo));
        pageComponents.forEach((component) => {
          newComponentsMap[component.id] = JSON.parse(JSON.stringify(component));
        });
        newPageDirtyMap[pageId] = false;
      } else {
        // remove page id from menu info
        const menuInfo = newMenuBriefInfoMap[menuId];
        const { page_ids } = menuInfo;
        const index = page_ids.indexOf(pageId);
        if (index > -1) page_ids.splice(index, 1);
        // delete page info cache and dirty status
        delete newPageInfoMap[pageId];
        delete newPageDirtyMap[pageId];
      }

      if (!payload) return nextState;

      const { type, payload: actionPayload } = payload;
      if (type === MENU_DECORATE_ACTIONS.CHANGE_LOCATION) {
        nextState.location = actionPayload.location;
        handleSwitchPage(nextState);
      } else if (type === MENU_DECORATE_ACTIONS.ADD_PAGE_TO_MENU) {
        handleAddPageToMenu({ nextState, ...actionPayload });
      }

      return nextState;
    }

    case MENU_DECORATE_ACTIONS.SORT_PAGES_IN_MENU: {
      const { menuId, pageIds } = payload;
      const newMenuBriefInfoMap = { ...state.menuBriefInfoMap };
      const menuInfo = newMenuBriefInfoMap[menuId];
      if (menuInfo) {
        menuInfo.page_ids = pageIds;
        menuInfo.dirty = true;
      } else {
        newMenuBriefInfoMap[menuId] = {
          page_ids: pageIds,
          dirty: true,
        };
      }
      return {
        ...state,
        menuBriefInfoMap: newMenuBriefInfoMap,
      };
    }

    case MENU_DECORATE_ACTIONS.SORT_PAGES_IN_CATEGORY: {
      const { menuId, categoryId, pageIds } = payload;
      const newCategoryPageIdsMap = { ...state.categoryPageIdsMap };
      const path = [menuId, categoryId].join('-');
      const menuCategoryPageInfo = newCategoryPageIdsMap[path];
      if (!menuCategoryPageInfo) return state;

      menuCategoryPageInfo.page_ids = pageIds;
      menuCategoryPageInfo.dirty = true;
      return {
        ...state,
        categoryPageIdsMap: newCategoryPageIdsMap,
      };
    }

    case MENU_DECORATE_ACTIONS.UPDATE_BACKGROUND_IMAGE_FOR_PAGE: {
      const { backgroundUrl, removeComponents, pageId } = payload;
      const { pageInfoMap } = state;
      const newPageInfoMap = { ...pageInfoMap };
      const pageInfo = newPageInfoMap[pageId];
      pageInfo.background_image_url = backgroundUrl;
      const newPageDirtyMap = { ...state.pageDirtyMap };
      newPageDirtyMap[pageId] = true;
      const nextState = {
        ...state,
        pageInfoMap: newPageInfoMap,
        pageDirtyMap: newPageDirtyMap,
      };

      if (!removeComponents) return nextState;

      const { component_ids } = pageInfo;
      if (!component_ids) return nextState;

      const newComponentsMap = { ...state.componentsMap };
      component_ids.forEach((componentId) => {
        delete newComponentsMap[componentId];
      });
      pageInfo.component_ids.length = 0;
      pageInfo.max_z_index = 0;
      nextState.componentsMap = newComponentsMap;
      EventEmitter.emit(EVENTS.DELETE_CROP);
      if (!nextState.showCategory) return nextState;

      const { categoryId, menuId } = getParamsFromLocation(nextState.location);
      const categoryPageIdsMap = { ...state.categoryPageIdsMap };
      nextState.categoryPageIdsMap = categoryPageIdsMap;
      removePageFromOtherCategories({
        categoryPageIdsMap,
        menuId,
        pageId,
        categoryId,
      });
      return nextState;
    }

    case MENU_DECORATE_ACTIONS.UPDATE_THUMBNAIL_IMAGE_FOR_PAGE: {
      const { pageInfoMap } = state;
      const { thumbnail_image_url, pageId } = payload;
      const newPageInfoMap = { ...pageInfoMap };
      const pageInfo = newPageInfoMap[pageId];
      pageInfo.thumbnail_image_url = thumbnail_image_url;
      return {
        ...state,
        pageInfoMap: newPageInfoMap,
      };
    }

    case MENU_DECORATE_ACTIONS.SET_EDITING_PAGE_ID:
    case MENU_DECORATE_ACTIONS.SCALE_CHANGED:
    case MENU_DECORATE_ACTIONS.UPDATE_MENU_DECOARATION_SUCCESS: {
      return {
        ...state,
        ...payload,
      };
    }

    case MENU_DECORATE_ACTIONS.CHANGE_LOCATION: {
      const { location } = payload;
      const nextState = {
        ...state,
        location,
      };
      const { pageId } = getParamsFromLocation(location);

      if (!pageId) {
        EventEmitter.emit(EVENTS.DELETE_CROP);
      } else {
        handleSwitchPage(nextState);
      }

      return nextState;
    }

    case MENU_DECORATE_ACTIONS.SET_CURRENT_EDITING_COMPONENT: {
      const { componentId } = payload;
      const nextState = {
        ...state,
        editingComponentId: componentId,
      };

      return handleCurrentComponentChanged(nextState);
    }

    case MENU_DECORATE_ACTIONS.CHANGE_THEME: {
      const { currentThemeConfig } = payload;
      handleThemeConfig(currentThemeConfig);
      const oldThemeConfig = getOldThemeConfig(state);
      let themeChanged = false;

      if (!oldThemeConfig) {
        themeChanged = true;
      } else {
        themeChanged =
          currentThemeConfig.theme_name !== oldThemeConfig.theme_name ||
          currentThemeConfig.background_color !== oldThemeConfig.background_color;
      }

      return {
        ...state,
        currentThemeConfig,
        themeChanged,
      };
    }

    case MENU_DECORATE_ACTIONS.ADJUST_COMPONENT_POSITION: {
      const { componentsMap, editingComponentId, pageDirtyMap } = state;
      // update component position and set component as dirty
      const newComponentsMap = { ...componentsMap };
      const component = newComponentsMap[editingComponentId];
      const clonedComponent = {
        ...component,
        ...payload,
        dirty: true,
        layout_changed: false,
      };
      newComponentsMap[editingComponentId] = clonedComponent;
      // set page as dirty
      const newPageDirtyMap = { ...pageDirtyMap };
      newPageDirtyMap[component.page_id] = true;

      return {
        ...state,
        componentsMap: newComponentsMap,
        pageDirtyMap: newPageDirtyMap,
      };
    }

    case MENU_DECORATE_ACTIONS.CLEAR_CACHE_INFOS: {
      return {
        ...state,
        ...getClonedCacheInfoState(),
      };
    }

    default: {
      return state;
    }
  }
};
