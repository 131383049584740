import {
  createTipAllocationPolicy,
  getAllTipAllocationPolicies,
  getTipAllocationSettings,
  togglePolicyActive,
  updateTipAllocationSettings,
} from '../services/tipsAllocation';

const BASE = 'TIPS_ALLOCATION_POLICY';

export const actions = {
  loadingSettings: `${BASE}_LOADING_SETTINGS`,
  loadSettingsSuccess: `${BASE}_LOAD_SETTINGS_SUCCESS`,
  loadSettingsFailed: `${BASE}_LOAD_SETTINGS_FAILED`,
  updatingSettings: `${BASE}_UPDATING_SETTINGS`,
  updateSettingsSuccess: `${BASE}_UPDATE_SETTINGS_SUCCESS`,
  updateSettingsFailed: `${BASE}_UPDATE_SETTINGS_FAILED`,
  loadingPolicies: `${BASE}_LOADING_POLICIES`,
  loadPoliciesSuccess: `${BASE}_LOAD_POLICIES_SUCCESS`,
  loadPoliciesFailed: `${BASE}_LOAD_POLICIES_FAILED`,
  updatePolicies: `${BASE}_UPDATE_POLICIES`,
};

export const fetchTipAllocationSettings = () => async (dispatch, getState) => {
  const { isLoading, settings } = getState().tipsAllocationPolicy;
  if (isLoading || settings) return;
  dispatch({ type: actions.loadingSettings });

  let response;

  try {
    response = await getTipAllocationSettings();
  } catch (e) {
    response = { success: false };
  }

  if (!response.success) {
    dispatch({ type: actions.loadSettingsFailed });
    return;
  }

  dispatch({
    type: actions.loadSettingsSuccess,
    payload: response.data,
  });
};

export const doUpdateTipAllocationSettings = (data, callback) => async (dispatch) => {
  dispatch({ type: actions.updatingSettings });
  let response;

  try {
    response = await updateTipAllocationSettings(data);
  } catch (e) {
    response = { success: false };
  }

  typeof callback === 'function' && callback(response);

  if (!response.success) {
    dispatch({ type: actions.updateSettingsFailed });
    return;
  }

  dispatch({
    type: actions.updateSettingsSuccess,
    payload: response.data,
  });
};

export const fetchTipAllocationPolicies = () => async (dispatch, getState) => {
  const { isLoadingPolicies } = getState().tipsAllocationPolicy;
  if (isLoadingPolicies) return;

  dispatch({ type: actions.loadingPolicies });
  let response;

  try {
    response = await getAllTipAllocationPolicies();
  } catch (e) {
    response = { success: false };
  }

  if (!response.success) {
    dispatch({ type: actions.loadPoliciesFailed });
    return;
  }

  dispatch({
    type: actions.loadPoliciesSuccess,
    payload: response.data,
  });
};

export const doCreateTipAllocationPolicies = (data) => async (dispatch) => {
  let response;

  try {
    response = await createTipAllocationPolicy(data);
  } catch (e) {
    response = { success: false };
  }

  if (!response.success) {
    dispatch({ type: actions.loadPoliciesFailed });
    return;
  }

  dispatch({
    type: actions.loadPoliciesSuccess,
    payload: response.data,
  });
};

export const doToggleTipAllocationPolicyActiveStatus = (id, active, callback) => async (dispatch, getState) => {
  let response;

  try {
    response = await togglePolicyActive(id, active);
  } catch (e) {
    response = { success: false };
  }

  typeof callback === 'function' && callback();
  if (!response.success) return;
  const { policies } = getState().tipsAllocationPolicy;
  const newPolicies = JSON.parse(JSON.stringify(policies));
  const index = newPolicies.findIndex((_) => _.id === id);
  if (index < 0) return;
  newPolicies[index].active = active;
  dispatch({
    type: actions.updatePolicies,
    payload: newPolicies,
  });
};
