import moment from 'moment';
// import { drawA5StandQRCode } from './DrawQRCode';
import { drawStickerQRCode } from './drawStickerQRCode';
import { draw5X8InchStandQRCode } from './draw5X8InchStandQRCode';
import { drawA5StandQRCode } from './drawA5StandQRCode';
import { addImagesToPDF, generateEmptyPDF, generatePDFWithImages } from '../../utils/drawLoyaltyQRCode';
import {
  QR_CODE_SIZE_STAND_A5,
  QR_CODE_SIZE_STAND_5X8_INCH,
  QR_CODE_SIZE_STICKER_4X6_INCH,
  QR_CODE_SIZE_STICKER_325_INCH,
  QR_CODE_SIZE_ACRYLIC_SHEETS_4X4_INCH,
  QR_CODE_SIZE_ACRYLIC_SHEETS_250X250_INCH,
  NORMAL_QRCODE_TYPE,
  TAKEOUT_QRCODE_TYPE,
  MENUBROWSING_QRCODE_TYPE,
} from '../../consts';
import { downloadFile } from '../../utils/utils';
import { groupList } from '../../utils/array';

const concurrency = 2;

class QRCodeTaskQueue {
  constructor({ onTaskDone, tasks, onAllTasksDone }) {
    this._tasks = tasks;
    this._onTaskDone = onTaskDone;
    this._onAllTasksDone = onAllTasksDone;
    this._aborted = false;
    this._targetFile = undefined;
    this._executeTasks();
  }

  abort() {
    this._aborted = true;
    this._emitAllTasksDone();
  }

  async _executeTasks() {
    const taskData = this._tasks[0];
    const taskGroups = groupList(this._tasks, concurrency);

    const isSingleFile = this._tasks.length == 1;
    for await (const taskGroup of taskGroups) {
      if (this._aborted) return;
      const files = await Promise.all(taskGroup.map((task) => this._executeTask(task)));
      await this._handleFiles(files, taskData, isSingleFile);
    }

    await this._handleAllTasksDone(taskData);
  }

  async _executeTask(taskData) {
    const { size, isQuickService, qrCodeType } = taskData;
    let file;
    if (size === QR_CODE_SIZE_STAND_5X8_INCH || size === QR_CODE_SIZE_STAND_A5) {
      let images = await drawA5StandQRCode(taskData);
      if (size === QR_CODE_SIZE_STAND_5X8_INCH) {
        images = await draw5X8InchStandQRCode(taskData);
      }
      if (qrCodeType !== NORMAL_QRCODE_TYPE || isQuickService) {
        file = images;
      } else {
        const { tableName, sectionName } = taskData;
        const fileName = `${sectionName}_${tableName}_${size}_QR_Code.pdf`;
        const pdf = await generatePDFWithImages({ images, size: taskData.size });
        const fileContent = pdf.output();
        file = { fileName, fileContent, pdf };
      }
    } else {
      const images = await drawStickerQRCode(taskData);
      if (size === QR_CODE_SIZE_STICKER_325_INCH && qrCodeType === NORMAL_QRCODE_TYPE && !isQuickService) {
        const { tableName, sectionName } = taskData;
        const fileName = `${sectionName}_${tableName}_${size}_QR_Code.pdf`;
        const pdf = await generatePDFWithImages({ images, size: taskData.size });
        const fileContent = pdf.output();
        file = { fileName, fileContent, pdf };
      } else if (
        size === QR_CODE_SIZE_ACRYLIC_SHEETS_4X4_INCH &&
        qrCodeType === NORMAL_QRCODE_TYPE &&
        !isQuickService
      ) {
        const { tableName, sectionName } = taskData;
        const fileName = `${sectionName}_${tableName}_${size}_QR_Code.pdf`;
        const pdf = await generatePDFWithImages({ images, size: taskData.size });
        const fileContent = pdf.output();
        file = { fileName, fileContent, pdf };
      } else if (
        size === QR_CODE_SIZE_ACRYLIC_SHEETS_250X250_INCH &&
        qrCodeType === NORMAL_QRCODE_TYPE &&
        !isQuickService
      ) {
        const { tableName, sectionName } = taskData;
        const fileName = `${sectionName}_${tableName}_${size}_QR_Code.pdf`;
        const pdf = await generatePDFWithImages({ images, size: taskData.size });
        const fileContent = pdf.output();
        file = { fileName, fileContent, pdf };
      } else if (size === QR_CODE_SIZE_STICKER_4X6_INCH && qrCodeType === NORMAL_QRCODE_TYPE && !isQuickService) {
        const { tableName, sectionName } = taskData;
        const fileName = `${sectionName}_${tableName}_${size}_QR_Code.pdf`;
        const pdf = await generatePDFWithImages({ images, size: taskData.size });
        const fileContent = pdf.output();
        file = { fileName, fileContent, pdf };
      } else {
        file = [...images];
      }
    }

    return file;
  }

  async _handleFiles(files, taskData, isSingleFile) {
    if (this._aborted) return;
    const { size, isQuickService, qrCodeType } = taskData;
    if (
      (size === QR_CODE_SIZE_STAND_A5 ||
        size === QR_CODE_SIZE_STAND_5X8_INCH ||
        size === QR_CODE_SIZE_STICKER_4X6_INCH ||
        size === QR_CODE_SIZE_STICKER_325_INCH ||
        size === QR_CODE_SIZE_ACRYLIC_SHEETS_4X4_INCH ||
        size === QR_CODE_SIZE_ACRYLIC_SHEETS_250X250_INCH) &&
      qrCodeType === NORMAL_QRCODE_TYPE &&
      !isQuickService
    ) {
      if (isSingleFile) {
        const { fileName, fileContent, pdf } = files[0];
        this._targetFile = pdf;
        this._totalPage = 1;
        if (!this._aborted && typeof this._onTaskDone === 'function') {
          this._onTaskDone();
        }
        return;
      }
      if (!this._targetFile) {
        const jsZIPModule = await import('jszip' /* webpackChunkName:"qrcode" */);
        const jsZIP = jsZIPModule.default;
        this._targetFile = new jsZIP();
      }
      files.forEach((file) => {
        const { fileName, fileContent } = file;
        this._targetFile.file(fileName, fileContent, { binary: true });

        if (!this._aborted && typeof this._onTaskDone === 'function') {
          this._onTaskDone();
        }
      });

      return;
    }

    if (!this._targetFile) {
      this._targetFile = await generateEmptyPDF(size);
      this._totalPage = 0;
    }

    files.forEach((file) => {
      addImagesToPDF({ pdf: this._targetFile, images: file, startPage: this._totalPage });
      this._totalPage += 1;

      if (!this._aborted && typeof this._onTaskDone === 'function') {
        this._onTaskDone();
      }
    });
  }

  async _handleAllTasksDone(taskData) {
    if (this._aborted) return;
    const { restaurantInfo, size, isQuickService, sectionName, tableName, qrCodeType } = taskData;
    const { name } = restaurantInfo;
    const time = moment().format('MM_DD_YYYY');
    const _tasksLength = this._tasks.length;
    let fileNamePrefix = '';
    if (qrCodeType === TAKEOUT_QRCODE_TYPE) {
      fileNamePrefix = 'take_out_';
    } else if (qrCodeType === MENUBROWSING_QRCODE_TYPE) {
      fileNamePrefix = 'menu_browsing_';
    }
    const _restaurauntName = (name || '').replace(/\.+/g, '');

    if (
      _tasksLength > 1 &&
      (size === QR_CODE_SIZE_STAND_A5 ||
        size === QR_CODE_SIZE_STAND_5X8_INCH ||
        size === QR_CODE_SIZE_STICKER_4X6_INCH ||
        size === QR_CODE_SIZE_STICKER_325_INCH ||
        size === QR_CODE_SIZE_ACRYLIC_SHEETS_4X4_INCH ||
        size === QR_CODE_SIZE_ACRYLIC_SHEETS_250X250_INCH) &&
      qrCodeType == NORMAL_QRCODE_TYPE &&
      !isQuickService
    ) {
      const zip = this._targetFile;
      const blob = await zip.generateAsync({ type: 'blob', mimeType: 'application/zip', compression: 'DEFLATE' });
      const fileName = `${_restaurauntName}_${size}_QR_Code_${time}`;
      downloadFile(blob, fileName);
    } else {
      const pdf = this._targetFile;
      let fileName = ``;
      if (tableName && size !== QR_CODE_SIZE_STICKER_4X6_INCH) {
        fileName = `${_restaurauntName}_${sectionName}_${tableName}_${fileNamePrefix}${size}_QR_Code_${time}.pdf`;
      } else {
        fileName = `${_restaurauntName}_${fileNamePrefix}${size}_QR_Code_${time}.pdf`;
      }
      await pdf.save(fileName);
    }

    this._emitAllTasksDone();
  }

  _emitAllTasksDone() {
    if (typeof this._onAllTasksDone === 'function') {
      this._onAllTasksDone(this);
    }
  }
}

export default class QRCodeTaskCluster {
  constructor({ onTaskDone, onAllDone }) {
    this._queues = [];
    this._onTaskDone = onTaskDone;
    this._onAllDone = onAllDone;
    this._resetExecutionInfo();
  }

  addQRCodeTasks({ tasks, onAllTasksDone }) {
    const total = tasks.length;
    this._total += total;
    this._notifyExecutionChanged();

    const handleAllDone = (queue) => {
      this._onQueueAllTasksDone(onAllTasksDone, queue);
    };

    this._queues.push(
      new QRCodeTaskQueue({
        tasks,
        onAllTasksDone: handleAllDone,
        onTaskDone: this._handleTaskDone.bind(this),
      })
    );
  }

  abort() {
    this._queues.forEach((queue) => {
      queue.abort();
    });

    if (typeof this._onAllDone === 'function') {
      this._onAllDone();
      this._resetExecutionInfo();
    }
  }

  _resetExecutionInfo() {
    this._total = 0;
    this._executed = 0;
  }

  _notifyExecutionChanged() {
    if (typeof this._onTaskDone === 'function') {
      this._onTaskDone({
        total: this._total,
        executed: this._executed,
      });
    }
  }

  _handleTaskDone() {
    this._executed += 1;
    this._notifyExecutionChanged();

    if (this._total === this._executed && typeof this._onAllDone === 'function') {
      this._onAllDone();
      this._resetExecutionInfo();
    }
  }

  async _onQueueAllTasksDone(onAllTasksDone, queue) {
    if (typeof onAllTasksDone === 'function') {
      onAllTasksDone();
    }

    const index = this._queues.indexOf(queue);

    if (index > -1) {
      this._queues.splice(index, 1);
    }
  }
}
