import { queryAdminUserActivityRecords } from '../services/activities';

const BASE = 'ACTIVITIES';

export const actions = {
  loadingActivities: `${BASE}_LOADING_ACTIVITIES`,
  loadActivitiesSuccess: `${BASE}_LOAD_ACTIVITIES_SUCCESS`,
  loadActivitiesFailed: `${BASE}_LOAD_ACTIVITIES_FAILED`,
};

export const fetchUserActivities = (params) => async (dispatch) => {
  try {
    dispatch({
      type: actions.loadingActivities,
    });

    const response = await queryAdminUserActivityRecords(params);
    if (!response.success) {
      dispatch({
        type: actions.loadActivitiesFailed,
      });
      return;
    }

    const { data, meta } = response;
    const { page = 1, page_size = 10, total = 0 } = meta || {};
    dispatch({
      type: actions.loadActivitiesSuccess,
      payload: {
        activities: data,
        pagination: {
          current: page,
          pageSize: page_size,
          total,
        },
      },
    });
  } catch (e) {
    dispatch({
      type: actions.loadActivitiesFailed,
    });
  }
};
