import { DELETE, GET, POST, PUT } from '../utils/request';
import { API_PREFIX } from '../consts';

export function getTipAllocationSettings() {
  return GET(`${API_PREFIX}/v1/tip-allocation/setting`);
}

export function updateTipAllocationSettings(data) {
  return PUT(`${API_PREFIX}/v1/tip-allocation/setting`, data);
}

export function getAllTipAllocationPolicies() {
  return GET(`${API_PREFIX}/v1/tip-allocation/policies`);
}

export function getTipAllocationPolicyDetail(id) {
  return GET(`${API_PREFIX}/v1/tip-allocation/policy/${id}`);
}

export function createTipAllocationPolicy(data) {
  return POST(`${API_PREFIX}/v1/tip-allocation/policy`, data);
}

export function updateTipAllocationPolicy({ id, ...payload }) {
  return PUT(`${API_PREFIX}/v1/tip-allocation/policy/${id}`, payload);
}

export function deleteTipPolicy(id) {
  return DELETE(`${API_PREFIX}/v1/tip-allocation/policy/${id}`);
}

export function togglePolicyActive(id, active) {
  return PUT(`${API_PREFIX}/v1/tip-allocation/policy/${id}/toggle-active`, { active });
}

export function getTipSheets({ page = 1, page_size = 30, start_at, end_at, employee_id, sort_direction, sort_field }) {
  return GET(`${API_PREFIX}/v1/tip-allocation/sheets`, {
    page,
    page_size,
    start_at,
    end_at,
    employee_id,
    sort_direction,
    sort_field,
  });
}

export function getTipSheetActivities({ id, page = 1, page_size = 100 }) {
  return GET(`${API_PREFIX}/v2/user_activity/source/${id}`, {
    page,
    page_size,
  });
}
