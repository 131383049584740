import { batchUpdateQuickNotes, createQuickNotes, deleteQuickNotes, getQuickNotes } from '../services/quickNotes';

const BASE = 'QUICK_NOTES';

export const actions = {
  loadingQuickNotes: `${BASE}_LOADING_QUICK_NOTES`,
  loadedQuickNotes: `${BASE}_LOADED_QUICK_NOTES`,
};

export const setQuickNotes = (payments) => async (dispatch) => {
  dispatch({
    type: actions.loadedQuickNotes,
    payload: payments,
  });
};

export const queryQuickNotes = (callback) => async (dispatch, getState) => {
  let response;

  const {
    quickNotes: { quickNotes },
  } = getState();
  if (quickNotes.length) return;

  dispatch({ type: actions.loadingQuickNotes });

  try {
    response = await getQuickNotes();
  } catch (e) {
    response = { success: false };
  }

  const notes = response.success ? response.data || [] : [];
  notes.sort((a, b) => a.sequence - b.sequence);
  dispatch(setQuickNotes(notes));

  typeof callback === 'function' && callback(response);
};

export const doCreateQuickNotes = (payload, callback) => async (dispatch, getState) => {
  let response;

  try {
    response = await createQuickNotes(payload);
    response = await getQuickNotes();
  } catch (e) {
    response = { success: false };
  }

  typeof callback === 'function' && callback(response);

  if (!response.success) return;

  const {
    quickNotes: { quickNotes },
  } = getState();
  const newData = [...response.data];
  newData.sort((a, b) => a.sequence - b.sequence);
  dispatch(setQuickNotes(newData));
};

export const doUpdateQuickNotes = (payload, callback) => async (dispatch, getState) => {
  let response;

  try {
    response = await batchUpdateQuickNotes(payload);
  } catch (e) {
    response = { success: false };
  }

  typeof callback === 'function' && callback(response);

  if (!response.success) return;
  const {
    quickNotes: { quickNotes },
  } = getState();
  const newData = [...quickNotes];
  payload.forEach((item) => {
    const index = newData.findIndex((_) => _.id === item.id);
    if (index > -1) {
      newData[index] = { ...newData[index], ...item };
    }
  });
  newData.sort((a, b) => a.sequence - b.sequence);
  dispatch(setQuickNotes(newData));
};

export const doDeleteQuickNotes = (id, callback) => async (dispatch, getState) => {
  let response;

  try {
    response = await deleteQuickNotes(id);
  } catch (e) {
    response = { success: false };
  }

  typeof callback === 'function' && callback(response);

  if (!response.success) return;
  const {
    quickNotes: { quickNotes },
  } = getState();
  const newData = [...quickNotes];
  const index = newData.findIndex((_) => _.id === id);
  if (index > -1) {
    newData.splice(index, 1);
  }
  dispatch(setQuickNotes(newData));
};
