import { API_PREFIX } from '../consts';
import { POST, GET, DELETE } from '../utils/request';

export function createOrUpdateDishPreference(data) {
  return POST(`${API_PREFIX}/v1/dish/preference`, data);
}

export function getDishPreferences() {
  return GET(`${API_PREFIX}/v1/dish/preferences`);
}

export function getDishPreferenceDetail(id) {
  return GET(`${API_PREFIX}/v1/dish/preference/${id}`);
}

export function deleteDishPreference(id) {
  return DELETE(`${API_PREFIX}/v1/dish/preference/${id}`);
}

export function sortDishPreferences(data) {
  return POST(`${API_PREFIX}/v1/dish/preference/sequence`, data);
}

export function checkDishPreferenceOptionIsExisted(data) {
  return GET(`${API_PREFIX}/v1/exist/preference/option`, data);
}

export function checkDishPreferenceIsExisted(data) {
  return GET(`${API_PREFIX}/v1/exist/preference/category`, data, { silence: true });
}
