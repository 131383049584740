import { queryOrders } from '../services/order';
import { ORDER_SOURCE_ENUMS, PAYMENT_TYPE_CASH } from '../consts';
import { rangeNumber } from '../utils/array';

const BASE = 'ORDERS';

export const actions = {
  loadingOrders: `${BASE}_LOADING_ORDERS`,
  loadOrderSuccess: `${BASE}_LOAD_ORDERS_SUCCESS`,
  loadOrderFailed: `${BASE}_LOAD_ORDERS_FAILED`,

  updateOrders: `${BASE}_UPDATE_ORDERS`,
};

async function loadOrderPageData(params) {
  let response;

  try {
    response = await queryOrders(params);
  } catch (e) {
    response = { success: false };
  }

  if (!response.success) return response;

  const { data, page: pageData } = response.data || {};
  const { page = 1, page_size = 10, total = 0 } = pageData || {};
  const convertedData = (data || []).map((item) => {
    const payment_types = (item.payments || []).reduce((prev, current) => {
      const { payment_type } = current;
      if (!prev.includes(payment_type)) prev.push(payment_type);
      return prev;
    }, []);
    if (item.order_source === ORDER_SOURCE_ENUMS.ONLINE) item.paid_total = item.total;
    return {
      ...item,
      payment_types,
      canDelete: payment_types.length === 1 && payment_types[0] === PAYMENT_TYPE_CASH,
      orderTotalNotEqualPaidTotal: item.total !== item.paid_total,
    };
  });

  return {
    success: true,
    orders: convertedData,
    pagination: {
      current: page,
      pageSize: page_size,
      total,
    },
  };
}

export const fetchOrders = (params) => async (dispatch) => {
  dispatch({
    type: actions.loadingOrders,
  });

  const response = await loadOrderPageData(params);

  if (!response.success) {
    dispatch({
      type: actions.loadOrderFailed,
    });
    return;
  }

  dispatch({
    type: actions.loadOrderSuccess,
    payload: {
      orders: response.orders,
      pagination: response.pagination,
    },
  });
};

const fetchAllPageData = async ({ page = 1, allPageData, ...payload }) => {
  const response = await loadOrderPageData({ ...payload, page });
  if (!response.success) return response;
  const { orders, pagination } = response;
  const { current, total, pageSize } = pagination;
  const total_pages = Math.ceil(total / pageSize);
  if (!allPageData) allPageData = Array(total_pages).fill(null);
  allPageData[current - 1] = orders;

  if (current === 1 && current < total_pages) {
    const groups = rangeNumber({ start: 2, end: total_pages, groupCount: 3 });

    for await (const group of groups) {
      await Promise.all(group.map((page) => fetchAllPageData({ ...payload, allPageData, page })));
    }
  }

  return { success: true, data: allPageData };
};

export const fetchExportingOrders = (params, callback) => async () => {
  const response = await fetchAllPageData(params);
  let orders = [];
  if (response.success) {
    orders = response.data.reduce((acc, current) => {
      acc.push(...current);
      return acc;
    }, []);
  }
  typeof callback === 'function' && callback({ success: response.data, orders });
};
