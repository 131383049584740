import { getSessionItem, KEYS } from '../store/storage';
import moment from 'moment';
import {
  getRestaurantProfile,
  getTableQueueList,
  updateRestaurantProfile,
  updateTableQueueList,
  updateWaitCustomerReply,
} from '../services/waitlist';

const BASE = 'WAITLIST';
export const actions = {
  loadingPreference: `${BASE}_LOADING_PREFERENCE`,
  loadPreference: `${BASE}_LOAD_PREFERENCE`,
};

export const updateWaitListRestaurantProfile = (restaurantProfile) => async (dispatch, getState) => {
  const locale = getSessionItem(KEYS.locale);
  const restaurant_id = getSessionItem(KEYS.restaurantId);
  const restaurantInfo = getState().setting.restaurantInfo;
  const restaurantTimeZone = restaurantInfo.time_zone;
  let response;
  let restaurant_profile_for_waitlist = {
    ...restaurantProfile,
    time_zone: restaurantTimeZone || 'Central Time (US & Canada)',
  };

  try {
    response = await updateRestaurantProfile({ locale, restaurant_id, restaurant_profile_for_waitlist });
  } catch (e) {
    response = { success: false };
  }

  if (!response.success) return;
};

function parseWaitlistBusinessHourRange(startTime, endTime) {
  if (startTime) {
    return [
      moment(`${moment().format('YYYY/MM/DD')} ${startTime}`, 'YYYY/MM/DD HH:mm'),
      moment(`${moment().format('YYYY/MM/DD')} ${endTime}`, 'YYYY/MM/DD HH:mm'),
    ];
  } else {
    return null;
  }
}

export const loadWaitListPreference = (callback) => async (dispatch, getState) => {
  dispatch({
    type: actions.loadingPreference,
  });

  const locale = getSessionItem(KEYS.locale);
  const restaurant_id = getSessionItem(KEYS.restaurantId);
  let responseList;
  try {
    responseList = await Promise.all([
      getRestaurantProfile({ locale, restaurant_id }),
      getTableQueueList({ locale, restaurant_id }),
    ]);
  } catch (e) {
    responseList = [{ success: false }, { success: false }];
  }

  if (!responseList[0].success || !responseList[1].success) {
    return;
  }

  let restaurantProfile = responseList[0].data;
  let queueList = responseList[1].data;

  let responseParsedData = {
    restaurantProfile: {
      id: restaurantProfile.id,
      launch: {
        active: restaurantProfile.first_business_hour_enabled || false,
        range: parseWaitlistBusinessHourRange(
          restaurantProfile.first_business_hour_start_at,
          restaurantProfile.first_business_hour_end_at
        ),
      },
      dinner: {
        active: restaurantProfile.second_business_hour_enabled || false,
        range: parseWaitlistBusinessHourRange(
          restaurantProfile.second_business_hour_start_at,
          restaurantProfile.second_business_hour_end_at
        ),
      },
      notes: restaurantProfile.note,
      foreignNotes: restaurantProfile.foreign_note,
      enableGoogleWaitlist: restaurantProfile.enable_google_waitlist,
      tableSeatingEnabled: restaurantProfile.table_seating_enabled,
    },
    queueList: queueList,
  };
  callback && callback(responseParsedData);
  dispatch({
    type: actions.loadPreference,
    payload: responseParsedData,
  });
};

export const updateWaitListTableQueue = (restaurant_queues, callback) => async (dispatch, getState) => {
  const locale = getSessionItem(KEYS.locale);
  const restaurant_id = getSessionItem(KEYS.restaurantId);
  const restaurantInfo = getState().setting.restaurantInfo;
  const restaurantTimeZone = restaurantInfo.time_zone;
  let response;

  try {
    response = await updateTableQueueList({ locale, restaurant_id, restaurant_queues });
  } catch (e) {
    response = { success: false };
  }
  callback(!response.success ? null : response.data);
  if (!response.success) return;
  // callback(response.data);
};

export const updateWaitCustomerReplyFromWebPage = (key, customer_response, callback) => async (dispatch, getState) => {
  let response;
  try {
    response = await updateWaitCustomerReply({ key, customer_response });
  } catch (e) {
    response = { success: false };
  }
  callback(response.success);
  if (!response.success) return;
};
