import { queryTransactions } from '../services/payment';

const BASE = 'TRANSACTION';

export const actions = {
  loadingTransactions: `${BASE}_LOADING_TRANSACTIONS`,
  loadTransactionsSuccess: `${BASE}_LOAD_TRANSACTIONS_SUCCESS`,
  loadTransactionsFailed: `${BASE}_LOAD_TRANSACTIONS_FAILED`,

  loadingRefunds: `${BASE}_LOADING_REFUNDS`,
  loadRefundsSuccess: `${BASE}_LOAD_REFUNDS_SUCCESS`,
  loadRefundsFailed: `${BASE}_LOAD_REFUNDS_FAILED`,
};

export const fetchTransactionList = (params, callback) => async (dispatch) => {
  dispatch({
    type: actions.loadingTransactions,
  });

  let response;

  try {
    response = await queryTransactions(params);
  } catch (e) {
    response = { success: false };
  }

  if (!response.success) {
    dispatch({
      type: actions.loadTransactionsFailed,
    });
    return;
  }

  const { data, meta } = response;
  const { page = 1, page_size = 10, total = 0 } = meta || {};

  dispatch({
    type: actions.loadTransactionsSuccess,
    payload: {
      transactions: data || [],
      pagination: {
        current: page,
        pageSize: page_size,
        total,
      },
    },
  });

  typeof callback === 'function' && callback(data || []);
};
