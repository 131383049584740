import { actions as cdsPreferenceActions } from 'src/actions/cdsPreference';

const initialState = {
  isLoadingCdsPreference: false,
  isLoadCdsPreferenceSuccess: false,
  isLoadCdsPreferenceFailed: false,

  isUpdatingCdsPreference: false,
  isUpdateCdsPreferenceSuccess: false,
  isUpdateCdsPreferenceFailed: false,

  cdsPreference: {},
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case cdsPreferenceActions.loadingCdsPreference: {
      return {
        ...state,
        isLoadingCdsPreference: true,
        isLoadCdsPreferenceSuccess: false,
        isLoadCdsPreferenceFailed: false,
      };
    }

    case cdsPreferenceActions.loadCdsPreferenceSuccess: {
      return {
        ...state,
        isLoadingCdsPreference: false,
        isLoadCdsPreferenceSuccess: true,
        isLoadCdsPreferenceFailed: false,
        cdsPreference: payload,
      };
    }

    case cdsPreferenceActions.loadCdsPreferenceFailed: {
      return {
        ...state,
        isLoadingCdsPreference: false,
        isLoadCdsPreferenceSuccess: true,
        isLoadCdsPreferenceFailed: false,
        cdsPreference: {},
      };
    }

    case cdsPreferenceActions.updatingCdsPreference: {
      return {
        ...state,
        isUpdatingCdsPreference: true,
        isUpdateCdsPreferenceSuccess: false,
        isUpdateCdsPreferenceFailed: false,
      };
    }

    case cdsPreferenceActions.updateCdsPreferenceSuccess: {
      return {
        ...state,
        isUpdatingCdsPreference: false,
        isUpdateCdsPreferenceSuccess: true,
        isUpdateCdsPreferenceFailed: false,
        cdsPreference: payload,
      };
    }

    case cdsPreferenceActions.updateCdsPreferenceFailed: {
      return {
        ...state,
        isUpdatingCdsPreference: false,
        isUpdateCdsPreferenceSuccess: false,
        isUpdateCdsPreferenceFailed: true,
      };
    }

    default: {
      return state;
    }
  }
};
