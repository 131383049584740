import { DELETE, GET, POST } from '../utils/request';
import { API_PREFIX, CLIENT_ID } from '../consts';

export function queryOrders({
  search_name,
  order_sources,
  platforms,
  status,
  display_status,
  payment_status,
  refund_status,
  start_at = '',
  end_at = '',
  employee_ids,
  page = 1,
  page_size = 10,
  loyalty_types,
  last4,
  phone,
  guest_name,
  labels,
}) {
  return GET(`${API_PREFIX}/v3/orders`, {
    search_name,
    order_sources,
    platforms,
    status,
    display_status,
    payment_status,
    refund_status,
    start_at,
    end_at,
    employee_ids,
    page,
    page_size,
    loyalty_types,
    last4,
    phone,
    guest_name,
    labels,
    need_total: true,
  });
}

export function queryOrderDetail(id) {
  return GET(`${API_PREFIX}/v3/order/${id}`);
}

export function deleteOrders(ids) {
  return DELETE(`${API_PREFIX}/v3/orders?ids=${ids}`);
}

export function sendEmalReceipt({ email, payment_ids, orderId }) {
  return POST(
    `${API_PREFIX}/v3/order/${orderId}/receipt/email`,
    {
      email,
      payment_ids,
    },
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
    }
  );
}

export function sendTextReceipt({ mobile, payment_ids, orderId }) {
  return POST(
    `${API_PREFIX}/v3/order/${orderId}/receipt/sms`,
    {
      phone: mobile,
      payment_ids,
    },
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
    }
  );
}
