import {
  QR_CODE_SIZE_STAND_A5,
  QR_CODE_SIZE_STAND_5X8_INCH,
  QR_CODE_SIZE_STICKER_4X6_INCH,
  QR_CODE_SIZE_STICKER_325_INCH,
  QR_CODE_SIZE_ACRYLIC_SHEETS_4X4_INCH,
  QR_CODE_SIZE_ACRYLIC_SHEETS_250X250_INCH,
  DISTRIBUTION_MODES,
} from '../../consts';
import { fittingStringEllipsis } from '../../utils/drawLoyaltyQRCode';
import { FONT_FAMILY } from 'src/consts/fonts';

export const scanENImage = `${process.env.ASSETS_PREFIX}/assets/loyalty/scan_en.svg`;
export const scanZHImage = `${process.env.ASSETS_PREFIX}/assets/loyalty/scan_zh.svg`;
export const fullENImage = `${process.env.ASSETS_PREFIX}/assets/loyalty/full_en.svg`;
export const fullZHImage = `${process.env.ASSETS_PREFIX}/assets/loyalty/full_zh.svg`;
export const noCashBackENImage = `${process.env.ASSETS_PREFIX}/assets/loyalty/no_cashback_en.svg`;
export const noCashBackZHImage = `${process.env.ASSETS_PREFIX}/assets/loyalty/no_cashback_zh.svg`;
export const noDiscountENImage = `${process.env.ASSETS_PREFIX}/assets/loyalty/no_discount_en.svg`;
export const noDiscountZHImage = `${process.env.ASSETS_PREFIX}/assets/loyalty/no_discount_zh.svg`;
export const prepaidCardBonusENImage = `${process.env.ASSETS_PREFIX}/assets/loyalty/prepaidcard_bouns_en.svg`;
export const prepaidCardBonusZHImage = `${process.env.ASSETS_PREFIX}/assets/loyalty/prepaidcard_bouns_zh.svg`;
export const prepaidCardNoBonusENImage = `${process.env.ASSETS_PREFIX}/assets/loyalty/prepaidcard_no_bouns_en.svg`;
export const prepaidCardNoBonusZHImage = `${process.env.ASSETS_PREFIX}/assets/loyalty/prepaidcard_no_bouns_zh.svg`;
export const wifiImage = `${process.env.ASSETS_PREFIX}/assets/loyalty/wifi.svg`;

export const DEMOLOGO = `${process.env.ASSETS_PREFIX}/assets/qrcode/TESTLOGO.png`;

export const StandSize = {
  width: 420,
  height: 595,
};

export const A5StandSize = {
  width: 420,
  height: 595,
};

export const Inch_5X8_StandSize = {
  width: 360,
  height: 576,
};

export const Inch_4X6_StickerSize = {
  width: 600,
  height: 900,
};

export const CM_3X3_StickerSize = {
  width: 975,
  height: 975,
};

export const CM_4X4_AcrylicSheetsSize = {
  width: 1200,
  height: 1200,
};

export const CM_250X250_AcrylicSheetsSize = {
  width: 1200,
  height: 1200,
};

export const StickerSize = {
  width: 600,
  height: 900,
};

export const scale = 3;
export const StandQRCodeSize = 224 * scale;
export const Inch5x8StandQRCodeSize = 160 * scale;
export const StickerQRCodeSize = 290 * scale;
export const StandLogoSize = 60 * scale;
export const StickerLogoSize = 80 * scale;
export const AcrylicSheetsLogoSize = 90 * scale;

export const LoyaltyQRCodeSize = 80 * scale;
export const LoyaltyQRWrapperSize = 92 * scale;
export const Loyalty5x8InchQRWrapperSize = 100 * scale;
export const Loyaltyy5x8InchQRCodeSize = 88 * scale;
export const LoyaltyLogoSize = 30 * scale;
export const BottomLogoSize = 44 * scale;
export const WifiImageSize = 30 * scale;

export const CM3x3StickerQRCodeSize = 381 * scale;

export const CM4x4AcrylicSheetsQRCodeSize = 482 * scale;
export const CM250x250AcrylicSheetsQRCodeSize = 660 * scale;

export function drawRadialGradient({ ctx, width, height }) {
  ctx.save();
  const gradient = ctx.createRadialGradient(width * 0.5 * scale, 0, 0, width * 0.5 * scale, 0, height * scale);
  gradient.addColorStop(0, '#595966');
  gradient.addColorStop(1, '#1D1B2E');
  ctx.fillStyle = gradient;
  ctx.fillRect(0, 0, width * scale, height * scale);
  ctx.restore();
}

export function checkIsHasLoyaltyContract(restaurantInfo) {
  const { restaurant_contracts = [] } = restaurantInfo;
  return restaurant_contracts.find((_) => _.distribution_mode === DISTRIBUTION_MODES.LOYALTY);
}

export function getBottomTextWithWidth({ ctx, taskData, fontSize, maxWidth }) {
  const { restaurantInfo } = taskData;
  const { name, foreign_name } = restaurantInfo;

  const { text: nameText, contentWith: nameWidth } = fittingStringEllipsis({
    context: ctx,
    str: name,
    maxWidth,
    font: `normal ${fontSize} ${FONT_FAMILY.PROXIMA_NOVA}`,
  });
  const { text: foreignNameText, contentWith: foreignNameWidth } = fittingStringEllipsis({
    context: ctx,
    str: foreign_name,
    maxWidth,
    font: `normal ${fontSize} ${FONT_FAMILY.PROXIMA_NOVA}`,
  });
  const textMaxWidth = Math.max(nameWidth, foreignNameWidth);

  return {
    nameText,
    foreignNameText,
    textMaxWidth,
  };
}

export function getCanvas(size) {
  const canvas = document.createElement('canvas');
  let canvasSize = A5StandSize;
  switch (size) {
    case QR_CODE_SIZE_STAND_A5: {
      canvasSize = A5StandSize;
      break;
    }
    case QR_CODE_SIZE_STAND_5X8_INCH: {
      canvasSize = Inch_5X8_StandSize;
      break;
    }
    case QR_CODE_SIZE_STICKER_4X6_INCH: {
      canvasSize = Inch_4X6_StickerSize;
      break;
    }
    case QR_CODE_SIZE_STICKER_325_INCH: {
      canvasSize = CM_3X3_StickerSize;
      break;
    }
    case QR_CODE_SIZE_ACRYLIC_SHEETS_4X4_INCH: {
      canvasSize = CM_4X4_AcrylicSheetsSize;
      break;
    }
    case QR_CODE_SIZE_ACRYLIC_SHEETS_250X250_INCH: {
      canvasSize = CM_250X250_AcrylicSheetsSize;
      break;
    }

    default: {
      canvasSize = A5StandSize;
    }
  }
  const { width, height } = canvasSize;
  canvas.width = width * scale;
  canvas.height = height * scale;
  return canvas;
}
