import { actions as deviceActions } from '../actions/device';

const initialState = {
  devices: [],
  printers: [],

  isLoadingDevices: false,
  isLoadDevicesSuccess: false,
  isLoadDevicesFailed: false,

  isLoadingPrinters: false,
  isLoadPrintersSuccess: false,
  isLoadPrintersFailed: false,

  isUpdatingDevice: false,
  isUpdateDeviceSuccess: false,
  isUpdateDeviceFailed: false,

  isDeletingDevice: false,
  isDeleteDeviceSuccess: false,
  isDeleteDeviceFailed: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case deviceActions.loadingDevices: {
      return {
        ...state,
        isLoadingDevices: true,
        isLoadDevicesSuccess: false,
        isLoadDevicesFailed: false,
      };
    }

    case deviceActions.loadDevicesSuccess: {
      return {
        ...state,
        isLoadingDevices: false,
        isLoadDevicesSuccess: true,
        isLoadDevicesFailed: false,
        ...payload,
      };
    }

    case deviceActions.loadDevicesFailed: {
      return {
        ...state,
        isLoadingDevices: false,
        isLoadDevicesSuccess: false,
        isLoadDevicesFailed: true,
      };
    }

    case deviceActions.loadingPrinters: {
      return {
        ...state,
        isLoadingPrinters: true,
        isLoadPrintersSuccess: false,
        isLoadPrintersFailed: false,
      };
    }

    case deviceActions.loadPrintersSuccess: {
      return {
        ...state,
        isLoadingPrinters: false,
        isLoadPrintersSuccess: true,
        isLoadPrintersFailed: false,
        ...payload,
      };
    }

    case deviceActions.loadPrintersFailed: {
      return {
        ...state,
        isLoadingPrinters: false,
        isLoadPrintersSuccess: false,
        isLoadPrintersFailed: true,
      };
    }

    case deviceActions.updatingDevice: {
      return {
        ...state,
        isUpdatingDevice: true,
        isUpdateDeviceSuccess: false,
        isUpdateDeviceFailed: false,
      };
    }

    case deviceActions.updateDeviceSuccess: {
      return {
        ...state,
        isUpdatingDevice: false,
        isUpdateDeviceSuccess: true,
        isUpdateDeviceFailed: false,
        ...payload,
      };
    }

    case deviceActions.updateDeviceFailed: {
      return {
        ...state,
        isUpdatingDevice: false,
        isUpdateDeviceSuccess: false,
        isUpdateDeviceFailed: true,
      };
    }

    case deviceActions.deletingDevice: {
      return {
        ...state,
        isDeletingDevice: true,
        isDeleteDeviceSuccess: false,
        isDeleteDeviceFailed: false,
      };
    }

    case deviceActions.deleteDeviceSuccess: {
      return {
        ...state,
        isDeletingDevice: false,
        isDeleteDeviceSuccess: true,
        isDeleteDeviceFailed: false,
      };
    }

    case deviceActions.deleteDeviceFailed: {
      return {
        ...state,
        isDeletingDevice: false,
        isDeleteDeviceSuccess: false,
        isDeleteDeviceFailed: true,
      };
    }

    default:
      return state;
  }
};
