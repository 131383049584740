import {
  drawRadialGradient,
  getCanvas,
  DEMOLOGO,
  CM3x3StickerQRCodeSize,
  CM4x4AcrylicSheetsQRCodeSize,
  StickerLogoSize,
  scale,
  StandQRCodeSize,
  Inch5x8StandQRCodeSize,
  StickerQRCodeSize,
  StandLogoSize,
  AcrylicSheetsLogoSize,
  LoyaltyQRCodeSize,
  LoyaltyQRWrapperSize,
  Loyalty5x8InchQRWrapperSize,
  Loyaltyy5x8InchQRCodeSize,
  LoyaltyLogoSize,
  BottomLogoSize,
  WifiImageSize,
  Inch_5X8_StandSize,
  A5StandSize,
  StandSize,
  Inch_4X6_StickerSize,
  CM_3X3_StickerSize,
  CM_4X4_AcrylicSheetsSize,
  StickerSize,
  checkIsHasLoyaltyContract,
  getBottomTextWithWidth,
  scanENImage,
  scanZHImage,
  fullENImage,
  fullZHImage,
  noCashBackENImage,
  noCashBackZHImage,
  noDiscountENImage,
  noDiscountZHImage,
  prepaidCardBonusENImage,
  prepaidCardBonusZHImage,
  prepaidCardNoBonusENImage,
  prepaidCardNoBonusZHImage,
  wifiImage,
} from './DrawQRCode';
import { NORMAL_QRCODE_TYPE, MENUBROWSING_QRCODE_TYPE } from '../../consts';
import { qrCodeServiceDomain } from '../../consts/third_party';
import { getPrepaidCardMaxBonusRatio, loadImage } from '../../utils/utils';
import { fittingStringEllipsis, drawRoundRect, generateQRCode, ELLIPSIS_TEXT } from '../../utils/drawLoyaltyQRCode';
import { FONT_FAMILY } from 'src/consts/fonts';

//inch5X8
const inch5X8StandENBackgroundImageWithWifi = `${process.env.ASSETS_PREFIX}/assets/qrcode/qr_bg_inch_5x8_en_wifi.svg`;
const inch5X8StandZHBackgroundImageWithWifi = `${process.env.ASSETS_PREFIX}/assets/qrcode/qr_bg_inch_5x8_zh_wifi.svg`;
const inch5X8StandENBackgroundImage = `${process.env.ASSETS_PREFIX}/assets/qrcode/qr_bg_inch_5x8_en.svg`;
const inch5X8StandZHBackgroundImage = `${process.env.ASSETS_PREFIX}/assets/qrcode/qr_bg_inch_5x8_zh.svg`;
//inch5X8 browsing menu
const menuBrowsing_inch5X8StandENBackgroundImageWithWifi = `${process.env.ASSETS_PREFIX}/assets/qrcode/browsing_menu/qr_bg_inch_5x8_en_wifi.svg`;
const menuBrowsing_inch5X8StandZHBackgroundImageWithWifi = `${process.env.ASSETS_PREFIX}/assets/qrcode/browsing_menu/qr_bg_inch_5x8_zh_wifi.svg`;
const menuBrowsing_inch5X8StandENBackgroundImage = `${process.env.ASSETS_PREFIX}/assets/qrcode/browsing_menu/qr_bg_inch_5x8_en.svg`;
const menuBrowsing_inch5X8StandZHBackgroundImage = `${process.env.ASSETS_PREFIX}/assets/qrcode/browsing_menu/qr_bg_inch_5x8_zh.svg`;

export async function draw5X8InchStandQRCode(taskData) {
  const { restaurantInfo, size } = taskData;
  const hasLoyaltyContract = checkIsHasLoyaltyContract(restaurantInfo);
  const images = [];

  let hasLoyalty = hasLoyaltyContract;

  if (hasLoyalty) {
    let { loyaltySettings } = taskData;

    if (!loyaltySettings) {
      loyaltySettings = { cash_back_ratio: '0.00', discount_ratio: '1.00' };
    }

    const { prepaidCardSettings } = taskData;
    const { cash_back_ratio, discount_ratio } = loyaltySettings || {};
    hasLoyalty = +discount_ratio !== 1 || cash_back_ratio > 0 || !!prepaidCardSettings?.is_active;
  }

  if (hasLoyalty) {
    const canvas2 = getCanvas(size);
    const ctx2 = canvas2.getContext('2d');
    await drawLoyalty5x8InchStandQRCodeInChinese({ ctx: ctx2, taskData });
    const image2 = canvas2.toDataURL();
    images.push(image2);

    const canvas1 = getCanvas(size);
    const ctx1 = canvas1.getContext('2d');
    await drawLoyalty5x8InchStandQRCodeInEnglish({ ctx: ctx1, taskData });
    const image1 = canvas1.toDataURL();
    images.push(image1);
  } else {
    const canvas2 = getCanvas(size);
    const ctx2 = canvas2.getContext('2d');
    await drawNonLoyalty5X8InchStandQRCodeInChinese({ ctx: ctx2, taskData });
    const image2 = canvas2.toDataURL();
    images.push(image2);

    const canvas1 = getCanvas(size);
    const ctx1 = canvas1.getContext('2d');
    await drawNonLoyalty5X8InchStandQRCodeInEnglish({ ctx: ctx1, taskData });
    const image1 = canvas1.toDataURL();
    images.push(image1);
  }

  return images;
}

async function drawLoyalty5x8InchStandQRCodeInChinese({ ctx, taskData }) {
  const { width, height } = Inch_5X8_StandSize;
  drawRadialGradient({ ctx, width, height: 450 });
  await draw5x8InchPromotionContentInChinese({ ctx, taskData });
  await drawLoyalty5x8InchQRCodeCommonContent({ ctx, taskData, width, height });
}

async function drawLoyalty5x8InchStandQRCodeInEnglish({ ctx, taskData }) {
  const { width, height } = Inch_5X8_StandSize;
  drawRadialGradient({ ctx, width, height: 450 });
  await draw5x8InchStandPromotionContentInEnglish({ ctx, taskData });
  await drawLoyalty5x8InchQRCodeCommonContent({ ctx, taskData, width, height: 450 });
}

async function drawNonLoyalty5X8InchStandQRCodeInChinese({ ctx, taskData }) {
  const { restaurantInfo, tableName, code, isQuickService, qrCodeType } = taskData;
  const { wifi_ssid, wifi_password, foreign_name, name, logo_url } = restaurantInfo;
  const { width, height } = Inch_5X8_StandSize;
  let image;
  if (qrCodeType === MENUBROWSING_QRCODE_TYPE) {
    if (wifi_ssid) {
      image = await loadImage(menuBrowsing_inch5X8StandZHBackgroundImageWithWifi);
    } else {
      image = await loadImage(menuBrowsing_inch5X8StandZHBackgroundImage);
    }
  } else {
    if (wifi_ssid) {
      image = await loadImage(inch5X8StandZHBackgroundImageWithWifi);
    } else {
      image = await loadImage(inch5X8StandZHBackgroundImage);
    }
  }

  ctx.drawImage(image, 0, 0, width * scale, height * scale);

  // draw restaurant foreign name
  const font = `normal ${16 * scale}px ${FONT_FAMILY.PROXIMA_NOVA}`;
  const { text } = fittingStringEllipsis({
    context: ctx,
    str: foreign_name || name,
    maxWidth: (width - 34) * scale,
    font,
  });
  ctx.save();
  ctx.fillStyle = '#ffffff';
  ctx.font = font;
  ctx.textAlign = 'center';
  if (qrCodeType === MENUBROWSING_QRCODE_TYPE) {
    ctx.fillText(text, width * 0.5 * scale, 195 * scale);
  } else {
    ctx.fillText(text, width * 0.5 * scale, 150 * scale);
  }

  ctx.restore();

  if (qrCodeType === NORMAL_QRCODE_TYPE && !isQuickService) {
    // draw table name
    ctx.save();
    ctx.fillStyle = '#ffffff';
    ctx.font = `bold normal ${28 * scale}px ${FONT_FAMILY.PROXIMA_NOVA}`;
    ctx.textAlign = 'center';
    ctx.fillText(tableName, width * 0.5 * scale, 204 * scale);
    ctx.restore();
  }

  // draw qr code
  const qrCodeLink = `${qrCodeServiceDomain}?code=${code}`;
  const qrCodeImage = await generateQRCode(qrCodeLink, Inch5x8StandQRCodeSize);
  let y = 230 * scale;

  if (qrCodeImage) {
    const x = (width * scale - Inch5x8StandQRCodeSize) / 2;
    ctx.drawImage(qrCodeImage, x, y, Inch5x8StandQRCodeSize, Inch5x8StandQRCodeSize);
  }

  if (logo_url) {
    const logoImage = await loadImage(logo_url);
    if (logoImage) {
      const x = (width * scale - StandLogoSize) / 2;
      y += (Inch5x8StandQRCodeSize - StandLogoSize) / 2;
      ctx.drawImage(logoImage, x, y, StandLogoSize, StandLogoSize);
    }
  }

  if (!wifi_ssid) return;

  ctx.save();
  ctx.font = `normal ${12 * 3}px/${14 * 3}px ${FONT_FAMILY.PROXIMA_NOVA}`;
  ctx.fillStyle = '#1D1B2E';
  ctx.fillText(`Wi-Fi: ${wifi_ssid}`, 50 * scale, 477 * scale, 103 * scale);
  ctx.fillText(`Password: ${wifi_password || ''}`, 50 * scale, 492 * scale, 180 * scale);
  ctx.restore();
}

async function drawNonLoyalty5X8InchStandQRCodeInEnglish({ ctx, taskData }) {
  const { restaurantInfo, tableName, code, isQuickService, qrCodeType } = taskData;
  const { wifi_ssid, wifi_password, name, logo_url } = restaurantInfo;
  const { width, height } = Inch_5X8_StandSize;

  let image;
  if (qrCodeType === MENUBROWSING_QRCODE_TYPE) {
    if (wifi_ssid) {
      image = await loadImage(menuBrowsing_inch5X8StandENBackgroundImageWithWifi);
    } else {
      image = await loadImage(menuBrowsing_inch5X8StandENBackgroundImage);
    }
  } else {
    if (wifi_ssid) {
      image = await loadImage(inch5X8StandENBackgroundImageWithWifi);
    } else {
      image = await loadImage(inch5X8StandENBackgroundImage);
    }
  }

  ctx.drawImage(image, 0, 0, width * scale, height * scale);

  // draw restaurant name
  const font = `normal ${16 * scale}px ${FONT_FAMILY.PROXIMA_NOVA}`;
  const { text } = fittingStringEllipsis({
    context: ctx,
    str: name || '',
    maxWidth: (width - 34) * scale,
    font,
  });
  ctx.save();
  ctx.fillStyle = '#ffffff';
  ctx.font = font;
  ctx.textAlign = 'center';
  ctx.fillText(text, width * 0.5 * scale, 150 * scale);
  ctx.restore();

  if (qrCodeType === NORMAL_QRCODE_TYPE && !isQuickService) {
    // draw table name
    ctx.save();
    ctx.fillStyle = '#ffffff';
    ctx.font = `bold normal ${28 * scale}px ${FONT_FAMILY.PROXIMA_NOVA}`;
    ctx.textAlign = 'center';
    ctx.fillText(tableName, width * 0.5 * scale, 204 * scale);
    ctx.restore();
  }

  // draw qr code
  const qrCodeLink = `${qrCodeServiceDomain}?code=${code}`;
  const qrCodeImage = await generateQRCode(qrCodeLink, Inch5x8StandQRCodeSize);
  let y = 230 * scale;

  if (qrCodeImage) {
    const x = (width * scale - Inch5x8StandQRCodeSize) / 2;
    ctx.drawImage(qrCodeImage, x, y, Inch5x8StandQRCodeSize, Inch5x8StandQRCodeSize);
  }

  if (logo_url) {
    const logoImage = await loadImage(logo_url);
    if (logoImage) {
      const x = (width * scale - StandLogoSize) / 2;
      y += (Inch5x8StandQRCodeSize - StandLogoSize) / 2;
      ctx.drawImage(logoImage, x, y, StandLogoSize, StandLogoSize);
    }
  }

  if (!wifi_ssid) return;

  ctx.save();
  ctx.font = `normal ${12 * 3}px/${14 * 3}px ${FONT_FAMILY.PROXIMA_NOVA}`;
  ctx.fillStyle = '#1D1B2E';
  ctx.fillText(`Wi-Fi: ${wifi_ssid}`, 50 * scale, 477 * scale, 103 * scale);
  ctx.fillText(`Password: ${wifi_password || ''}`, 50 * scale, 492 * scale, 180 * scale);
  ctx.restore();
}

async function drawLoyalty5x8InchQRCodeCommonContent({ ctx, taskData, width, height }) {
  await drawLoyalty5x8InchQRCode({ ctx, taskData, width });
  await draw5x8InchBottomContent({ ctx, taskData, width, height });
}

async function drawLoyalty5x8InchQRCode({ ctx, taskData, width }) {
  const { restaurantInfo, tableName, code, isQuickService, qrCodeType } = taskData;
  const { logo_url } = restaurantInfo;

  if (qrCodeType === NORMAL_QRCODE_TYPE && !isQuickService) {
    // draw table name
    ctx.save();
    ctx.fillStyle = '#ffffff';
    ctx.textAlign = 'center';
    const fontSize = `${32 * scale}px`;
    ctx.font = `bold normal ${fontSize} ${FONT_FAMILY.PROXIMA_NOVA}`;
    ctx.fillText(tableName, width * 0.5 * scale, 305 * scale);
    ctx.restore();
  }

  const x = (width * scale - Loyalty5x8InchQRWrapperSize) / 2;
  drawRoundRect({
    ctx,
    x,
    y: 320 * scale,
    width: Loyalty5x8InchQRWrapperSize,
    height: Loyalty5x8InchQRWrapperSize,
    radius: 8 * scale,
  });

  // draw qrcode
  const qrCodeLink = `${qrCodeServiceDomain}?code=${code}`;
  const qrCodeImage = await generateQRCode(qrCodeLink, Loyaltyy5x8InchQRCodeSize);

  if (qrCodeImage) {
    const x = (width * scale - Loyaltyy5x8InchQRCodeSize) / 2;
    const y = 320 * scale + (Loyalty5x8InchQRWrapperSize - Loyaltyy5x8InchQRCodeSize) / 2;
    ctx.drawImage(qrCodeImage, x, y, Loyaltyy5x8InchQRCodeSize, Loyaltyy5x8InchQRCodeSize);
  }

  if (logo_url) {
    const logoImage = await loadImage(logo_url);
    if (logoImage) {
      const x = (width * scale - LoyaltyLogoSize) / 2;
      const y = 320 * scale + (Loyalty5x8InchQRWrapperSize - LoyaltyLogoSize) / 2;
      ctx.drawImage(logoImage, x, y, LoyaltyLogoSize, LoyaltyLogoSize);
    }
  }
}

async function draw5x8InchBottomContent({ ctx, taskData, width }) {
  const fontSize = `${12 * scale}px/${14 * scale}px`;
  const { restaurantInfo } = taskData;
  const { wifi_ssid } = restaurantInfo;

  ctx.save();
  ctx.fillStyle = '#ffffff';
  ctx.fillRect(0, 450 * scale, width * scale, 54 * scale);
  ctx.restore();

  if (wifi_ssid) {
    await draw5x8InchBottomContentWithWifiInfo({ ctx, fontSize, taskData, width });
    return;
  }

  await draw5x8InchBottomContentWithoutWifiInfo({ ctx, fontSize, taskData, width });
}

async function draw5x8InchBottomContentWithWifiInfo({ ctx, taskData, width, fontSize }) {
  const wifiImg = await loadImage(wifiImage);
  let x = 10 * scale;
  ctx.drawImage(wifiImg, x, 460 * scale, WifiImageSize, WifiImageSize);

  const { restaurantInfo } = taskData;
  const { logo_url, wifi_ssid, wifi_password } = restaurantInfo;

  // wifi info
  ctx.save();
  ctx.font = `normal ${fontSize} ${FONT_FAMILY.PROXIMA_NOVA}`;
  ctx.fillStyle = '#1D1B2E';
  x += WifiImageSize + 4 * scale;
  const wifiTextMaxWidth = width * 0.5 * scale - WifiImageSize - 4 * scale;
  ctx.fillText(`Wi-Fi: ${wifi_ssid}`, x, 474 * scale, wifiTextMaxWidth);
  ctx.fillText(`Password: ${wifi_password || ''}`, x, 488 * scale, wifiTextMaxWidth);
  ctx.restore();

  // restaurant names
  let maxWidth = width * 0.5 * scale - 20 * scale;

  if (logo_url) {
    maxWidth -= BottomLogoSize + 4 * scale;
  }

  const { nameText, foreignNameText, textMaxWidth } = getBottomTextWithWidth({ ctx, taskData, fontSize, maxWidth });
  const textX = width * scale - 10 * scale - textMaxWidth;

  if (logo_url) {
    const logoImage = await loadImage(logo_url);

    if (logoImage) {
      const logoX = textX - BottomLogoSize - 4 * scale;
      ctx.drawImage(logoImage, logoX, 454 * scale, BottomLogoSize, BottomLogoSize);
    }
  }

  ctx.save();
  ctx.font = `normal ${fontSize} ${FONT_FAMILY.PROXIMA_NOVA}`;
  ctx.fillStyle = '#1D1B2E';
  ctx.fillText(nameText, textX, 474 * scale);
  ctx.restore();

  ctx.save();
  ctx.font = `normal ${fontSize} ${FONT_FAMILY.PROXIMA_NOVA}`;
  ctx.fillStyle = '#1D1B2E';
  ctx.fillText(foreignNameText, textX, 488 * scale);
  ctx.restore();
}

async function draw5x8InchBottomContentWithoutWifiInfo({ ctx, taskData, width, fontSize }) {
  const { restaurantInfo } = taskData;
  const { logo_url } = restaurantInfo;

  let maxWidth = width * scale - 10 * 2 * scale;
  const logoContentWidth = BottomLogoSize + 4 * scale;
  if (logo_url) maxWidth -= logoContentWidth;
  const { nameText, foreignNameText, textMaxWidth } = getBottomTextWithWidth({ ctx, taskData, fontSize, maxWidth });

  let fullWidth = textMaxWidth;
  if (logo_url) fullWidth += logoContentWidth;
  let x = (width * scale - fullWidth) / 2;

  if (logo_url) {
    const logoImage = await loadImage(logo_url);
    if (logoImage) {
      ctx.drawImage(logoImage, x, 454 * scale, BottomLogoSize, BottomLogoSize);

      x += logoContentWidth;
    }
  }

  ctx.save();
  ctx.font = `normal ${fontSize} ${FONT_FAMILY.PROXIMA_NOVA}`;
  ctx.fillStyle = '#1D1B2E';
  ctx.fillText(nameText, x, 474 * scale);
  ctx.restore();

  ctx.save();
  ctx.font = `normal ${fontSize} ${FONT_FAMILY.PROXIMA_NOVA}`;
  ctx.fillStyle = '#1D1B2E';
  ctx.fillText(foreignNameText, x, 488 * scale);
  ctx.restore();
}

async function draw5x8InchPromotionContentInChinese({ ctx, taskData }) {
  const { loyaltySettings, prepaidCardSettings } = taskData;
  let { cash_back_ratio = 0, discount_ratio = 1 } = loyaltySettings || {};
  cash_back_ratio = parseInt(cash_back_ratio * 100);
  const discount = discount_ratio * 10;
  let hasDiscount = discount !== 10;
  const scanTextImage = await loadImage(scanZHImage);
  ctx.drawImage(scanTextImage, 50 * scale, 26 * scale, 260 * scale, 43 * scale);

  const discountFontSize = `${38 * scale}px`;
  const cashBackFontSize = `${36 * scale}px`;

  if (hasDiscount && cash_back_ratio > 0) {
    const fullTextImage = await loadImage(fullZHImage);
    ctx.drawImage(fullTextImage, 50 * scale, 86 * scale, 260 * scale, 165 * scale);
    ctx.save();
    ctx.fillStyle = '#FBDDB1';
    ctx.textAlign = 'center';
    ctx.font = `bold normal ${discountFontSize} 'Avenir Next Condensed'`;
    ctx.fillText(String(discount), 238 * scale, 180 * scale, 46 * scale);
    ctx.font = `bold normal ${cashBackFontSize} 'Avenir Next Condensed'`;
    ctx.fillText(`${cash_back_ratio}%`, 197 * scale, (180 + 48) * scale, 56 * scale);
    ctx.restore();
    return;
  }

  // no cashback
  if (hasDiscount && cash_back_ratio <= 0) {
    const noCashBackImage = await loadImage(noCashBackZHImage);
    ctx.drawImage(noCashBackImage, 50 * scale, 86 * scale, 260 * scale, 112 * scale);
    ctx.save();
    ctx.textAlign = 'center';
    ctx.fillStyle = '#FBDDB1';
    ctx.font = `bold normal ${discountFontSize} 'Avenir Next Condensed'`;
    ctx.fillText(String(discount), 238 * scale, 178 * scale, 45 * scale);
    ctx.restore();
    return;
  }

  // no discount
  if (!hasDiscount && cash_back_ratio > 0) {
    const noDiscountImage = await loadImage(noDiscountZHImage);
    ctx.drawImage(noDiscountImage, 50 * scale, 86 * scale, 260 * scale, 112 * scale);
    ctx.save();
    ctx.fillStyle = '#FBDDB1';
    ctx.textAlign = 'center';
    ctx.font = `bold normal ${cashBackFontSize} 'Avenir Next Condensed'`;
    ctx.fillText(`${cash_back_ratio}%`, 198 * scale, 177 * scale, 48 * scale);
    ctx.restore();
    return;
  }

  let maxRatio = getPrepaidCardMaxBonusRatio(prepaidCardSettings);
  if (maxRatio >= 0.01) {
    const bonusImage = await loadImage(prepaidCardBonusZHImage);
    ctx.drawImage(bonusImage, 50 * scale, 86 * scale, 260 * scale, 112 * scale);
    ctx.save();
    ctx.textAlign = 'center';
    ctx.fillStyle = '#FBDDB1';
    ctx.font = `bold normal 125px "Avenir Next Condensed"`;
    ctx.fillText(`${Math.floor(maxRatio * 100)}%`, 270 * scale, 180 * scale, 55 * scale);
    ctx.restore();
    return;
  }

  const noBonusImage = await loadImage(prepaidCardNoBonusZHImage);
  ctx.drawImage(noBonusImage, 50 * scale, 86 * scale, 260 * scale, 112 * scale);
}

async function draw5x8InchStandPromotionContentInEnglish({ ctx, taskData }) {
  const { loyaltySettings, prepaidCardSettings } = taskData;
  let { cash_back_ratio = 0, discount_ratio = 1 } = loyaltySettings || {};
  let hasDiscount = +discount_ratio !== 1;
  cash_back_ratio = parseInt(cash_back_ratio * 100);
  let discount = parseInt(100 - discount_ratio * 100);
  const scanTextImage = await loadImage(scanENImage);
  ctx.drawImage(scanTextImage, 51 * scale, 50 * scale, 260 * scale, 23 * scale);
  const discountFontSize = `${42 * scale}px`;
  const cashBackFontSize = `${20 * scale}px`;

  if (hasDiscount && cash_back_ratio > 0) {
    const fullTextImage = await loadImage(fullENImage);
    ctx.drawImage(fullTextImage, 50 * scale, 92 * scale, 259 * scale, 153 * scale);
    ctx.save();
    ctx.textAlign = 'center';
    ctx.fillStyle = '#FBDDB1';
    ctx.font = `bold normal ${discountFontSize} "Avenir Next Condensed"`;
    ctx.fillText(`${discount}%`, 98 * scale, 190 * scale, 74 * scale);
    ctx.font = `bold normal ${cashBackFontSize} "Avenir Next Condensed"`;
    ctx.fillText(`${cash_back_ratio}%`, 76 * scale, 225 * scale, 35 * scale);
    ctx.restore();
    return;
  }

  // no cashback
  if (hasDiscount && cash_back_ratio <= 0) {
    const noCashBackImage = await loadImage(noCashBackENImage);
    ctx.drawImage(noCashBackImage, 50 * scale, 92 * scale, 259 * scale, 124 * scale);
    ctx.save();
    ctx.textAlign = 'center';
    ctx.fillStyle = '#FBDDB1';
    ctx.font = `bold normal ${discountFontSize} "Avenir Next Condensed"`;
    ctx.fillText(`${discount}%`, 98 * scale, 190 * scale, 74 * scale);
    ctx.restore();
    return;
  }

  // no discount
  if (!hasDiscount && cash_back_ratio > 0) {
    const noDiscountImage = await loadImage(noDiscountENImage);
    ctx.drawImage(noDiscountImage, 50 * scale, 92 * scale, 259 * scale, 117 * scale);
    ctx.save();
    ctx.textAlign = 'center';
    ctx.fillStyle = '#FBDDB1';
    ctx.font = `bold normal ${cashBackFontSize} "Avenir Next Condensed"`;
    ctx.fillText(`${cash_back_ratio}%`, 76 * scale, 180 * scale, 35 * scale);
    ctx.restore();
    return;
  }

  let maxRatio = Math.floor(getPrepaidCardMaxBonusRatio(prepaidCardSettings) * 100);

  if (maxRatio >= 1) {
    const bonusImage = await loadImage(prepaidCardBonusENImage);
    ctx.drawImage(bonusImage, 50 * scale, 92 * scale, 259 * scale, 117 * scale);
    ctx.save();
    ctx.textAlign = 'center';
    ctx.fillStyle = '#FBDDB1';
    ctx.font = `bold normal 80px/80px "Avenir Next Condensed"`;
    ctx.fillText(`${maxRatio}%`, 212 * scale, 184 * scale, 45 * scale);
    ctx.restore();
    return;
  }

  const noBonusImage = await loadImage(prepaidCardNoBonusENImage);
  ctx.drawImage(noBonusImage, 50 * scale, 92 * scale, 259 * scale, 117 * scale);
}
