import { queryReportPreference, updateReportPreference } from 'src/services/reportPreference';

const BASE = 'REPORT_PREFERENCE';

export const actions = {
  loadingReportPreference: `${BASE}_LOADING_PREFERENCE`,
  loadReportPreferenceSuccess: `${BASE}_LOAD_PREFERENCE_SUCCESS`,
  loadReportPreferenceFailed: `${BASE}_LOAD_PREFERENCE_FAILED`,

  updatingReportPreference: `${BASE}_UPDATING_PREFERENCE`,
  updateReportPreferenceSuccess: `${BASE}_UPDATE_PREFERENCE_SUCCESS`,
  updateReportPreferenceFailed: `${BASE}_UPDATE_PREFERENCE_FAILED`,
};

export const fetchReportPreference = (refresh, callback) => async (dispatch, getState) => {
  const { isLoadReportPreference, reportPreference: existedPreference } = getState().reportPreference;
  if (!refresh && isLoadReportPreference) {
    typeof callback === 'function' && callback(existedPreference);
    return;
  }

  dispatch({
    type: actions.loadingReportPreference,
  });

  let response;

  try {
    response = await queryReportPreference();
  } catch (e) {
    response = { success: false };
  }

  if (!response.success) {
    dispatch({
      type: actions.loadReportPreferenceFailed,
    });
    return;
  }

  const reportPreference = response.data || {};

  dispatch({
    type: actions.loadReportPreferenceSuccess,
    payload: reportPreference,
  });

  typeof callback === 'function' && callback(reportPreference);
};

export const doUpdateReportPreference = (data, callback) => async (dispatch) => {
  dispatch({
    type: actions.updatingReportPreference,
  });

  let response;

  try {
    response = await updateReportPreference(data);
  } catch (e) {
    response = { success: false };
  }

  typeof callback === 'function' && callback(response);

  if (!response.success) {
    dispatch({
      type: actions.updateReportPreferenceFailed,
    });
    return;
  }

  dispatch({
    type: actions.updateReportPreferenceSuccess,
    payload: data,
  });
};
