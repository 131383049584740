import {
  createSalesCategory,
  deleteSalesCategory,
  querySalesCategoryList,
  updateSalesCategory,
} from '../services/salesCategory';

const BASE = 'SALESCATEGORY';

export const actions = {
  loadingSalesCategorys: `${BASE}_LOADING_SALESCATEGORY`,
  loadSalesCategorySuccess: `${BASE}_LOAD_SALESCATEGORY_SUCCESS`,
  loadSalesCategoryFailed: `${BASE}_LOAD_SALESCATEGORY_FAILED`,

  creatingSalesCategory: `${BASE}_CREATING_SALESCATEGORY`,
  createSalesCategorySuccess: `${BASE}_CREATE_SALESCATEGORY_SUCCESS`,
  createSalesCategoryFailed: `${BASE}_CREATE_SALESCATEGORY_FAILED`,

  updatingSalesCategory: `${BASE}_UPDATING_SALESCATEGORY`,
  updateSalesCategorySuccess: `${BASE}_UPDATE_SALESCATEGORY_SUCCESS`,
  updateSalesCategoryFailed: `${BASE}_UPDATE_SALESCATEGORY_FAILED`,

  deletingSalesCategory: `${BASE}_DELETING_SALESCATEGORY`,
  deleteSalesCategorySuccess: `${BASE}_DELETE_SALESCATEGORY_SUCCESS`,
  deleteSalesCategoryFailed: `${BASE}_DELETE_SALESCATEGORY_FAILED`,
};

export const fetchSalesCategoryList =
  ({ restaurantId }) =>
  async (dispatch) => {
    dispatch({
      type: actions.loadingSalesCategorys,
    });

    let response;

    try {
      response = await querySalesCategoryList({ restaurantId });
    } catch (e) {
      response = { success: false };
    }

    if (!response.success) {
      dispatch({
        type: actions.loadSalesCategoryFailed,
      });
      return;
    }

    dispatch({
      type: actions.loadSalesCategorySuccess,
      payload: { salesCategorys: response.data },
    });
  };

export const doCreateSalesCategory =
  ({ restaurantId, ...data }, callback) =>
  async (dispatch) => {
    dispatch({
      type: actions.creatingSalesCategory,
    });

    try {
      const response = await createSalesCategory({ restaurantId, ...data });

      if (!response.success) {
        dispatch({
          type: actions.createSalesCategoryFailed,
        });
        return;
      }

      dispatch({
        type: actions.createSalesCategorySuccess,
      });

      dispatch(fetchSalesCategoryList({ restaurantId }));
      typeof callback === 'function' && callback();
    } catch (e) {
      dispatch({
        type: actions.createSalesCategoryFailed,
      });
    }
  };

export const doUpdateSalesCategory =
  ({ restaurantId, ...data }, callback) =>
  async (dispatch) => {
    dispatch({
      type: actions.updatingSalesCategory,
    });

    let response;

    try {
      response = await updateSalesCategory({ restaurantId, ...data });
    } catch (e) {
      response = { success: false };
    }

    if (!response.success) {
      dispatch({
        type: actions.updateSalesCategoryFailed,
      });
      return;
    }

    dispatch({
      type: actions.updateSalesCategorySuccess,
    });

    dispatch(fetchSalesCategoryList({ restaurantId }));
    typeof callback === 'function' && callback(response);
  };

export const doDeleteSalesCategory =
  ({ id, restaurantId }) =>
  async (dispatch) => {
    const response = await deleteSalesCategory({ id, restaurantId });

    if (!response.success) return;

    dispatch(fetchSalesCategoryList({ restaurantId }));
  };
