import { queryJobTasks, queryTaskDetail, revokeJobTask } from '../services/task';

const BASE = 'TASK';

export const actions = {
  loadingTasks: `${BASE}_LOADING_TASKS`,
  loadTasksSuccess: `${BASE}_LOAD_TASK_SUCCESS`,
  loadTasksFailed: `${BASE}_LOAD_TASK_FAILED`,

  retryingTask: `${BASE}_RETRYING_TASK`,
  retryTaskSuccess: `${BASE}_RETRY_TASK_SUCCESS`,
  retryTaskFailed: `${BASE}_RETRY_TASK_FAILED`,
};

export const fetchTasks = (params) => async (dispatch) => {
  dispatch({
    type: actions.loadingTasks,
  });

  let response;

  try {
    response = await queryJobTasks(params);
  } catch (e) {
    response = { success: false };
  }

  if (!response.success) {
    dispatch({
      type: actions.loadTasksFailed,
    });
    return;
  }

  const { data, meta } = response;
  const { page = 1, page_size = 10, total = 0 } = meta || {};

  dispatch({
    type: actions.loadTasksSuccess,
    payload: {
      tasks: data,
      pagination: {
        current: page,
        pageSize: page_size,
        total,
      },
    },
  });
};

export const fetchTaskItem = (params) => async (dispatch) => {
  dispatch({
    type: actions.loadingTasks,
  });

  let response;

  try {
    response = await queryTaskDetail(params);
  } catch (e) {
    response = { success: false };
  }

  if (!response.success) {
    dispatch({
      type: actions.loadTasksFailed,
    });
    return;
  }

  dispatch({
    type: actions.loadTasksSuccess,
    payload: {
      tasks: [response.data],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 1,
      },
    },
  });
};

export const doRetryTask =
  ({ task_id, task_type, authorization }, callback) =>
  async (dispatch) => {
    dispatch({
      type: actions.retryingTask,
    });

    try {
      const response = await revokeJobTask({ task_id, task_type, authorization });
      if (!response.success) {
        dispatch({
          type: actions.retryTaskFailed,
        });
        return;
      }

      dispatch({
        type: actions.retryTaskSuccess,
      });

      typeof callback === 'function' && callback();
    } catch (e) {
      dispatch({
        type: actions.retryTaskFailed,
      });
    }
  };
