import { actions as permissionActions } from '../actions/permission';

const initialState = {
  loadingSystemPermissions: false,
  loadSystemPermissionsSuccess: false,

  permissionTree: [],
  defaultCheckedKeys: [],
  permissionMapping: null,
  permissionCodeToIdMapping: {},
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case permissionActions.loadingSystemPermissions: {
      return {
        loadingSystemPermissions: true,
        loadSystemPermissionsSuccess: false,
      };
    }

    case permissionActions.loadSystemPermissionsSuccess: {
      return {
        loadingSystemPermissions: false,
        loadSystemPermissionsSuccess: true,
        ...payload,
      };
    }

    case permissionActions.loadingSystemPermissionsFailed: {
      return {
        ...state,
        loadingSystemPermissions: false,
        loadingRoleDetailSuccess: false,
      };
    }

    default:
      return state;
  }
};
