import { actions as menuActions } from '../actions/menu';

const initialState = {
  customizedProperties: [],
  allMenus: [],
  taxCodes: [],
  chowbusTaxCodes: [],
  serviceMenuDataMapping: {},
  serviceMenuDataDirtyStatus: {},

  isLoadingDishMenus: false,
  isLoadDishMenusSuccess: false,
  isLoadDishMenusFailed: false,

  isLoadingCustomizations: false,
  isLoadCustomizationsSuccess: false,
  isLoadCustomizationsFailed: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case menuActions.loadingContractMeals: {
      return {
        ...state,
        isLoadingDishMenus: true,
        isLoadDishMenusSuccess: false,
        isLoadDishMenusFailed: false,
      };
    }

    case menuActions.loadContractMealsSuccess: {
      return {
        ...state,
        isLoadingDishMenus: false,
        isLoadDishMenusSuccess: true,
        isLoadDishMenusFailed: false,
        ...payload,
      };
    }

    case menuActions.loadContractMealsFailed: {
      return {
        ...state,
        isLoadingDishMenus: false,
        isLoadDishMenusSuccess: false,
        isLoadDishMenusFailed: true,
      };
    }

    case menuActions.updateServiceMenuDataMapping: {
      return {
        ...state,
        serviceMenuDataMapping: payload,
      };
    }

    case menuActions.loadingCustomizationProperties: {
      return {
        ...state,
        isLoadingCustomizations: true,
        isLoadCustomizationsSuccess: false,
        isLoadCustomizationsFailed: false,
      };
    }

    case menuActions.loadCustomizationPropertiesSuccess: {
      return {
        ...state,
        isLoadingCustomizations: false,
        isLoadCustomizationsSuccess: true,
        isLoadCustomizationsFailed: false,
        customizedProperties: payload,
      };
    }

    case menuActions.loadCustomizationPropertiesFailed: {
      return {
        ...state,
        isLoadingCustomizations: false,
        isLoadCustomizationsSuccess: false,
        isLoadCustomizationsFailed: true,
      };
    }

    case menuActions.updateCustomizedProperties: {
      return {
        ...state,
        customizedProperties: payload,
      };
    }

    case menuActions.updateAllMenus: {
      return {
        ...state,
        allMenus: payload,
      };
    }

    case menuActions.updateServiceMenuDataDirtyStatus: {
      return {
        ...state,
        serviceMenuDataDirtyStatus: payload,
      };
    }

    case menuActions.updateTaxCodes: {
      return {
        ...state,
        ...payload,
      };
    }
    default:
      return state;
  }
};
