import { GET, POST } from '../utils/request';
import { API_PREFIX } from '../consts';

export function createPaymentRefund(payload) {
  return POST(`${API_PREFIX}/v2/payments/refunds`, {
    refund_requests: payload,
  });
}

export function queryTransactions({
  search_name,
  payment_type,
  payment_status,
  refund_status,
  start_at = '',
  end_at = '',
  page = 1,
  page_size = 10,
  payment_status_expand,
}) {
  return GET(`${API_PREFIX}/v1/payments/transaction`, {
    search_name,
    payment_type,
    payment_status,
    refund_status,
    start_at,
    end_at,
    page,
    page_size,
    payment_status_expand,
    need_total: true,
  });
}

export function queryRefundRecords({ order_id, payment_id, start_at = '', end_at = '', page, page_size, page_status }) {
  return GET(
    `${API_PREFIX}/v1/payments/refunds`,
    {
      order_id,
      payment_id,
      start_at,
      end_at,
      page,
      page_size,
      page_status,
    },
    {
      silence: true,
    }
  );
}

export function getPaymentSignature(id) {
  return GET(`${API_PREFIX}/v1/signature/payment/${id}`);
}

export async function refundLoyaltyPayments(payload) {
  return POST(`${API_PREFIX}/v2/payments/refunds`, {
    refund_requests: payload,
  });
}
