const BASE = 'MEAL_PROTOTYPE';

export const actions = {
  updateProperties: `${BASE}_UPDATE_PROPERTIES`,
  resetData: `${BASE}_RESET_DATA`,
};

export const updateMealPrototypeProperties = (payload) => (dispatch) => {
  dispatch({
    type: actions.updateProperties,
    payload,
  });
};

export const resetMealPrototypeProperties = () => (dispatch) => {
  dispatch({ type: actions.resetData });
};
