import { actions as giftActions } from '../actions/giftcard';

const initialState = {
  isLoadingGiftCards: false,
  isLoadingGiftCardsSuccess: false,
  isLoadingGiftCardsFailed: false,

  isLoadingGiftCardDetail: false,
  isLoadingGiftCardDetailSuccess: false,
  isLoadingGiftCardDetailFailed: false,

  giftCards: [],
  pagination: { current: 1, pageSize: 10, total: 0 },

  isLoadingGiftCardTransactionList: false,
  isLoadingGiftCardTransactionListSuccess: false,
  isLoadingGiftCardTransactionListFailed: false,
  transactions: [],
  transactionPagination: { current: 1, pageSize: 10, total: 0 },

  isLoadingGiftCardTopups: false,
  isLoadingGiftCardTopupsSuccess: false,
  isLoadingGiftCardTopupsFailed: false,
  giftCardTopups: [],
  giftCardTopupsPagination: { current: 1, pageSize: 10, total: 0 },
};

const giftCardReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case giftActions.loadingGiftCards: {
      return {
        ...state,
        isLoadingGiftCards: true,
        isLoadingGiftCardsSuccess: false,
        isLoadingGiftCardsFailed: false,
      };
    }

    case giftActions.loadGiftCardsSuccess: {
      return {
        ...state,
        isLoadingGiftCards: false,
        isLoadingGiftCardsSuccess: true,
        isLoadingGiftCardsFailed: false,
        ...payload,
      };
    }

    case giftActions.loadGiftCardsFailed: {
      return {
        ...state,
        isLoadingGiftCards: false,
        isLoadingGiftCardsSuccess: false,
        isLoadingGiftCardsFailed: true,
      };
    }

    //
    case giftActions.loadingGiftCardTranscationList: {
      return {
        ...state,
        isLoadingGiftCardTransactionList: true,
        isLoadingGiftCardTransactionListSuccess: false,
        isLoadingGiftCardTransactionListFailed: false,
      };
    }

    case giftActions.loadGiftCardTranscationListSuccess: {
      return {
        ...state,
        isLoadingGiftCardTransactionList: false,
        isLoadingGiftCardTransactionListSuccess: true,
        isLoadingGiftCardTransactionListFailed: false,
        ...payload,
      };
    }

    case giftActions.loadGiftCardTranscationListFailed: {
      return {
        ...state,
        isLoadingGiftCardTransactionList: false,
        isLoadingGiftCardTransactionListSuccess: false,
        isLoadingGiftCardTransactionListFailed: true,
      };
    }

    case giftActions.updateGiftCard: {
      return {
        ...state,
        giftCards: payload,
      };
    }

    //
    case giftActions.loadingGiftCardTopups: {
      return {
        ...state,
        isLoadingGiftCardTopups: true,
        isLoadingGiftCardTopupsSuccess: false,
        isLoadingGiftCardTopupsFailed: false,
      };
    }

    case giftActions.loadGiftCardTopupsSuccess: {
      return {
        ...state,
        isLoadingGiftCardTopups: false,
        isLoadingGiftCardTopupsSuccess: true,
        isLoadingGiftCardTopupsFailed: false,
        ...payload,
      };
    }

    case giftActions.loadGiftCardTopupsFailed: {
      return {
        ...state,
        isLoadingGiftCardTopups: false,
        isLoadingGiftCardTopupsSuccess: false,
        isLoadingGiftCardTopupsFailed: true,
      };
    }

    default:
      return state;
  }
};

export default giftCardReducer;
