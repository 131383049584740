import { batchUpdateFees, createFee, deleteFee, queryFeeList, updateFee } from '../services/fee';

const BASE = 'fee';

export const actions = {
  loadingFees: `${BASE}_LOADING_FEES`,
  loadFeesSuccess: `${BASE}_LOAD_FEES_SUCCESS`,
  loadFeesFailed: `${BASE}_LOAD_FEES_FAILED`,
};

export const fetchFeeList = () => async (dispatch) => {
  dispatch({ type: actions.loadingFees });
  let response;

  try {
    response = await queryFeeList();
  } catch (e) {
    response = { success: false };
  }

  if (!response.success) {
    dispatch({ type: actions.loadFeesFailed });
    return;
  }

  const fees = response.data || [];
  fees.forEach((fee) => {
    fee.auto_apply = fee.auto_apply || {};
    const { credit_card_transactions_is_under, guests_amount_greater_than_or_equal_to } = fee.auto_apply;
    fee.auto_apply.enable = credit_card_transactions_is_under?.enable || guests_amount_greater_than_or_equal_to?.enable;
  });
  dispatch({
    type: actions.loadFeesSuccess,
    payload: { fees },
  });
};

export const sortFees = (payload) => async (dispatch) => {
  let response;

  try {
    response = await batchUpdateFees(payload);
  } catch (e) {
    response = { success: false };
  }

  if (!response.success) return;

  dispatch({
    type: actions.loadFeesSuccess,
    payload: { fees: payload },
  });
};

export const doCreateFee = (params, callback) => async (dispatch) => {
  const response = await createFee(params);
  if (typeof callback === 'function') {
    callback(response.success);
  }
};

export const doUpdateFee = (params, callback) => async (dispatch) => {
  const response = await updateFee(params);
  if (typeof callback === 'function') {
    callback(response.success);
  }
};

export const doDeleteFee = (id, callback) => async (dispatch) => {
  const response = await deleteFee(id);
  if (typeof callback === 'function') {
    callback(response.success);
  }
};
