import { actions as floorPlanActions } from '../actions/floorPlan';

const initialState = {
  isLoadingSections: false,
  isLoadSectionsSuccess: false,
  isLoadSectionsFailed: false,
  sections: [],
  sectionInfo: {},
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case floorPlanActions.loadingSections: {
      return {
        ...state,
        isLoadingSections: true,
        isLoadSectionsSuccess: false,
        isLoadSectionsFailed: false,
      };
    }

    case floorPlanActions.loadSectionsSuccess: {
      return {
        ...state,
        isLoadingSections: false,
        isLoadSectionsSuccess: true,
        isLoadSectionsFailed: false,
        sections: payload,
      };
    }

    case floorPlanActions.loadSectionsFailed: {
      return {
        ...state,
        isLoadingSections: false,
        isLoadSectionsSuccess: false,
        isLoadSectionsFailed: true,
      };
    }

    default:
      return state;
  }
};
