import {
  batchUpdateDiscounts,
  createDiscount,
  deleteDiscount,
  queryDiscountList,
  updateDiscount,
} from '../services/discount';

const BASE = 'DISCOUNT';

export const actions = {
  loadingDiscounts: `${BASE}_LOADING_DISCOUNT`,
  loadDiscountSuccess: `${BASE}_LOAD_DISCOUNT_SUCCESS`,
  loadDiscountFailed: `${BASE}_LOAD_DISCOUNT_FAILED`,

  creatingDiscount: `${BASE}_CREATING_DISCOUNT`,
  createDiscountSuccess: `${BASE}_CREATE_DISCOUNT_SUCCESS`,
  createDiscountFailed: `${BASE}_CREATE_DISCOUNT_FAILED`,

  updatingDiscount: `${BASE}_UPDATING_DISCOUNT`,
  updateDiscountSuccess: `${BASE}_UPDATE_DISCOUNT_SUCCESS`,
  updateDiscountFailed: `${BASE}_UPDATE_DISCOUNT_FAILED`,

  deletingDiscount: `${BASE}_DELETING_DISCOUNT`,
  deleteDiscountSuccess: `${BASE}_DELETE_DISCOUNT_SUCCESS`,
  deleteDiscountFailed: `${BASE}_DELETE_DISCOUNT_FAILED`,
};

export const fetchDiscountList = () => async (dispatch) => {
  dispatch({
    type: actions.loadingDiscounts,
  });

  let response;

  try {
    response = await queryDiscountList();
  } catch (e) {
    response = { success: false };
  }

  if (!response.success) {
    dispatch({
      type: actions.loadDiscountFailed,
    });
    return;
  }

  dispatch({
    type: actions.loadDiscountSuccess,
    payload: { discounts: response.data },
  });
};

export const doCreateDiscount = (data, callback) => async (dispatch) => {
  dispatch({
    type: actions.creatingDiscount,
  });

  try {
    const response = await createDiscount(data);

    if (!response.success) {
      dispatch({
        type: actions.createDiscountFailed,
      });
      return;
    }

    dispatch({
      type: actions.createDiscountSuccess,
    });

    dispatch(fetchDiscountList());
    typeof callback === 'function' && callback();
  } catch (e) {
    dispatch({
      type: actions.createDiscountFailed,
    });
  }
};

export const doUpdateDiscount = (data, callback) => async (dispatch) => {
  dispatch({
    type: actions.updatingDiscount,
  });

  try {
    const response = await updateDiscount(data);

    if (!response.success) {
      dispatch({
        type: actions.updateDiscountFailed,
      });
      return;
    }

    dispatch({
      type: actions.updateDiscountSuccess,
    });

    dispatch(fetchDiscountList());
    typeof callback === 'function' && callback();
  } catch (e) {
    dispatch({
      type: actions.updateDiscountFailed,
    });
  }
};

export const doDeleteDiscount = (id) => async (dispatch) => {
  const response = await deleteDiscount(id);

  if (!response.success) return;

  dispatch(fetchDiscountList());
};

export const sortDiscounts = (payload) => async (dispatch) => {
  let response;

  try {
    response = await batchUpdateDiscounts(payload);
  } catch (e) {
    response = { success: false };
  }

  if (response.success) return;

  dispatch({
    type: actions.loadDiscountSuccess,
    payload: { discounts: payload },
  });
};
