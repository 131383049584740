import { actions as preferenceActions } from '../actions/preference';

const initialState = {
  isLoadingPreference: false,
  isLoadPreferenceSuccess: false,
  isLoadPreferenceFailed: false,

  isUpdatingPreference: false,
  isUpdatePreferenceSuccess: false,
  isUpdatePreferenceFailed: false,

  preference: {},
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case preferenceActions.loadingPreference: {
      return {
        ...state,
        isLoadingPreference: true,
        isLoadPreferenceSuccess: false,
        isLoadPreferenceFailed: false,
      };
    }

    case preferenceActions.loadPreferenceSuccess: {
      return {
        ...state,
        isLoadingPreference: false,
        isLoadPreferenceSuccess: true,
        isLoadPreferenceFailed: false,
        preference: payload,
      };
    }

    case preferenceActions.loadPreferenceFailed: {
      return {
        ...state,
        isLoadingPreference: false,
        isLoadPreferenceSuccess: true,
        isLoadPreferenceFailed: false,
        preference: {},
      };
    }

    case preferenceActions.updatingPreference: {
      return {
        ...state,
        isUpdatingPreference: true,
        isUpdatePreferenceSuccess: false,
        isUpdatePreferenceFailed: false,
      };
    }

    case preferenceActions.updatePreferenceSuccess: {
      return {
        ...state,
        preference: payload,
        isUpdatingPreference: false,
        isUpdatePreferenceSuccess: true,
        isUpdatePreferenceFailed: false,
      };
    }

    case preferenceActions.updatePreferenceFailed: {
      return {
        ...state,
        isUpdatingPreference: false,
        isUpdatePreferenceSuccess: false,
        isUpdatePreferenceFailed: true,
      };
    }

    default: {
      return state;
    }
  }
};
