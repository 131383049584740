import { actions as transactionActions } from '../actions/transaction';

const initialState = {
  isLoadingTransactions: false,
  isLoadTransactionsSuccess: false,
  isLoadTransactionsFailed: false,

  isLoadingRefunds: false,
  isLoadRefundsSuccess: false,
  isLoadRefundsFailed: false,

  transactions: [],
  pagination: { current: 1, pageSize: 10, total: 0 },

  refunds: [],
  refundPagination: { current: 1, pageSize: 10, total: 0 },
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case transactionActions.loadingTransactions: {
      return {
        ...state,
        isLoadingTransactions: true,
        isLoadTransactionsSuccess: false,
        isLoadTransactionsFailed: false,
      };
    }

    case transactionActions.loadTransactionsSuccess: {
      return {
        ...state,
        isLoadingTransactions: false,
        isLoadTransactionsSuccess: true,
        isLoadTransactionsFailed: false,
        ...payload,
      };
    }

    case transactionActions.loadTransactionsFailed: {
      return {
        ...state,
        isLoadingTransactions: false,
        isLoadTransactionsSuccess: false,
        isLoadTransactionsFailed: true,
        transactionActions: [],
      };
    }

    case transactionActions.loadingRefunds: {
      return {
        ...state,
        isLoadingRefunds: true,
        isLoadRefundsSuccess: false,
        isLoadRefundsFailed: false,
      };
    }

    case transactionActions.loadRefundsSuccess: {
      return {
        ...state,
        isLoadingRefunds: false,
        isLoadRefundsSuccess: true,
        isLoadRefundsFailed: false,
        refunds: payload.refunds,
        refundPagination: payload.pagination,
      };
    }

    case transactionActions.loadRefundsFailed: {
      return {
        ...state,
        isLoadingRefunds: false,
        isLoadRefundsSuccess: false,
        isLoadRefundsFailed: true,
        refunds: [],
      };
    }

    default:
      return state;
  }
};
