import { CDS_API_PREFIX } from 'src/consts';
import { GET, PUT } from 'src/utils/request';

export function queryCdsPreference() {
  return GET(`${CDS_API_PREFIX}/v1/preference`);
}

export function updateCdsPreference(data) {
  return PUT(`${CDS_API_PREFIX}/v1/preference`, data);
}
