import React from 'react';

const IconSmartRestaurant = () => (
  <svg width="14" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipath="url(#clip0_679_7195)">
      <path
        d="M15 12H1C1 7.58172 4.13401 4 8 4C11.866 4 15 7.58172 15 12Z"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M10 4H6C6 2.61929 6.89543 1.5 8 1.5C9.10457 1.5 10 2.61929 10 4Z"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path d="M1 14.5H15" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" />
    </g>
    <defs>
      <clipPath id="clip0_679_7195">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default IconSmartRestaurant;
