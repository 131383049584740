import { actions } from '../actions/department';

const initialState = {
  isLoadingDepartments: false,
  isLoadDepartmentsSuccess: false,

  departments: [],
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case actions.loadingDepartments: {
      return {
        ...state,
        isLoadingDepartments: true,
        isLoadDepartmentsSuccess: false,
      };
    }

    case actions.loadDepartmentsSuccess: {
      return {
        ...state,
        isLoadingDepartments: false,
        isLoadDepartmentsSuccess: true,
        departments: payload,
      };
    }

    case actions.loadDepartmentsFailed: {
      return {
        ...state,
        isLoadingDepartments: false,
        isLoadDepartmentsSuccess: false,
        departments: [],
      };
    }

    default: {
      return state;
    }
  }
};
