import { actions as teamActions } from '../actions/team';

const initialState = {
  roles: [],
  members: [],

  loadingRoles: false,
  loadRolesSuccess: false,

  loadingMembers: false,
  loadMemberSuccess: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case teamActions.loadingRoles: {
      return {
        ...state,
        loadingRoles: true,
      };
    }

    case teamActions.loadingRolesSuccess: {
      return {
        ...state,
        loadingRoles: false,
        loadRolesSuccess: true,
        roles: payload.roles,
      };
    }

    case teamActions.loadingRolesFailed: {
      return {
        ...state,
        loadingRoles: false,
        loadRolesSuccess: false,
        roles: [],
      };
    }

    case teamActions.loadingMembers: {
      return {
        ...state,
        loadingMembers: true,
        loadMemberSuccess: false,
      };
    }

    case teamActions.loadingMemberSuccess: {
      return {
        ...state,
        loadingMembers: false,
        loadMemberSuccess: true,
        members: payload.members,
      };
    }

    case teamActions.loadingMemberFailed: {
      return {
        ...state,
        loadingMembers: false,
        loadMemberSuccess: false,
        members: [],
      };
    }

    default:
      return state;
  }
};
