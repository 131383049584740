export const DEFAULT_DELIMITER = '.';

export function getChangedEntry(obj, { prefix = '', delimiter = DEFAULT_DELIMITER } = {}) {
  const firstKey = Object.keys(obj)[0];
  const value = obj[firstKey];
  prefix = prefix ? `${prefix}${delimiter}${firstKey}` : firstKey;
  if ({}.toString.call(value) !== '[object Object]')
    return {
      key: prefix,
      value,
      delimiter,
    };
  return getChangedEntry(value, { prefix, delimiter });
}

export function getFieldValueByNamePath(srcObject, namePath) {
  if (!srcObject || !namePath) return undefined;

  let fieldValue = srcObject;
  let i = 0;
  try {
    while (i < namePath.length) {
      fieldValue = fieldValue?.[namePath[i]];
      i++;
    }
  } catch (e) {
    fieldValue = undefined;
  }

  return fieldValue;
}

export function setValueByNamePath(target, namePath, value) {
  if (!target || !namePath) return;

  let targetObject = target;
  const { length } = namePath;
  for (let i = 0; i < length; i++) {
    const field = namePath[i];
    if (i !== length - 1) {
      if (!targetObject[field]) targetObject[field] = {};
      targetObject = targetObject[field];
    } else {
      targetObject[field] = value;
    }
  }
}

export function isNotEmptyObject(obj) {
  try {
    return obj && Object.keys(obj).length > 0;
  } catch (error) {
    return false;
  }
}

export function findEmptyKeys(obj, fields) {
  try {
    Object.keys(obj).forEach((k) => {
      const v = obj[k];
      if (!v) {
        fields.push(k);
        return;
      }

      findEmptyKeys(v, fields);
    });
  } catch (error) {}
}
