import { actions as CRMActions } from '../actions/crm';

const initialState = {
  autopilotCampaignList: undefined,
  userSegments: undefined,
  manualCampaigns: undefined,
  availableBindingList: undefined,
  segmentMonthlyUsage: undefined,
  //新的CRM协议是否confirm
  campaignServiceUsageConfirmed: undefined,
  //crm是否订阅
  crmSubscribeStatus: undefined,
  twilioStatus: undefined,
  twilioApplyInfo: undefined,
  twilioRequestCount: 0,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case CRMActions.updateTwilioRequestCount: {
      return {
        ...state,
        twilioRequestCount: payload,
      };
    }
    case CRMActions.updateTwilioApplyInfo: {
      return {
        ...state,
        twilioApplyInfo: payload,
      };
    }
    case CRMActions.loadTwilioStatus: {
      return {
        ...state,
        twilioStatus: payload,
      };
    }
    case CRMActions.updateAutopilotCampaignList: {
      return {
        ...state,
        autopilotCampaignList: payload,
      };
    }
    case CRMActions.updateUserSegmentList: {
      return {
        ...state,
        userSegments: payload,
      };
    }
    case CRMActions.updateManualCampaignList: {
      return {
        ...state,
        manualCampaigns: payload,
      };
    }
    case CRMActions.updateCampaignAvailableBindingList: {
      return {
        ...state,
        availableBindingList: payload,
      };
    }
    case CRMActions.updateSegmentMonthlyUsage: {
      return {
        ...state,
        segmentMonthlyUsage: payload,
      };
    }
    case CRMActions.confirmCampaignServiceUsage: {
      return {
        ...state,
        campaignServiceUsageConfirmed: payload,
      };
    }

    case CRMActions.loadCRMSubscribeStatus: {
      return {
        ...state,
        crmSubscribeStatus: payload,
      };
    }

    default:
      return state;
  }
};
