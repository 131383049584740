import JwtDecode from 'jwt-decode';
import { getAppInfos, getNavigatorLocaleSetting } from './utils';
import { setAppReady, setIsGroup, setIsInApp, switchLocale } from '../actions/app';
import { cacheCurrentUserInfo } from '../actions/user';
import { setCurrentRgId } from '../actions/rgUser';
import { getCookie, getSessionItem, KEYS, setSessionItem, USER_KEYS } from '../store/storage';
import { extractRestaurantId, getCurrentUserInfoFromToken } from './user';

async function getSessionFromUserAgent(store) {
  const appInfo = getAppInfos();
  const { token, refreshToken, lang } = appInfo;
  store.dispatch(switchLocale(lang));
  let expireTime;

  if (token) {
    const decoded = JwtDecode(token);
    const { exp, email, sub } = decoded;
    const userInfo = {
      sub,
      email,
    };
    expireTime = (exp - 30) * 1000;
    const { restaurantId, identity } = extractRestaurantId(decoded);
    store.dispatch(
      cacheCurrentUserInfo({
        currentUser: userInfo,
        currentRestaurantId: restaurantId,
        email,
        identity,
      })
    );
    setSessionItem({
      [KEYS.email]: email,
      [KEYS.token]: token,
      [KEYS.expireTime]: expireTime,
      [KEYS.userInfo]: userInfo,
      [KEYS.refreshToken]: refreshToken,
      [KEYS.locale]: lang,
      [KEYS.restaurantId]: restaurantId,
    });
  } else {
    setSessionItem({
      [KEYS.token]: null,
      [KEYS.locale]: lang,
    });
  }
  await store.dispatch(setAppReady(true));

  if (process.env.STAGING_ENV !== 'production') {
    const mod = await import('vconsole' /* webpackChunkName: 'vconsole' */);
    // init vConsole
    const vConsole = new mod.default();
    console.log('token: ', token, ' ,expireTime: ', expireTime, ' ,locale: ', lang);
  }
}

async function getSessionFromStorage(store) {
  const state = store.getState();
  const { appReady } = state.app;
  if (appReady) return;

  const token = getSessionItem(USER_KEYS.token);
  let locale;

  if (token) {
    locale = getSessionItem(KEYS.locale, getNavigatorLocaleSetting());
    const userInfo = JSON.parse(getSessionItem(USER_KEYS.userInfo));
    const restaurantId = getSessionItem(USER_KEYS.restaurantId);
    const email = getSessionItem(KEYS.email);
    const identity = getSessionItem(USER_KEYS.identity);

    store.dispatch(
      cacheCurrentUserInfo({
        currentUser: userInfo,
        currentRestaurantId: restaurantId,
        email,
        identity,
      })
    );
  } else {
    const localToken = getCookie(USER_KEYS.token, null);
    locale = getCookie(KEYS.locale) || getNavigatorLocaleSetting();

    if (!localToken) {
      setSessionItem({
        [KEYS.email]: getCookie(KEYS.email) || '',
      });
    } else {
      const currentUserInfos = getCurrentUserInfoFromToken(localToken);
      const { currentUser, currentRestaurantId, email, expireTime, identity } = currentUserInfos;
      setSessionItem({
        [USER_KEYS.token]: localToken,
        [USER_KEYS.refreshToken]: getCookie(USER_KEYS.refreshToken),
        [USER_KEYS.userInfo]: currentUser,
        [USER_KEYS.restaurantId]: currentRestaurantId,
        [KEYS.email]: email,
        [USER_KEYS.expireTime]: expireTime,
        [USER_KEYS.identity]: identity,
      });
      store.dispatch(cacheCurrentUserInfo(currentUserInfos));
    }
  }

  const currentRgId = getSessionItem(USER_KEYS.currentRgId) || getCookie(USER_KEYS.currentRgId) || '';
  store.dispatch(setIsGroup(!!currentRgId));
  store.dispatch(setCurrentRgId(currentRgId));

  store.dispatch(switchLocale(locale));
  await store.dispatch(setAppReady(true));
}

export async function bootstrap(store) {
  const appInfo = getAppInfos();
  const { isApp } = appInfo;
  store.dispatch(setIsInApp(isApp));
  setSessionItem({
    [KEYS.isInApp]: isApp,
  });
  if (isApp) {
    store.dispatch(setIsGroup(false));
    getSessionFromUserAgent(store);
  } else {
    getSessionFromStorage(store);
  }
}
