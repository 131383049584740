import { GET, POST } from '../utils/request';
import { API_PREFIX } from '../consts';

export function queryJobTasks({
  task_type,
  task_status,
  start_at = '',
  end_at = '',
  page = 1,
  page_size = 10,
  authorization,
}) {
  return GET(
    `${API_PREFIX}/admin/v1/jobtasks`,
    {
      task_type,
      task_status,
      start_at,
      end_at,
      page,
      page_size,
    },
    {
      headers: {
        Authorization: (!authorization.includes('Basic') ? 'Basic ' : '') + authorization,
      },
      silence: true,
    }
  );
}

export function revokeJobTask({ task_type, task_id, authorization }) {
  return POST(
    `${API_PREFIX}/admin/v3/jobtask/push`,
    { task_type, task_id },
    {
      headers: {
        Authorization: (!authorization.includes('Basic') ? 'Basic ' : '') + authorization,
      },
    }
  );
}

export function queryTaskDetail({ task_type, task_id, authorization }) {
  return GET(
    `${API_PREFIX}/admin/v3/jobtask`,
    { task_type, task_id },
    {
      headers: {
        Authorization: (!authorization.includes('Basic') ? 'Basic ' : '') + authorization,
      },
    }
  );
}
